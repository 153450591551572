import {Admin, PermissionDto} from '../../api/generated';
import {NirioEdit} from '../../components/NirioEdit';
import {PermissionGuard} from '../../components/PermissionGuard';
import {AdminForm} from './AdminForm';

export const AdminEdit = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.AdminWrite}>
    <NirioEdit
      mutationMode="optimistic"
      title={(record: Admin): string =>
        `Administrateur ${record.firstName} ${record.lastName}`
      }>
      <AdminForm />
    </NirioEdit>
  </PermissionGuard>
);
