export const typography = {
  h1: {
    fontFamily: 'Chance',
    fontSize: '24px',
    fontWeight: '700',
  },
  h2: {
    fontFamily: 'Chance',
    fontSize: '20px',
    fontWeight: '700',
  },
  h3: {
    fontFamily: 'Chance',
    fontSize: '16px',
    fontWeight: '700',
  },
  body1: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
  },
  body2: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '400',
  },
};
