import {styled} from '@mui/material';
import {
  ArrayInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import {OauthBodyEncoding} from '../../api/generated';

export function OauthBodyInput(): JSX.Element {
  return (
    <>
      <SelectInput
        source="oauthBodyEncoding"
        choices={Object.values(OauthBodyEncoding).map(value => ({
          id: value,
          name: `oauth.encoding.${value}`,
        }))}
        defaultValue={OauthBodyEncoding.Json}
        validate={required()}
      />

      <ArrayInput source="oauthBody">
        <StyledSimpleFormIterator inline>
          <TextInput source="key" label={false} placeholder="Clé" />
          <TextInput source="value" label={false} placeholder="Valeur" />
        </StyledSimpleFormIterator>
      </ArrayInput>
    </>
  );
}

const StyledSimpleFormIterator = styled(SimpleFormIterator)({
  '& .RaSimpleFormIterator-line': {
    paddingTop: '8px',
    paddingBottom: '8px',
  },

  '& .RaSimpleFormIterator-form': {
    flex: 1,
  },

  '& .MuiTextField-root': {
    flex: 1,
  },

  '& .RaSimpleFormIterator-action': {
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
  },
});
