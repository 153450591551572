import {styled, Typography} from '@mui/material';
import {
  EditButton,
  FunctionField,
  useGetRecordId,
  useTranslate,
} from 'react-admin';

import {
  PermissionDto,
  PromotionSettings,
  PromotionSettingsType,
} from '../../../api/generated';
import {CustomShow} from '../../../components/CustomShow';
import {LabeledFunctionField} from '../../../components/Fields/LabeledFunctionField';
import {LabeledTextField} from '../../../components/Fields/LabeledTextField';
import {PermissionGuard} from '../../../components/PermissionGuard';
import {RenderConditionalLabel} from '../../../components/RenderLabel';
import {usePermissions} from '../../../hooks/usePermissions';
import {palette} from '../../../theme/palette';
import {formatMaybeAmountInCents} from '../../../utils/format-maybe-amount-in-cents';

const StyledCardsContentWithoutMargin = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const LeftCardTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '30px',
});

const LabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '7px',

  '& .MuiTypography-root': {
    fontSize: '14px',
  },
  '& .RaLabeled-label': {
    color: palette.grey[500],
    fontSize: '12px',
  },
});

const leftCardContent = [
  <LabeledTextField source="userLimit" key="userLimit" />,
  <LabeledTextField source="slidingMonthLimit" key="slidingMonthLimit" />,
];

const RightCardContent = (): JSX.Element => {
  const type = useGetRecordId();

  const field =
    type === PromotionSettingsType.ReferrerPromotion
      ? 'referrerGainAmountInCents'
      : 'retailerGainAmountInCents';

  return (
    <LabelContainer>
      <LabeledFunctionField
        key="gainAmountInCents"
        source="gainAmountInCents"
        render={(record: PromotionSettings): string =>
          formatMaybeAmountInCents(Number(record.gainAmountInCents) ?? 0)
        }
      />
      <LabeledFunctionField
        key={field}
        source={field}
        render={(record: PromotionSettings): string =>
          formatMaybeAmountInCents(Number(record[field]) ?? 0)
        }
      />
    </LabelContainer>
  );
};

export const SettingsShow = (): JSX.Element => {
  const {can} = usePermissions();
  const type = useGetRecordId();
  const translate = useTranslate();

  const title = translate(`resources.PromotionSettings.fields.${type}`);

  const actions = [];
  if (can(PermissionDto.ProductWrite)) {
    actions.push(
      <EditButton
        icon={<div />}
        key="edit-button"
        label="Modifier"
        variant="contained"
      />,
    );
  }

  const topPapers = [
    {
      content: (
        <StyledCardsContentWithoutMargin>
          <LeftCardTitleContainer>
            <Typography variant="h1" color="secondary">
              Condition d&apos;activation
            </Typography>
            <FunctionField
              render={(record: PromotionSettings): JSX.Element => (
                <RenderConditionalLabel condition={record.isActive} />
              )}
            />
          </LeftCardTitleContainer>
          <LabelContainer>{leftCardContent}</LabelContainer>
        </StyledCardsContentWithoutMargin>
      ),
    },
    {
      title: 'Gain',
      content: (
        <LabelContainer>
          <RightCardContent />
        </LabelContainer>
      ),
    },
  ];

  return (
    <PermissionGuard permission={PermissionDto.PromotionRead}>
      <CustomShow
        backTo="/admin/AllPromotions"
        title={title}
        actions={actions}
        topPapers={topPapers}
        titleTextTransform="none"
      />
    </PermissionGuard>
  );
};
