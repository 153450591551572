import {Box} from '@mui/material';
import {ComponentProps} from 'react';
import {Button, CreateBase, Loading, useCreateController} from 'react-admin';
import {Link} from 'react-router-dom';

import {basename} from '../constants';
import {BreadCrumbHeader} from './BreadCrumbHeader';
import {Form} from './Form/Form';
import {NirioSaveButton} from './NirioSaveButton';
import {PageLayout} from './PageLayout';

type NirioCreateProps = ComponentProps<typeof CreateBase> & {
  title?: string;
  backTo?: string;
};

export const NirioCreate = ({
  backTo,
  children,
  title,
  ...props
}: NirioCreateProps): JSX.Element => {
  const controller = useCreateController(props);

  if (controller.isLoading) {
    return <Loading />;
  }

  return (
    <CreateBase redirect="show" {...props}>
      <PageLayout>
        <Form>
          <Box my={7}>
            <BreadCrumbHeader
              {...controller}
              title={title}
              actions={[
                <Button
                  key="back"
                  label="Annuler"
                  component={Link}
                  to={backTo ?? `${basename}/${controller.resource}`}
                />,
                <NirioSaveButton key="save" />,
              ]}
            />
          </Box>
          {children}
        </Form>
      </PageLayout>
    </CreateBase>
  );
};
