import {Card, CardContent, Stack, Typography, useTheme} from '@mui/material';
import {ReactNode} from 'react';

interface StackTitleCardProps {
  title: string;
  children: ReactNode;
}
export const StackTitleCard: React.FC<StackTitleCardProps> = ({
  title,
  children,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        flex: 1,
      }}>
      <CardContent>
        <Typography
          variant="h3"
          component="h3"
          color="secondary"
          sx={{marginBottom: theme.spacing(2)}}>
          {title}
        </Typography>
        <Stack spacing={theme.spacing(1)}>{children}</Stack>
      </CardContent>
    </Card>
  );
};
