import {styled, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';
import {required, SaveButton, SelectInput} from 'react-admin';
import {FieldValues} from 'react-hook-form';

import {TransactionsExportFormat} from '../../api/generated';
import {Form} from '../../components/Form/Form';
import {DatetimePicker} from '../../components/Form/Inputs/DatetimePicker';
import {getBeginningOfDay, getEndOfDay} from '../../utils/date.utils';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledForm = styled(Form)({
  backgroundColor: 'white',
  width: '570px',
  boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const InputsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '& .MuiInputBase-root': {
    width: '241px',
    height: '44px',
    borderRadius: '8px',
  },
});

const StyledSaveButton = styled(SaveButton)({
  margin: 'auto',
});

export interface ReportModalProps {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onSubmit: (data: FieldValues) => void;
}

export const ReportModal = ({
  isOpen,
  onClose,
  onSubmit,
}: ReportModalProps): JSX.Element => {
  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <StyledForm
        onSubmit={onSubmit}
        defaultValues={{
          format: TransactionsExportFormat.Csv,
          startDate: getBeginningOfDay(new Date()).toISOString(),
          endDate: getEndOfDay(new Date()).toISOString(),
        }}>
        <Typography variant="h1" color="secondary">
          Rapport d&apos;émargement
        </Typography>
        <Typography>
          Télécharger la liste des transactions liées à ce produit sur la plage
          de temps souhaitée
        </Typography>

        <SelectInput
          source="format"
          label="Format du rapport"
          placeholder="Choisir le format du rapport"
          choices={Object.values(TransactionsExportFormat).map(type => ({
            id: type,
            name: type,
          }))}
          validate={required()}
          fullWidth
        />

        <InputsContainer>
          <DatetimePicker source="startDate" label="Du" validate={required()} />
          <DatetimePicker
            source="endDate"
            label="Au"
            validate={required()}
            maxOutSeconds
          />
        </InputsContainer>

        <StyledSaveButton
          icon={<div />}
          label="Accéder au rapport"
          variant="contained"
          alwaysEnable
        />
      </StyledForm>
    </StyledModal>
  );
};
