import {styled, Tooltip} from '@mui/material/';
import {Fragment} from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Pagination,
  ReferenceManyField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useShowController,
} from 'react-admin';

import {
  Cart,
  CartItem,
  Client,
  ClientProfile,
  Payable,
  PermissionDto,
} from '../../api/generated';
import {SecondaryButton} from '../../components/Buttons/SecondaryButton';
import {
  MainPaperContainer,
  MainPaperContainerTitle,
  StyledTopPaper,
  TableSpacer,
  TopPapersFlexContainer,
  TopPaperTitle,
} from '../../components/CustomShow';
import {ExternalLink} from '../../components/ExternalLink';
import {NirioShow} from '../../components/NirioShow';
import {PaidAmountTooltip} from '../../components/PaidAmountTooltip';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {usePermissions} from '../../hooks/usePermissions';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {CessationRaWorkflowTable} from '../cessationRaWorkflow/CessationRaWorkflowTable';
import {DEBT_ORIGIN_TYPES, FEE_ORIGIN_TYPES} from './helpers';
import {KycWorkflowsList} from './KycWorkflowsList';
import {NirioBankDebtTable} from './NirioBankDebtTable';

const StyledSimpleShowLayout = styled(SimpleShowLayout)({
  '& .RaSimpleShowLayout-row': {
    display: 'inline-flex',
    marginBottom: '0.2em',
  },
  '& .ra-field .MuiTypography-root:not(.RaLabeled-label)': {
    fontSize: '14px',
  },
});

const PROFILES_WITH_NIRIO_ACCOUNT: Array<string> = [
  ClientProfile.Coap,
  ClientProfile.Cosp,
  ClientProfile.CracAvecCb,
  ClientProfile.CracSansCb,
  ClientProfile.Unknown,
];

export const ClientShow = (): JSX.Element | null => {
  const showController = useShowController<Client>();
  const {can} = usePermissions();

  if (!showController.record) {
    return null;
  }

  const clientHasNirioAccount = PROFILES_WITH_NIRIO_ACCOUNT.includes(
    showController.record.profile,
  );

  return (
    <PermissionGuard permission={PermissionDto.PayerRead}>
      <NirioShow canDelete={false} canEdit={false}>
        <TopPapersFlexContainer>
          <StyledTopPaper>
            <TopPaperTitle variant="h1" color="secondary">
              Authentification
            </TopPaperTitle>
            <StyledSimpleShowLayout>
              {'xpoAppUserId' in showController.record &&
                'xpoProfileUrl' in showController.record && (
                  <FunctionField
                    source="xpoAppUserId"
                    render={(
                      record: Client & {
                        xpoAppUserId: string;
                        xpoProfileUrl: string;
                      },
                    ): JSX.Element => (
                      <ExternalLink href={record.xpoProfileUrl}>
                        {record.xpoAppUserId}
                      </ExternalLink>
                    )}
                  />
                )}
              <FunctionField
                source="cbsClientId"
                render={(record: Client): JSX.Element => (
                  <ExternalLink href={record.cbsProfileUrl ?? undefined}>
                    {record.cbsClientId ?? 'Ø'}
                  </ExternalLink>
                )}
              />
              <TextField source="id" />
              {'email' in showController.record && <TextField source="email" />}
            </StyledSimpleShowLayout>
          </StyledTopPaper>

          <StyledTopPaper>
            <TopPaperTitle variant="h1" color="secondary">
              État du compte
            </TopPaperTitle>
            <StyledSimpleShowLayout>
              <TextField source="status" />
              <TextField source="profile" />
              <DateField showTime source="createdAt" />
            </StyledSimpleShowLayout>
          </StyledTopPaper>
        </TopPapersFlexContainer>
        {showController.record.profile !== ClientProfile.CdrCorporation &&
          showController.record.profile !== ClientProfile.CdrNatural && (
            <MainPaperContainer>
              <MainPaperContainerTitle variant="h1" color="secondary">
                Tentatives d&apos;onboarding
              </MainPaperContainerTitle>
              <KycWorkflowsList />
            </MainPaperContainer>
          )}
        {can(PermissionDto.CraRead) && clientHasNirioAccount && (
          <MainPaperContainer sx={{pb: '1em'}}>
            <MainPaperContainerTitle variant="h1" color="secondary">
              Cessation de la relation d&apos;affaire
            </MainPaperContainerTitle>
            {showController.record.cessationRaWorkflows?.length ? (
              <CessationRaWorkflowTable />
            ) : (
              showController.record.profile === ClientProfile.CracAvecCb && (
                <PermissionLink
                  permission={PermissionDto.CraWrite}
                  to={`/admin/Client/${showController.record.id}/CessationRaWorkflow/create`}>
                  <Tooltip
                    title={
                      can(PermissionDto.CraWrite)
                        ? undefined
                        : "Vous n'avez pas la permission requise pour clôturer un compte"
                    }>
                    <span>
                      {/* span required for tooltip */}
                      <SecondaryButton
                        text="Clôturer ce compte"
                        disabled={!can(PermissionDto.CraWrite)}
                        sx={{ml: '32px'}}
                      />
                    </span>
                  </Tooltip>
                </PermissionLink>
              )
            )}
          </MainPaperContainer>
        )}
        <MainPaperContainer>
          <MainPaperContainerTitle variant="h1" color="secondary">
            Paniers et factures
          </MainPaperContainerTitle>

          <TableSpacer>
            <Fragment>
              <MainPaperContainerTitle variant="h2">
                Paniers
              </MainPaperContainerTitle>
              <ReferenceManyField
                label="Paniers"
                pagination={<Pagination />}
                perPage={10}
                reference="Cart"
                target="payerId"
                sort={{field: 'createdAt', order: 'DESC'}}>
                <Datagrid
                  sx={{
                    boxShadow: 'none',
                  }}
                  hover={false}
                  bulkActionButtons={false}>
                  <FunctionField
                    source="id"
                    label="ID panier"
                    render={(record: Cart): JSX.Element => (
                      <PermissionLink
                        permission={PermissionDto.CartRead}
                        to={`/admin/Cart/${record.id}/show`}>
                        {record.id}
                      </PermissionLink>
                    )}
                  />
                  <DateField showTime source="createdAt" />
                  <ArrayField
                    label="ID factures"
                    source="items"
                    sortable={false}>
                    <SingleFieldList
                      linkType={false}
                      sx={{flexDirection: 'column', rowGap: '0.5rem'}}>
                      <FunctionField
                        source="payableId"
                        render={(record: CartItem): JSX.Element => (
                          <PermissionLink
                            permission={PermissionDto.PayableRead}
                            to={`/admin/Payable/${record.payableId}/show`}>
                            {record.payableId}
                          </PermissionLink>
                        )}
                      />
                    </SingleFieldList>
                  </ArrayField>
                  <FunctionField
                    label={<PaidAmountTooltip text="Montant (en €)" />}
                    render={(record: Cart): string => {
                      const totalAmount = record.items?.reduce(
                        (total: number, item) =>
                          total + item.amountInCents + item.serviceFeeInCents,
                        0,
                      );

                      return formatMaybeAmountInCents(totalAmount);
                    }}
                  />
                  <FunctionField
                    label="Statut"
                    source="paidAt"
                    render={(record: Cart): string =>
                      `${record.paidAt ? 'Réglé' : 'Ouvert'}`
                    }
                  />
                </Datagrid>
              </ReferenceManyField>
            </Fragment>

            <Fragment>
              <MainPaperContainerTitle variant="h2">
                Factures
              </MainPaperContainerTitle>

              <ReferenceManyField
                pagination={<Pagination />}
                perPage={10}
                reference="Payable"
                target="payerId"
                sort={{field: 'createdAt', order: 'DESC'}}>
                <Datagrid
                  sx={{
                    boxShadow: 'none',
                  }}
                  hover={false}
                  bulkActionButtons={false}>
                  <FunctionField
                    source="id"
                    render={(record: Payable): JSX.Element => (
                      <PermissionLink
                        permission={PermissionDto.PayableRead}
                        to={`/admin/Payable/${record.id}/show`}>
                        {record.id}
                      </PermissionLink>
                    )}
                  />
                  <DateField showTime source="createdAt" />
                  <FunctionField
                    source="initialAmountInCents"
                    render={(record: Payable): string =>
                      formatMaybeAmountInCents(record.initialAmountInCents)
                    }
                  />
                  <FunctionField
                    source="remainingAmountInCents"
                    render={(record: Payable): string =>
                      formatMaybeAmountInCents(record.remainingAmountInCents)
                    }
                  />
                  <FunctionField
                    source="product.name"
                    render={(record: Payable): JSX.Element => (
                      <>
                        {!!record.product && (
                          <PermissionLink
                            permission={PermissionDto.ProductRead}
                            to={`/admin/Product/${record.product.id}/show`}>
                            {record.product.name}
                          </PermissionLink>
                        )}
                      </>
                    )}
                  />
                  <TextField source="product.category.label" />
                  <FunctionField
                    source="product.partner.name"
                    render={(record: Payable): JSX.Element => (
                      <>
                        {!!record.product && (
                          <PermissionLink
                            permission={PermissionDto.PartnerRead}
                            to={`/admin/Partner/${record.product.partner.id}/show`}>
                            {record.product.partner.name}
                          </PermissionLink>
                        )}
                      </>
                    )}
                  />
                </Datagrid>
              </ReferenceManyField>
            </Fragment>
          </TableSpacer>
        </MainPaperContainer>

        <NirioBankDebtTable
          title="Dettes"
          filter={{
            origins: DEBT_ORIGIN_TYPES,
          }}
        />

        <NirioBankDebtTable
          title="Impayés"
          filter={{
            origins: FEE_ORIGIN_TYPES,
          }}
        />
      </NirioShow>
    </PermissionGuard>
  );
};
