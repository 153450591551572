import {useFormContext, useWatch} from 'react-hook-form';

// Taken from https://react-hook-form.com/docs/usewatch#rules
export function useFormValues<T extends Record<string, unknown>>(): T {
  const {getValues} = useFormContext<T>();

  return {
    ...useWatch(), // subscribe to form value updates
    ...getValues(), // always merge with latest form values
  };
}
