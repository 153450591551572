import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';

import {
  CartItem,
  ClientProfile,
  PaymentWorkflow,
  PaymentWorkflowStatus,
  PermissionDto,
  useRetailerDepartmentAndZipCodeQuery,
} from '../../api/generated';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {dateRangeFilters} from '../../components/Form/Filters/dateRangeFilters';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PaidAmountTooltip} from '../../components/PaidAmountTooltip';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {isUuid} from '../../utils/input.utils';
import {stopPropagation} from '../../utils/stop-propagation';

export const OperationList = (): JSX.Element => {
  const {data} = useRetailerDepartmentAndZipCodeQuery();

  const cartFilters = [
    ...dateRangeFilters({
      from: {
        source: 'cart.paidAtStart',
        label: "Date de l'opération (de)",
      },
      to: {
        source: 'cart.paidAtEnd',
        label: "Date de l'opération (à)",
      },
    }),
    <TextInput
      source="id"
      key={3}
      alwaysOn
      resettable
      validate={isUuid}
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <TextInput
      source="cart.id"
      key={4}
      alwaysOn
      resettable
      validate={isUuid}
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <AutocompleteArrayInput
      source="status"
      alwaysOn
      key={5}
      choices={[
        {
          id: 'PENDING',
          name: 'En attente',
        },
        {
          id: 'CONFIRMED',
          name: 'Confirmée',
        },
        {
          id: 'BLOCKED',
          name: 'Bloquée',
        },
      ]}
    />,
    <TextInput
      key={6}
      source="retailer.rdiRoleCode"
      alwaysOn
      resettable
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <AutocompleteArrayInput
      key={7}
      source="retailer.profiles"
      label="Rechercher par profil"
      choices={Object.values(ClientProfile).map(value => ({
        id: value,
      }))}
      groupBy={(record): string =>
        `${record.id}`.includes('CDR')
          ? 'CDR'
          : `${record.id}`.includes('PEAGE_ANONYME')
          ? 'PEAGE_ANONYME'
          : 'NIRIO'
      }
      optionText="id"
      alwaysOn
    />,
    <AutocompleteArrayInput
      key="retailerDepartmentCode"
      source="retailer.departmentCodes"
      choices={data?.allZipCode.departmentCodes.map(value => ({
        id: value,
      }))}
      optionText="id"
      alwaysOn
    />,
    <AutocompleteArrayInput
      key="retailerZipCode"
      source="retailer.zipCodes"
      choices={data?.allZipCode?.zipCodes.map(value => ({
        id: value,
      }))}
      optionText="id"
      alwaysOn
    />,
    <FilterSpacer key="spacer" alwaysOn />,
    <FilterResetButton key="reset" alwaysOn />,
  ];
  return (
    <PermissionGuard permission={PermissionDto.PaymentWorkflowRead}>
      <List
        actions={<ListTopBar />}
        filters={cartFilters}
        sort={{field: 'cart.paidAt', order: 'DESC'}}>
        <Datagrid hover={false} rowClick="show" bulkActionButtons={false}>
          <DateField locales="fr-FR" showTime={true} source="createdAt" />
          <FunctionField
            source="cart.paidAt"
            render={(record: PaymentWorkflow): JSX.Element | null => {
              if (record.status === 'CONFIRMED') {
                return (
                  <DateField
                    locales="fr-FR"
                    showTime={true}
                    source="cart.paidAt"
                  />
                );
              }
              return null;
            }}
          />
          <TextField source="id" />
          <TextField
            sortable={false}
            source="cart.items[0].cbsTransactionId"
            label="Référence CBS"
          />
          <FunctionField
            source="cart.id"
            render={(record: PaymentWorkflow): JSX.Element => (
              <PermissionLink
                permission={PermissionDto.CartRead}
                to={`/admin/Cart/${record.cart.id}/show`}>
                {record.cart.id}
              </PermissionLink>
            )}
          />
          <FunctionField
            key="retailer.rdiRoleCode"
            source="retailer.rdiRoleCode"
            render={(record: PaymentWorkflow): JSX.Element => (
              <PermissionLink
                permission={PermissionDto.RetailerRead}
                to={`/admin/Retailer/${record.retailer.id}/show`}>
                {record.retailer.rdiRoleCode}
              </PermissionLink>
            )}
          />
          ,
          <FunctionField
            sortable={false}
            source="amountInCents"
            label={<PaidAmountTooltip />}
            render={(record: PaymentWorkflow): string => {
              return formatMaybeAmountInCents(Number(record.amountInCents));
            }}
          />
          <TextField source="paymentMethod" />
          <FunctionField
            source="status"
            sortable={false}
            render={(record: PaymentWorkflow): string => {
              switch (record.status) {
                case PaymentWorkflowStatus.Blocked:
                  return 'Bloquée';
                case PaymentWorkflowStatus.Confirmed:
                  return 'Confirmée';
                case PaymentWorkflowStatus.Pending:
                  return 'En attente';
                case PaymentWorkflowStatus.Closed:
                  return 'Fermée';
                default:
                  return 'Inconnue';
              }
            }}
          />
          <TextField sortable={false} source="cart.client.profile" />
          <ArrayField
            sortable={false}
            source="cart.items"
            label="Catégories de produit">
            <SingleFieldList
              linkType={false}
              sx={{flexDirection: 'column', rowGap: '0.5rem'}}>
              <TextField source="payable.product.category.label" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField
            sortable={false}
            source="cart.items"
            label="Libellé partenaires">
            <SingleFieldList
              linkType={false}
              sx={{flexDirection: 'column', rowGap: '0.5rem'}}>
              <FunctionField
                sortable={false}
                source="payable.product.partner.name"
                render={(record: CartItem): JSX.Element => (
                  <>
                    {!!record.payable.product && (
                      <Link
                        onClick={stopPropagation}
                        to={`/admin/Partner/${record.payable.product.partner.id}/show`}>
                        {record.payable.product.partner.name}
                      </Link>
                    )}
                  </>
                )}
              />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
