import {Typography} from '@mui/material';
import {styled} from '@mui/system';
import {FC} from 'react';
import {Link} from 'react-admin';

import {IconComponentType} from '../Icon';

const StyledSideBarItem = styled(Link)({
  width: '100%',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  padding: '24px 32px',
  boxSizing: 'border-box',
  borderBottom: '1px solid #E8E8E8',
});

export interface SideBarItemProps {
  label: string;
  to: string;
  leftIcon?: IconComponentType;
  isActive?: boolean;
}

export const SideBarItem: FC<SideBarItemProps> = ({
  label,
  to,
  leftIcon: LeftIcon,
  isActive,
}) => {
  return (
    <StyledSideBarItem
      sx={{
        backgroundColor: isActive ? '#E5F8FF' : 'transparent',
      }}
      to={to}
      onClick={(): void => {
        document.querySelector('.RaLayout-appFrame')?.scrollTo({top: 0});
      }}>
      {LeftIcon && <LeftIcon isActive={isActive} size={24} />}
      <Typography
        color={isActive ? 'secondary' : 'secondary.contrastText'}
        variant="h3"
        sx={{display: {xs: 'none', sm: 'block'}, ml: 2}}>
        {label}
      </Typography>
    </StyledSideBarItem>
  );
};
