import {
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import {Loading} from 'react-admin';

import {OnOff} from '../../../../api/generated';
import {useFeatureFlags} from '../../../../hooks/useFeatureFlags';
import {ErrorAlert} from '../../../Alerts/ErrorAlert';
import {PageLayout} from '../../../PageLayout';
import {StackTitleCard} from '../../../StackTitleCard';
import {SimulationContext} from '../../context/context';
import {ActionTypes, SimulationMode} from '../../context/reducer.types';
import {generateCartRevisionHash} from '../../utils/generateCartRevisionHash';
import {modes} from './ModeSelector';

export const SimulatorConfiguration: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const {state, dispatch} = React.useContext(SimulationContext);
  const theme = useTheme();
  React.useEffect(() => {
    let simulationMode: SimulationMode;
    const cartId = state.urlParams?.get('cartId');
    const paramUpdatedAt = state.urlParams?.get('updatedAt');
    if (cartId && paramUpdatedAt) {
      simulationMode = SimulationMode.Manual;
      const cartRevisionHash = generateCartRevisionHash(
        cartId,
        new Date(paramUpdatedAt),
      );
      dispatch({
        type: ActionTypes.SetCode,
        payload: {code: `${cartId};${cartRevisionHash}`},
      });
    } else {
      simulationMode = SimulationMode.Scan;
    }
    dispatch({
      type: ActionTypes.SetSimulationMode,
      payload: {simulationMode: simulationMode},
    });
  }, [state.urlParams, dispatch]);
  return (
    <PageLayout>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={theme.spacing(2)}
        sx={{
          padding: theme.spacing(2),
        }}>
        {state.error && state.alert && (
          <ErrorAlert
            error={state.error}
            onClose={(): void => {
              dispatch({
                type: ActionTypes.SetAlert,
                payload: {alertOpen: false},
              });
            }}
          />
        )}
        <Stack
          component={Collapse}
          spacing={theme.spacing(2)}
          in={!state.result}>
          <FormControl disabled={state.locked} variant="outlined">
            <StackTitleCard title="Paramètres de la simulation">
              <TextField
                required
                label="Code du détaillant"
                variant="outlined"
                error={!state.retailerRoleCode}
                helperText={
                  !state.retailerRoleCode &&
                  'Le code du détaillant doit être renseigné'
                }
                value={state.retailerRoleCode}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ): void => {
                  dispatch({
                    type: ActionTypes.SetRetailerRoleCode,
                    payload: {retailerRoleCode: event.target.value},
                  });
                }}
              />
              <TextField
                required={featureFlags.operatorAuthorizationCheck === OnOff.On}
                label="Code de l'opérateur"
                variant="outlined"
                error={
                  featureFlags.operatorAuthorizationCheck === OnOff.On &&
                  !state.operatorId
                }
                helperText={
                  featureFlags.operatorAuthorizationCheck === OnOff.On &&
                  !state.operatorId &&
                  "Le code de l'opérateur doit être renseigné"
                }
                value={state.operatorId}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ): void => {
                  dispatch({
                    type: ActionTypes.SetOperatorId,
                    payload: {operatorId: event.target.value},
                  });
                }}
              />
              <RadioGroup
                value={state.simMode}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ): void => {
                  dispatch({
                    type: ActionTypes.SetSimulationMode,
                    payload: {
                      simulationMode:
                        event.target.value === SimulationMode.Scan
                          ? SimulationMode.Scan
                          : SimulationMode.Manual,
                    },
                  });
                }}>
                <Typography variant="body1" component="p">
                  Je veux:
                </Typography>
                <FormControlLabel
                  value="scan"
                  control={<Radio />}
                  label="scanner un QR Code"
                />
                <FormControlLabel
                  value="manual"
                  control={<Radio />}
                  label="entrer le contenu d'un QR Code"
                />
              </RadioGroup>
            </StackTitleCard>
          </FormControl>
        </Stack>
        {state.isLoading && !state.error && <Loading />}
        {modes[state.simMode]}
      </Stack>
    </PageLayout>
  );
};
