import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {ForwardedRef, forwardRef, useImperativeHandle} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {Controller, useForm} from 'react-hook-form';

const HtmlEditorComponent = (
  {initialHtml, toolbar}: {initialHtml: string; toolbar?: object},
  ref: ForwardedRef<{getHtml: () => string} | undefined>,
): JSX.Element => {
  const htmlToEditorState = (html: string): EditorState => {
    const value = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      value.contentBlocks,
      value?.entityMap,
    );
    return EditorState.createWithContent(contentState);
  };

  const {control, getValues} = useForm();

  useImperativeHandle(ref, () => ({
    getHtml: (): string => {
      const editorState = getValues('editor') as EditorState;
      return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    },
  }));

  return (
    <Controller
      control={control}
      name="editor"
      defaultValue={htmlToEditorState(initialHtml)}
      render={({field: {onChange, value}}): JSX.Element => (
        <Editor
          toolbar={toolbar}
          editorStyle={{
            padding: '0px 10px 10px',
            height: '200px',
          }}
          editorState={value as EditorState}
          onEditorStateChange={onChange}
        />
      )}
    />
  );
};

export const HtmlEditor = forwardRef(HtmlEditorComponent);
