export const formatMaybeAmountInCents = (amountInCents?: number): string => {
  return formatMaybeAmountInCentsWithDecimals(amountInCents, 2);
};

export const formatMaybeAmountInCentsWithDecimals = (
  amountInCents?: number,
  minimumFractionDigits = 2,
): string => {
  if (typeof amountInCents !== 'number') {
    return 'Ø';
  }
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits,
  }).format(amountInCents / 100);
};
