import Button, {ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

const StyledButton = styled(Button)<ButtonProps>(() => ({
  marginTop: '32px',
  backgroundColor: 'darkBlue',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'darkBlue',
  },
}));

export default StyledButton;
