import {FC, Fragment, useCallback} from 'react';

import {FilterCard} from '../../components/FilterCard/FilterCard';
import {FilterCardContainer} from '../../components/FilterCard/FilterCardContainer';
import {Icons} from '../../components/Icon';
import {useRetailerMonitoringFiltersCount} from './useRetailerMonitoringFiltersCount';

export interface RetailerCardProps {
  alwaysOn: boolean;
  // The List component create a wrapper over a filter and feeds that wrapper a `key` with value {source}
  // therefore if we use this component without a source then its wrapper will have no key
  source: string;
}

export const RetailerCards: FC<RetailerCardProps> = () => {
  const {filterCount, toggleFilter, filterSelected} =
    useRetailerMonitoringFiltersCount();

  const handleAboveVelocityThreshold = useCallback(
    () => toggleFilter('aboveVelocityThreshold'),
    [toggleFilter],
  );
  const handleBlocked = useCallback(
    () => toggleFilter('blocked'),
    [toggleFilter],
  );
  const handleBonuses = useCallback(
    () => toggleFilter('bonuses'),
    [toggleFilter],
  );
  const handlePenalties = useCallback(
    () => toggleFilter('penalties'),
    [toggleFilter],
  );

  return (
    <FilterCardContainer>
      <Fragment>
        <FilterCard
          count={filterCount.aboveVelocityThreshold}
          icon={Icons.Bell}
          onClick={handleAboveVelocityThreshold}
          title="Seuil d’alerte atteint"
          isSelected={filterSelected.aboveVelocityThreshold}
        />
        <FilterCard
          count={filterCount.blocked}
          icon={Icons.Lock}
          onClick={handleBlocked}
          title="Détaillants bloqués"
          isSelected={filterSelected.blocked}
        />
        <FilterCard
          count={filterCount.bonuses}
          icon={Icons.CircledChecked}
          onClick={handleBonuses}
          title="Bonus en cours sur leur seuil de blocage"
          isSelected={filterSelected.bonuses}
        />
        <FilterCard
          count={filterCount.penalties}
          icon={Icons.CircledCross}
          onClick={handlePenalties}
          title="Malus en cours sur leur seuil de blocage"
          isSelected={filterSelected.penalties}
        />
      </Fragment>
    </FilterCardContainer>
  );
};
