import {AxiosError} from 'axios';
import {URLSearchParams} from 'url';

import {Result} from '../dto/service-requests.dto';

type ActionMap<M extends {[index: string]: unknown}> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum ActionTypes {
  Reset = 'RESET',
  SetAlert = 'SET_POPUP',
  SetCode = 'SET_CODE',
  SetError = 'SET_ERROR',
  SetJSONMode = 'SET_JSON_MODE',
  SetLoading = 'SET_LOADING',
  SetLocked = 'SET_LOCKED',
  SetOperatorId = 'SET_OPERATOR_ID',
  SetResult = 'SET_RESULT',
  SetRetailerRoleCode = 'SET_RETAILER_ROLE_CODE',
  SetSimulationMode = 'SET_SIMULATION_MODE',
  SetUrlParams = 'SET_URL_PARAMS',
}

export enum SimulationMode {
  Scan = 'scan',
  Manual = 'manual',
  Results = 'results',
}
export enum PaymentMethod {
  Card = 'bankCard',
  Cash = 'cash',
}

type Payload = {
  [ActionTypes.SetLoading]: {loading: boolean};
  [ActionTypes.SetError]: {error: AxiosError | Error | null};
  [ActionTypes.SetResult]: {result: Result | null};
  [ActionTypes.SetCode]: {code: string};
  [ActionTypes.SetJSONMode]: {isJson: boolean};
  [ActionTypes.SetUrlParams]: {urlParms: URLSearchParams | null};
  [ActionTypes.SetSimulationMode]: {
    simulationMode: SimulationMode;
  };
  [ActionTypes.SetRetailerRoleCode]: {retailerRoleCode: string};
  [ActionTypes.Reset]: undefined;
  [ActionTypes.SetLocked]: {locked: boolean};
  [ActionTypes.SetAlert]: {alertOpen: boolean};
  [ActionTypes.SetOperatorId]: {operatorId: string};
};

export type State = {
  isLoading: boolean;
  simMode: SimulationMode;
  error: AxiosError | Error | null;
  result: Result | null;
  code: string;
  jsonMode: boolean;
  urlParams: URLSearchParams | null;
  retailerRoleCode: string;
  operatorId: string;
  locked: boolean;
  alert: boolean;
};

export type Action = ActionMap<Payload>[keyof ActionMap<Payload>];
