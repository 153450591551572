import {regex, Validator} from 'react-admin';

import {safeDivide, safeMultiply} from './numeric.utils';

type UnsafeDecimalInput = string | number | null | undefined;

/**
 * Default `parse` function from RA's TextField. Not exported by Ra, so copied/pasted.
 */
const convertStringToNumber = (
  value: string | null | number | undefined,
): number | null => {
  if (value == null || value === '') {
    return null;
  }
  const float = parseFloat(value as string);

  return isNaN(float) ? 0 : float;
};

/**
 * Default `format` function from RA's TextField. Not exported by Ra, so copied/pasted.
 */
const convertNumberToString = (value: UnsafeDecimalInput): string =>
  value == null || isNaN(value as number) ? '' : value.toString();

export function numberInputParseToCents(
  value: UnsafeDecimalInput,
): number | null {
  return safeMultiply(convertStringToNumber(value), 100);
}

export function numberInputFormatFromCents<T extends UnsafeDecimalInput>(
  value: T,
): string {
  return convertNumberToString(safeDivide(value, 100));
}

export const alphanumericValidator = regex(
  /^[A-Za-z0-9]+$/,
  'Seuls sont acceptés les caractères alphanumériques.',
);

export const numericValidator = regex(
  /^[0-9]+$/,
  'Seuls sont acceptés les caractères numériques.',
);

export const isUuid = regex(
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
  "L'id doit être renseigné dans son intégralité.",
);

export const isNotOnlySpaces =
  (message = "Ce champ ne peut pas être composé uniquement d'espaces.") =>
  (value: string): string | undefined =>
    value.trim().length === 0 ? message : undefined;

/**
 * @param query The query that returns data if object is found or null or undefined otherwise
 * @param currentValue The current value when editing that doesn't need unique validation
 * @param message The error message displayed if an object is found
 * @example
 * const [getAdminByEmail] = useGetAdminByEmailLazyQuery();
 * const uniqueEmailValidator = uniqueValidator((email: string) =>
 *    getAdminByEmail({variables: {email}}),
 * ).then(({data}) => data?.getAdminByEmail);
 */
export const uniqueValidator: <TValue, FormValues>(
  query: (
    value: TValue,
    values: FormValues,
  ) => Promise<object | undefined | null>,
  currentValue?: TValue,
  message?: string,
) => Validator =
  (query, currentValue, message = 'Cette valeur est déjà utilisée') =>
  async (value, values) => {
    if (value !== currentValue) {
      const data = await query(value, values); // eslint-disable-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
      if (data) {
        return message;
      }
    }
    return null;
  };
