import {Box, SxProps, Tooltip} from '@mui/material';
import bwipjs from 'bwip-js';
import {useEffect, useRef} from 'react';

export const DataMatrix = (props: {
  data: string;
  sx?: SxProps;
}): JSX.Element => {
  const {data, sx} = props;

  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (ref.current) {
      bwipjs.toCanvas(ref.current, {
        bcid: 'datamatrix', // Barcode type
        text: data, // Text to encode
      });
    }
  }, [ref, data]);

  return (
    <Box sx={sx}>
      <Tooltip title={data}>
        <canvas ref={ref} style={{height: '100%', width: '100%'}} />
      </Tooltip>
    </Box>
  );
};
