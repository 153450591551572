import {styled} from '@mui/material';

import {
  Product,
  ProductCategory,
  useGetAllPartnersQuery,
  useGetAllProductCategoriesQuery,
} from '../../api/generated';

const ProductDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '7px',
});

export function renderProductDetails(record: Product): JSX.Element {
  return (
    <ProductDetailsContainer>
      {record.canExpire && (
        <div>Date de validité facture à contrôler si présente</div>
      )}
      {record.canBePartiallyPaid && <div>Paiement partiel autorisé</div>}
      {record.canBePaidByThirdParty && <div>Paiement pour tiers autorisé</div>}
    </ProductDetailsContainer>
  );
}

export function renderProductAllowedPaymentMethods({
  allowedPaymentMethods,
}: Product): string {
  if (!allowedPaymentMethods) {
    return '';
  }

  return allowedPaymentMethods.map((method: string) => method).join(', ');
}

export const useProductFormQueries = (): {
  categories: ProductCategory[] | undefined;
  partners: {id: string; name: string}[] | undefined;
} => {
  const {data: categoriesData} = useGetAllProductCategoriesQuery();

  const categories: ProductCategory[] | undefined =
    categoriesData?.getAllProductCategories;

  const {data: {getAllPartners: partners} = {}} = useGetAllPartnersQuery({
    fetchPolicy: 'no-cache',
  });

  return {
    categories,
    partners,
  };
};
