import {PermissionDto} from '../../api/generated';
import {NirioCreate} from '../../components/NirioCreate';
import {PermissionGuard} from '../../components/PermissionGuard';
import {AdminForm} from './AdminForm';

export const AdminCreate = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.AdminWrite}>
    <NirioCreate title="Nouvel administrateur">
      <AdminForm />
    </NirioCreate>
  </PermissionGuard>
);
