import {AxiosError} from 'axios';

import {AlertInformations} from '../components/Alerts/Alert';

const defaultParsedError: AlertInformations = {
  title: 'Error',
  message: 'Unknown Error',
};

type ErrorData = {code: string; type: string} | {message: string};

export const parseAxiosError = (error: AxiosError): AlertInformations => {
  if (error.response && error.code) {
    const errorData = error.response.data as ErrorData;
    let details: string | undefined;
    if (errorData !== undefined && 'message' in errorData) {
      details = `Details: ${errorData.message}`;
    } else if (
      errorData !== undefined &&
      'code' in errorData &&
      'type' in errorData
    ) {
      details = `Details: ${errorData.code}: ${errorData.type}`;
    }
    return {
      title: `${error.name}: ${error.code}`,
      message: error.message,
      details,
    };
  }
  return defaultParsedError;
};
