import {Box, Stack, Typography} from '@mui/material';
import {ReactNode} from 'react';
import {LoadingPage} from 'react-admin';

import {PermissionDto} from '../api/generated';
import {usePermissions} from '../hooks/usePermissions';

export type PermissionGuardProps = {
  children: ReactNode;
  permission: PermissionDto;
};

export const PermissionGuard = ({
  children,
  permission,
}: PermissionGuardProps): JSX.Element => {
  const {can, isLoading} = usePermissions();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!can(permission)) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={3}
        justifyContent="center">
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography variant="h1" sx={{fontSize: '3rem'}}>
            Désolé
          </Typography>
          <Typography variant="h3" color="error">
            Vous n&apos;avez pas les permissions d&apos;accéder à cette page.
          </Typography>
        </Stack>
      </Box>
    );
  }

  return <>{children}</>;
};
