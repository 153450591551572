import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DateTimePicker as MuiDateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import {
  DateTimeInputProps,
  FieldTitle,
  InputHelperText,
  useInput,
  ValidationError,
  Validator,
} from 'react-admin';

// needed for the FilterResetButton to work (otherwise field.value == '' and renderInput.inputProps.value is not updated)
const format = (value: string | null): string | null => value || null;

const maybeMaxOutSeconds = (
  date: string | Date,
  maxOutSeconds?: boolean,
): string => {
  if (!maxOutSeconds) {
    return new Date(date).toISOString();
  }

  const maxedOutDate = new Date(date);
  maxedOutDate.setSeconds(59, 999);
  return maxedOutDate.toISOString();
};

const baseValidator: Validator = (
  value: string | Date | null,
): string | undefined => {
  if (!value) {
    return undefined;
  }
  return !(value instanceof Date && isNaN(value.getTime())) // after parsing, value is a Date only if it's an Invalid Date, but it can also come unparsed from the default values so I still do the isNaN check
    ? undefined
    : "Vous devez indiquer la date et l'heure.";
};

export function DatetimePicker({
  maxOutSeconds = false,
  showHelperText = true,
  ...props
}: DateTimeInputProps & {
  maxOutSeconds?: boolean;
  showHelperText?: boolean;
}): JSX.Element {
  const {label, source, resource, className, sx} = props;

  const parse = (value: string | null): string | null => {
    if (!value) {
      return value;
    }
    const date = new Date(value);

    return !isNaN(date.getTime()) // if Date is invalid, getTime() returns NaN
      ? maybeMaxOutSeconds(value, maxOutSeconds)
      : value;
  };

  const {
    field,
    fieldState: {error, isTouched},
    formState: {isSubmitted},
    isRequired,
  } = useInput({
    ...props,
    parse,
    format,
    validate: props.validate
      ? [baseValidator].concat(props.validate)
      : baseValidator,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <MuiDateTimePicker
        {...field}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        className={className}
        renderInput={(renderInputProps): JSX.Element => {
          return (
            <TextField
              {...renderInputProps}
              sx={sx}
              inputProps={{
                ...renderInputProps.inputProps,
                placeholder: 'jj/mm/aaaa hh:mm',
              }}
              error={!!error}
              helperText={
                (showHelperText || !!error) && ( // Always show helper text in case of error
                  // React-admin FilterForm systematically sets default helperText to false for all filters, but Ra Inputs use InputHelperText to still display helperText's placeholder space, I c/c that piece of code
                  <InputHelperText
                    touched={isTouched || isSubmitted}
                    error={error?.message}
                    helperText={
                      error?.message ? (
                        <ValidationError error={error.message} />
                      ) : (
                        props.helperText
                      )
                    }
                  />
                )
              }
              InputProps={{
                // Don't be fooled, inputProps is alas different from InputProps
                ...renderInputProps.InputProps,
                endAdornment: (
                  <>
                    {field.value && (
                      <IconButton
                        onClick={(): void => {
                          field.onChange(null);
                        }}
                        size="small">
                        <ClearIcon sx={{fontSize: 'medium'}} />
                      </IconButton>
                    )}
                    {renderInputProps.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        showDaysOutsideCurrentMonth
      />
    </LocalizationProvider>
  );
}
