import {Typography} from '@mui/material';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {formatMaybeAmountInCents} from '../../../../../utils/format-maybe-amount-in-cents';
import {PaymentAmount, PaymentMethod} from '../../../dto/service-requests.dto';

interface PaymentAmountsProps {
  amounts: PaymentAmount[];
}

interface PaymentInformationsProps {
  totalAmount: {amount: number; currency: string};
  amounts: PaymentAmount[];
}

function createPaymentAmountsData(
  amount: number,
  currency: string,
  methods: PaymentMethod[],
): {amount: string; currency: string; methods: string} {
  return {
    amount: amount.toString(),
    currency,
    methods: methods.map(m => m.type).join(', '),
  };
}

function paymentAmountsRows({
  amounts,
}: {
  amounts: PaymentAmount[];
}): {amount: string; currency: string; methods: string}[] {
  const amountsData = [];
  for (const amount of amounts) {
    amountsData.push(
      createPaymentAmountsData(
        amount.fixedAmount.amount,
        amount.fixedAmount.currency,
        amount.allowedPaymentMethods,
      ),
    );
  }
  return amountsData;
}

const PaymentAmounts: React.FC<PaymentAmountsProps> = ({
  amounts,
}): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Montant</TableCell>
            <TableCell>Devise</TableCell>
            <TableCell>Moyens de paiement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentAmountsRows({amounts}).map((amountData, index) => (
            <TableRow key={index}>
              <TableCell>
                {formatMaybeAmountInCents(Number(amountData.amount))}
              </TableCell>
              <TableCell>{amountData.currency}</TableCell>
              <TableCell>{amountData.methods}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PaymentInformations: React.FC<PaymentInformationsProps> = ({
  totalAmount,
  amounts,
}): JSX.Element => {
  return (
    <List>
      <PaymentAmounts amounts={amounts} />
      <Typography variant="body1" component="p">
        Montant total de la commande:{' '}
        {formatMaybeAmountInCents(Number(totalAmount.amount))}{' '}
        {totalAmount.currency}
      </Typography>
    </List>
  );
};

export default PaymentInformations;
