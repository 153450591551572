import {palette} from './palette';

export const MuiTablePagination = {
  styleOverrides: {
    root: {
      borderRadius: '0 0 8px 8px',
      boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
      backgroundColor: '#fff',

      '& .MuiToolbar-root': {
        borderRadius: '0 0 8px 8px',
      },
      '& .MuiInputBase-root': {
        color: palette.grey[500],
        fontFamily: 'Inter',
        size: '14px',
      },
      '& .MuiTablePagination-selectLabel': {
        color: palette.grey[500],
        fontFamily: 'Inter',
        size: '14px',
      },
      '& .MuiTablePagination-toolbar': {
        backgroundColor: 'white',
        borderTop: `2px solid ${palette.grey.light}`,
      },
      '& .MuiTablePagination-displayedRows': {
        color: palette.grey[500],
        fontFamily: 'Inter',
        size: '14px',
      },
      '& .MuiTablePagination-actions': {
        '& .MuiPagination-text': {
          '& .MuiPagination-ul': {
            '& .MuiPaginationItem-root': {
              color: palette.grey[500],
            },
            '& .MuiButtonBase-root': {
              color: palette.grey[500],
              fontFamily: 'Inter',
              size: '14px',
            },
          },
        },
      },
    },
  },
};
