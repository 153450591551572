import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const nirioBankDebtProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_LIST' || fetchType === 'GET_MANY_REFERENCE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        query allNirioBankDebts(
          $page: Int!
          $perPage: Int!
          $sortField: String!
          $sortOrder: String!
          $filter: NirioBankDebtFilterDto!
        ) {
          items: allNirioBankDebts(
            page: $page
            perPage: $perPage
            sortOrder: $sortOrder
            filter: $filter
            sortField: $sortField
          ) {
            id: cbsId
            cbsId
            createdAt
            amountInCents
            remainingAmountInCents
            origin
            operationType
            paidAt
            nirioUserId
            __typename
          }
          total: _allNirioBankDebtsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
          ) {
            count
            __typename
          }
        }
      `,
    };
  }

  return builtQuery;
};
