export const RaSelectArrayInputOverride = {
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        height: '56px',
        width: '332px',
        borderRadius: '8px',
        backgroundColor: 'white',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        overflow: 'hidden',
        border: '1px solid #E8E8E8',
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
        '&::before': {
          content: 'none',
        },
      },
    },
  },
};
