import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {AxiosError} from 'axios';
import frLocale from 'date-fns/locale/fr';
import {useState} from 'react';
import {Loading} from 'react-admin';

import {OnOff} from '../../../api/generated';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ErrorAlert} from '../../Alerts/ErrorAlert';
import {PageLayout} from '../../PageLayout';
import {makeTollRequest, TollParams} from '../api/makeTollRequest';
import {Result} from '../dto/service-requests.dto';
import Results from './PaymentSimulation/Results';

// TODO: Make generic and reusable ?
type State =
  | {
      state: 'PENDING';
    }
  | {
      state: 'LOADING';
    }
  | {
      state: 'ERROR';
      err: AxiosError | Error;
    }
  | {
      state: 'SUCCESS';
      value: Result;
    };
export const TollSimulator: React.FC = () => {
  const theme = useTheme();
  const [tollParams, setTollParams] = useState<TollParams>({
    retailerRoleCode: '',
    licensePlate: '',
    operatorId: '',
    createdAfter: null,
    createdBefore: null,
  });
  const [request, setRequest] = useState<State>({state: 'PENDING'});
  const [jsonMode, setJsonMode] = useState(false);

  const submitRequest = async (): Promise<void> => {
    try {
      setRequest({state: 'LOADING'});
      const result = await makeTollRequest(tollParams);
      setRequest({state: 'SUCCESS', value: result});
    } catch (err) {
      setRequest({
        state: 'ERROR',
        err:
          err instanceof AxiosError || err instanceof Error
            ? err
            : new Error(String(err)),
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <PageLayout>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={theme.spacing(2)}
          sx={{
            padding: theme.spacing(2),
          }}>
          {request.state === 'ERROR' && (
            <ErrorAlert
              error={request.err}
              onClose={(): void => setRequest({state: 'PENDING'})}
            />
          )}

          {request.state === 'SUCCESS' ? (
            <Results
              result={request.value}
              retailerRoleCode={tollParams.retailerRoleCode}
              operatorId={tollParams.operatorId}
              jsonMode={jsonMode}
              onJsonModeChange={setJsonMode}
              onReset={(): void => setRequest({state: 'PENDING'})}
              rescanText="Rechercher à nouveau"
            />
          ) : (
            <TollSimulatorConfig
              value={tollParams}
              onChange={setTollParams}
              onSubmit={(): void => void submitRequest()}
            />
          )}
        </Stack>
        {request.state === 'LOADING' && <Loading />}
      </PageLayout>
    </LocalizationProvider>
  );
};

const TollSimulatorConfig: React.FC<{
  value: TollParams;
  onChange: (value: TollParams) => void;
  onSubmit: () => void;
}> = ({value, onChange, onSubmit}) => {
  const featureFlags = useFeatureFlags();
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h3"
          component="h3"
          color="secondary"
          sx={{marginBottom: theme.spacing(2)}}>
          Paramètres de la recherche
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gap={2}
          width="650px">
          <TextField
            required
            label="Code du détaillant"
            variant="outlined"
            type="number"
            error={!value.retailerRoleCode}
            helperText={
              !value.retailerRoleCode &&
              'Le code du détaillant doit être renseigné'
            }
            value={value.retailerRoleCode}
            onChange={(e): void =>
              onChange({
                ...value,
                retailerRoleCode: e.target.value,
              })
            }
          />

          <TextField
            required
            label="Plaque d'immatriculation"
            variant="outlined"
            placeholder="AA123AA"
            error={!value.licensePlate}
            helperText={
              !value.licensePlate &&
              "La plaque d'immatriculation doit être renseignée"
            }
            value={value.licensePlate}
            onChange={(e): void =>
              onChange({
                ...value,
                licensePlate: e.target.value,
              })
            }
          />

          <TextField
            required={featureFlags.operatorAuthorizationCheck === OnOff.On}
            label="Code de l'opérateur"
            variant="outlined"
            error={
              featureFlags.operatorAuthorizationCheck === OnOff.On &&
              !value.operatorId
            }
            helperText={
              featureFlags.operatorAuthorizationCheck === OnOff.On &&
              !value.operatorId &&
              "Le code de l'opérateur doit être renseigné"
            }
            value={value.operatorId}
            onChange={(e): void =>
              onChange({
                ...value,
                operatorId: e.target.value,
              })
            }
          />

          <DateTimePicker
            label="Après le "
            value={value.createdAfter}
            onChange={(createdAfter): void =>
              onChange({
                ...value,
                createdAfter,
              })
            }
            renderInput={(inputProps): JSX.Element => (
              <TextField
                {...inputProps}
                variant="outlined"
                inputProps={{
                  ...inputProps.inputProps,
                  placeholder: 'jj/mm/aaaa hh:mm',
                }}
              />
            )}
          />

          <DateTimePicker
            label="Avant le"
            value={value.createdBefore}
            onChange={(createdBefore): void =>
              onChange({
                ...value,
                createdBefore,
              })
            }
            renderInput={(inputProps): JSX.Element => (
              <TextField
                {...inputProps}
                variant="outlined"
                inputProps={{
                  ...inputProps.inputProps,
                  placeholder: 'jj/mm/aaaa hh:mm',
                }}
              />
            )}
          />

          <Box gridColumn="span 2" justifySelf="center">
            <Button
              variant="contained"
              disabled={
                (featureFlags.operatorAuthorizationCheck === OnOff.On &&
                  !value.operatorId) ||
                !value.retailerRoleCode ||
                !value.licensePlate
              }
              onClick={onSubmit}>
              Envoyer
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
