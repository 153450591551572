import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const cartProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_LIST' || fetchType === 'GET_MANY_REFERENCE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        query AllCarts(
          $page: Int!
          $perPage: Int!
          $sortField: String!
          $sortOrder: String!
          $filter: CartFilterDto!
        ) {
          items: allCarts(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            id
            createdAt
            updatedAt
            paidAt
            client {
              id
              __typename
            }
            payerId
            paymentWorkflows {
              id
              createdAt
              status
            }
            items {
              cartId
              createdAt
              updatedAt
              payable {
                id
                initialAmountInCents
                remainingAmountInCents
                product {
                  id
                  category {
                    label
                  }
                  partner {
                    name
                    id
                  }
                }
                __typename
              }
              payableId
              amountInCents
              serviceFeeInCents
              __typename
            }
            __typename
          }
          total: _allCartsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
          ) {
            count
            __typename
          }
        }
      `,
    };
  }

  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      query: gql`
        query Cart($id: ID!) {
          data: Cart(id: $id) {
            id
            createdAt
            updatedAt
            paidAt
            client {
              id
            }
            payerId
            items {
              cart {
                id
              }
              cartId
              createdAt
              updatedAt
              payable {
                id
                remainingAmountInCents
                initialAmountInCents
                product {
                  id
                  name
                  category {
                    id
                    label
                  }
                  partner {
                    id
                    name
                  }
                }
              }
              payableId
              amountInCents
              serviceFeeInCents
              cbsTransactionId
            }
            paymentWorkflows {
              id
            }
          }
        }
      `,
    };
  }

  return builtQuery;
};
