import {Box} from '@mui/material';
import {
  Button,
  EditBase,
  Form,
  useGetRecordId,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import {Link} from 'react-router-dom';

import {
  PermissionDto,
  PromotionSettingsType,
  useUpdatePromotionSettingsReferrerMutation,
  useUpdatePromotionSettingsRetailerMutation,
} from '../../../api/generated';
import {BreadCrumbHeader} from '../../../components/BreadCrumbHeader';
import {NirioSaveButton} from '../../../components/NirioSaveButton';
import {PageLayout} from '../../../components/PageLayout';
import {PermissionGuard} from '../../../components/PermissionGuard';
import {SettingsForm} from './SettingsForm';

export const SettingsEdit = (): JSX.Element => {
  const type = useGetRecordId();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const title = translate(`resources.PromotionSettings.fields.${type}`);

  const id =
    type === 'REFERRER_PROMOTION'
      ? PromotionSettingsType.ReferrerPromotion
      : PromotionSettingsType.RetailerPromotion;

  const useMutationHook = {
    RETAILER_PROMOTION: useUpdatePromotionSettingsRetailerMutation,
    REFERRER_PROMOTION: useUpdatePromotionSettingsReferrerMutation,
  }[type];

  if (!useMutationHook) {
    throw new Error(`No mutation hook found for type ${type}`);
  }

  const [updateSettings, {loading}] = useMutationHook();

  const onSubmit = async (data: Record<string, number>): Promise<void> => {
    try {
      await updateSettings({
        variables: {
          id,
          gainAmountInCents: data.gainAmountInCents,
          isActive: !!data.isActive,
          requiredPaymentsCount: data.requiredPaymentsCount,
          requiredPaymentsMinimumAmountInCents:
            data.requiredPaymentsMinimumAmountInCents,
          requiredPaymentsSlidingDays: data.requiredPaymentsSlidingDays,
          ...(type === PromotionSettingsType.ReferrerPromotion
            ? {referrerGainAmountInCents: data.referrerGainAmountInCents}
            : {retailerGainAmountInCents: data.retailerGainAmountInCents}),
          slidingMonthLimit: data.slidingMonthLimit,
          userLimit: data.userLimit,
        },
      });
      redirect(`/admin/PromotionSettings/${type}/show`);
    } catch (error) {
      notify('ra.notification.promotionSettingsEditError', {type: 'error'});
    }
  };

  return (
    <PermissionGuard permission={PermissionDto.PromotionWrite}>
      <EditBase>
        <PageLayout>
          <Form onSubmit={onSubmit}>
            <Box my={7}>
              <BreadCrumbHeader
                title={title}
                actions={[
                  <Button
                    key="back"
                    label="Annuler"
                    component={Link}
                    to="show"
                  />,
                  <NirioSaveButton key="save" disabled={loading} />,
                ]}
                backTo="show"
              />
            </Box>
            <SettingsForm />
          </Form>
        </PageLayout>
      </EditBase>
    </PermissionGuard>
  );
};
