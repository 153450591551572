import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {IconButton, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import {Result} from '../../../dto/service-requests.dto';
import PaymentInformations from './PaymentInformations';
import PeopleInformations from './PeopleInformations';
import TechnicalDetails from './TechnicalDetails';

type PrettyInformationsProps = {
  result?: Result;
  datamatrixContent?: string;
};
const PrettyInformations: React.FC<PrettyInformationsProps> = ({
  result,
  datamatrixContent,
}) => {
  if (!result) {
    return null;
  }

  return (
    <div
      style={{display: 'block', margin: '0.25rem'}}
      data-attribute="payer-informations">
      <div
        style={{
          display: 'flex',
        }}>
        <Typography variant="h3" component="h3">
          Informations de paiement
        </Typography>
        <Tooltip title="Copier ces informations au format JSON">
          <IconButton
            onClick={(): void => {
              navigator.clipboard
                .writeText(JSON.stringify(result, null, 4))
                .catch(_err => {
                  return;
                });
            }}
            color="primary">
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Typography variant="h5" component="h5">
        Détails Techniques
      </Typography>
      <TechnicalDetails
        uuid={result.uuid}
        externalId={result.externalId}
        datamatrixContent={datamatrixContent}
        partnerName={result.service.name}
        isFinancial={result.service.operationType.isFinancial}
        collectionType={result.service.operationType.type}
        collectionTypeName={result.service.operationType.name}
      />

      <Typography variant="h5" component="h5">
        Informations Personne
      </Typography>
      <PeopleInformations personInfo={result.payer} />

      <Typography variant="h5" component="h5">
        Opérations
      </Typography>
      <PaymentInformations
        totalAmount={result.totalAmount}
        amounts={result.paymentAmounts}
      />
    </div>
  );
};

export default PrettyInformations;
