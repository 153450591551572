import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {AxiosError} from 'axios';
import {useState} from 'react';
import {
  DateTimeInput,
  Loading,
  SaveButton,
  TextInput,
  Title,
  useTranslate,
} from 'react-admin';
import {useSearchParams} from 'react-router-dom';

import {Form} from '../../Form/Form';
import {JSONViewer} from '../../JSONViewer';
import {neptuneClient} from '../api/neptuneClient';

type OperationReportForm = {
  rdiRoleCode: string;
  type: string;
  fromDate: Date;
  toDate: Date;
};

export const OperationReport = (): JSX.Element => {
  const [queryParams] = useSearchParams();

  const translate = useTranslate();

  const yesterday = new Date(Date.now() - 86400000);
  const now = new Date();

  const defaultValues: OperationReportForm = {
    rdiRoleCode: queryParams.get('rdiRoleCode') ?? '',
    type: 'RES',
    fromDate: yesterday,
    toDate: now,
  };

  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState<unknown>({});

  const handleSubmit = async ({
    rdiRoleCode,
    type,
    ...values
  }: OperationReportForm): Promise<void> => {
    setLoading(true);

    try {
      const client = await neptuneClient(rdiRoleCode);
      const {data} = await client.get<unknown>(
        `/operation/operations/report/${type}`,
        {
          params: values,
        },
      );

      setJson(data);
    } catch (e) {
      setJson((e as AxiosError).response?.data);
    }

    setLoading(false);
  };

  return (
    <Stack spacing={2} sx={{mt: 2, mb: 2}}>
      <Card>
        <Title title="Neptune ⟩ Operation ⟩ Report" />
        <CardContent>
          <Form
            onSubmit={handleSubmit as never}
            defaultValues={defaultValues}
            resetOptions={{keepValues: true}}>
            <Stack>
              <TextInput source="rdiRoleCode" />
              <TextInput source="type" />
              <DateTimeInput source="fromDate" />
              <DateTimeInput source="toDate" />
              <SaveButton
                alwaysEnable
                icon={<SendIcon />}
                label={translate('ra.action.confirm')}
              />
            </Stack>
          </Form>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          {loading ? <Loading /> : <JSONViewer data={json} />}
        </CardContent>
      </Card>
    </Stack>
  );
};
