import Decimal from 'decimal.js';
type DecimalInput = string | number | null | undefined;

const canDoDecimal = (value: DecimalInput): value is number => {
  return (
    value !== null &&
    value !== undefined &&
    typeof value === 'number' &&
    !isNaN(value)
  );
};

/**
 * Divide will throw if it got passed null or ''. Which our inputs' `parse` and `format` method are totally doing.
 * @param value
 * @param divideBy
 */
export function safeDivide<T extends DecimalInput>(
  value: T,
  divideBy: number,
): number | T {
  if (!canDoDecimal(value)) {
    return value;
  }
  return new Decimal(value).div(divideBy).toNumber();
}

export function safeMultiply<T extends DecimalInput>(
  value: T,
  multiplyBy: number,
): T | number {
  if (!canDoDecimal(value)) {
    return value;
  }
  return new Decimal(value).times(multiplyBy).toNumber();
}
