import Stack, {StackProps} from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

const StyledErrorModal = styled(Stack)<StackProps>(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  border: '2px solid #000',
  boxShadow: '24,',
  padding: '32px',
  backgroundColor: 'white',
}));

export default StyledErrorModal;
