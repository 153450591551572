import crypto from 'crypto';

export function generateCartRevisionHash(
  cartId: string,
  updatedAt: Date,
): string {
  const revision = `${cartId};${updatedAt.toISOString()}`;

  return crypto.createHash('md5').update(revision).digest('hex');
}
