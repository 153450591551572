import {palette} from './palette';

export const MuiTextFieldOverride = {
  styleOverrides: {
    root: {
      '& .MuiFormLabel-root': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        color: palette.grey[500],
        left: '4px',
        top: '9px',
      },

      '& .MuiInputLabel-shrink': {
        top: '-8px',
      },
      '.MuiInputBase-root': {
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
        border: ' 1px solid #E8E8E8',
        borderRadius: '8px',
        height: '56px',
        backgroundColor: '#FFFFFF',

        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none',
        },
      },
      '& .MuiInputBase-multiline': {
        minHeight: '56px',
        height: 'auto',
      },
    },
  },
};
