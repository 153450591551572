import {Card, CardContent, CardHeader, Grid} from '@mui/material';
import {minValue, NumberInput, required, useGetRecordId} from 'react-admin';

import {PromotionSettingsType} from '../../../api/generated';
import {StyledSwitch} from '../../../components/Form/Inputs/StyledSwitch';
import {
  numberInputFormatFromCents,
  numberInputParseToCents,
} from '../../../utils/input.utils';

export const SettingsForm = (): JSX.Element => {
  const type = useGetRecordId();

  const field =
    type === PromotionSettingsType.ReferrerPromotion
      ? 'referrerGainAmountInCents'
      : 'retailerGainAmountInCents';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader
            title="Conditions d'activation"
            action={
              <StyledSwitch
                defaultValue={true}
                label="Actif"
                source="isActive"
              />
            }
          />
          <CardContent>
            <NumberInput
              key="userLimit"
              source="userLimit"
              min={1}
              step={1}
              validate={[
                required(),
                minValue(
                  1,
                  "La limite d'utilisateurs doit être supérieure ou égale à 1",
                ),
              ]}
              fullWidth
            />
            <NumberInput
              key="slidingMonthLimit"
              source="slidingMonthLimit"
              min={1}
              step={1}
              validate={[
                required(),
                minValue(
                  1,
                  'Le nombre de mois glissants doit être supérieur ou égal à 1',
                ),
              ]}
              fullWidth
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader title="Gain" />
          <CardContent>
            <NumberInput
              key="gainAmountInCents"
              source="gainAmountInCents"
              min={0.01}
              step={0.01}
              format={numberInputFormatFromCents}
              parse={numberInputParseToCents}
              validate={[required()]}
              fullWidth
            />
            <NumberInput
              key={field}
              source={field}
              min={0.01}
              step={0.01}
              format={numberInputFormatFromCents}
              parse={numberInputParseToCents}
              validate={[
                required(),
                minValue(
                  0.01,
                  'Le montant maximum autorisé doit être supérieur à 0',
                ),
              ]}
              fullWidth
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
