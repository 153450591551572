import {FC} from 'react';

import {useGetNirioBankDebtIndicatorQuery} from '../../api/generated';
import {FilterCardContainer} from '../../components/FilterCard/FilterCardContainer';
import {FilterCardInline} from '../../components/FilterCard/FilterCardInline';
import {Icons} from '../../components/Icon';
import {formatMaybeAmountInCentsWithDecimals} from '../../utils/format-maybe-amount-in-cents';
import {DEBT_ORIGIN_TYPES, FEE_ORIGIN_TYPES} from './helpers';

export interface NirioBankDebtCardsProps {
  alwaysOn: boolean;
  source: string;
}

export const NirioBankDebtCards: FC<NirioBankDebtCardsProps> = () => {
  const totalDueNirioBankDebt = useGetNirioBankDebtIndicatorQuery({
    variables: {
      filter: {
        origins: DEBT_ORIGIN_TYPES,
      },
    },
  });
  const totalDueNirioBankDebtFee = useGetNirioBankDebtIndicatorQuery({
    variables: {
      filter: {
        origins: FEE_ORIGIN_TYPES,
      },
    },
  });

  const loading =
    totalDueNirioBankDebt.loading && totalDueNirioBankDebtFee.loading;

  return !loading &&
    totalDueNirioBankDebt.data &&
    totalDueNirioBankDebtFee.data ? (
    <FilterCardContainer>
      <>
        <FilterCardInline
          count={formatMaybeAmountInCentsWithDecimals(
            totalDueNirioBankDebt.data.nirioBankDebtSum,
            0,
          )}
          icon={Icons.Bell}
          title="Dette totale"
        />
        <FilterCardInline
          count={formatMaybeAmountInCentsWithDecimals(
            totalDueNirioBankDebtFee.data.nirioBankDebtSum,
            0,
          )}
          icon={Icons.Bell}
          title="Impayés"
        />
      </>
    </FilterCardContainer>
  ) : null;
};
