import {PermissionDto} from '../../../api/generated';
import {NirioCreate} from '../../../components/NirioCreate';
import {PermissionGuard} from '../../../components/PermissionGuard';
import {WelcomePromotionForm} from './WelcomePromotionForm';

export const WelcomePromotionCreate = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.PromotionWrite}>
    <NirioCreate title="Nouvelle promotion">
      <WelcomePromotionForm />
    </NirioCreate>
  </PermissionGuard>
);
