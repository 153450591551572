import {Link, LinkProps} from '@mui/material';

import {stopPropagation} from '../utils/stop-propagation';

export const ExternalLink = (props: LinkProps): JSX.Element => {
  if (!props.href) {
    return <>{props.children}</>;
  }

  return <Link {...props} target="_blank" onClick={stopPropagation} />;
};
