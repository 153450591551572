import {usePermissions as usePermissionsRA} from 'react-admin';

import {PermissionDto} from '../api/generated';

export const usePermissions = (): {
  can: (permission: PermissionDto) => boolean;
  isLoading: boolean;
  permissions: PermissionDto[];
} => {
  const {isLoading, permissions = []} = usePermissionsRA<PermissionDto[]>();

  return {
    can: (permission: PermissionDto): boolean =>
      hasPermission(permissions, permission),
    isLoading,
    permissions,
  };
};

export const hasPermission = (
  permissions: PermissionDto[],
  permission: PermissionDto,
): boolean => permissions.includes(permission);

export const isPermission = (u: unknown): u is PermissionDto => {
  return (
    typeof u === 'string' &&
    Object.values(PermissionDto).includes(u as PermissionDto)
  );
};
