import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const retailerProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_LIST') {
    return {
      ...builtQuery,
      query: gql`
        query allRetailers(
          $page: Int!
          $perPage: Int!
          $sortField: String!
          $sortOrder: String!
          $filter: RetailerFilterDto!
        ) {
          items: allRetailers(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            id
            createdAt
            cbsAgentId
            externalUrl
            rdiPointOfSaleCode
            rdiRoleCode
            name
            status
            serviceOffers {
              id
              formattedId
            }
          }
          total: _allRetailersMeta(
            page: $page
            perPage: $perPage
            filter: $filter
          ) {
            count
          }
        }
      `,
    };
  }

  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      query: gql`
        query Retailer($id: String!) {
          data: Retailer(id: $id) {
            id
            cbsAgentId
            externalUrl
            rdiPointOfSaleCode
            rdiRoleCode
            name
            status
            address
            acprFormationStatus
            acprAgentStatus
            rdiAcprAgentCode
            reasonsForBlock
            serviceOffers {
              id
              formattedId
              label
              productCategoryCodes
            }
            velocity {
              limitValueInCents
              currentValueInCents
            }
          }
        }
      `,
    };
  }

  return builtQuery;
};
