import GenerateIcon from '@mui/icons-material/Loop';
import {IconButton, InputAdornment, styled, Typography} from '@mui/material';
import copy from 'copy-to-clipboard';
import {generate} from 'generate-password';
import {useState} from 'react';
import {regex, required, TextInput, useNotify} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {StyledStack} from './helpers';

const StyledTypography = styled(Typography)({
  margin: '15px 0',
});

const PASSWORD_LENGTH = 16;

export const PartnerAuthInputs = ({
  sourceBase,
}: {
  sourceBase: string;
}): JSX.Element => {
  const notify = useNotify();

  const passwordSource = `${sourceBase}.password`;
  const usernameSource = `${sourceBase}.username`;

  const {setValue, getValues} = useFormContext();

  const currentUsername = (getValues(usernameSource) || undefined) as
    | string
    | undefined;
  const [initialUsername] = useState(currentUsername);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const changePassword = (): void => {
    const password = generate({
      length: PASSWORD_LENGTH,
      numbers: true,
    });
    copy(password);
    setValue(passwordSource, password);
    setPasswordChanged(true);
    notify('ra.notification.passwordCopiedToClipboard');
  };
  const usernameRegexValidator = regex(
    /^[\w-]+$/,
    'Ne peut contenir que des caractères alphanumériques',
  );

  return (
    <StyledStack>
      <StyledTypography color="gray">
        Identifiants API rapports d&apos;émargement
      </StyledTypography>
      <TextInput
        source={usernameSource}
        validate={
          passwordChanged
            ? [
                usernameRegexValidator,
                required(
                  "Le nom d'utilisateur est obligatoire (le mot de passe a été modifié)",
                ),
              ]
            : usernameRegexValidator
        }
      />
      <TextInput
        type={passwordChanged ? 'text' : 'password'}
        disabled={true}
        validate={
          currentUsername !== initialUsername
            ? required(
                "Veuillez modifier le mot de passe (le nom d'utilisateur a été modifié)",
              )
            : undefined
        }
        format={(value: string): string =>
          passwordChanged
            ? value
            : initialUsername === undefined
            ? ''
            : 'password-hidden'
        }
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={changePassword} size="large">
                <GenerateIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        source={passwordSource}
      />
    </StyledStack>
  );
};
