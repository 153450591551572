import {Typography} from '@mui/material';
import {useCallback} from 'react';
import {
  Datagrid,
  FunctionField,
  Labeled,
  ListContextProvider,
  RaRecord,
  TextField,
  useList,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import {
  PermissionDto,
  Retailer,
  RetailerStatus,
  ServiceOfferDto,
} from '../../api/generated';
import {CustomShow} from '../../components/CustomShow';
import {ExternalLink} from '../../components/ExternalLink';
import {LabeledFunctionField} from '../../components/Fields/LabeledFunctionField';
import {LabeledTextField} from '../../components/Fields/LabeledTextField';
import {PermissionGuard} from '../../components/PermissionGuard';
import {safeDivide, safeMultiply} from '../../utils/numeric.utils';

const dataTab = [
  {
    title: 'Détaillant',
    fields: [
      <LabeledTextField source="rdiRoleCode" key="rdiRoleCode" />,
      <Labeled key="cbsAgentId">
        <FunctionField
          source="cbsAgentId"
          render={(record: Retailer): JSX.Element => (
            <ExternalLink href={record.externalUrl}>
              {record.cbsAgentId ?? 'Ø'}
            </ExternalLink>
          )}
        />
      </Labeled>,
      <LabeledFunctionField
        label="Statut détaillant"
        key="status"
        render={(record: Retailer): React.ReactElement => {
          switch (record.status) {
            case RetailerStatus.BlockedByRdi:
              return (
                <>
                  <Typography variant="body2">Bloqué par RDI</Typography>
                  {record.reasonsForBlock?.length && (
                    <Typography
                      variant="body2"
                      style={{color: 'red', fontStyle: 'italic'}}>
                      {record.reasonsForBlock
                        ?.map(item => {
                          switch (item) {
                            case 'NO_VALID_AGREEMENT':
                              return 'Agrément jeu invalide';
                            case 'INVALID_ACPR_STATUS':
                              return 'Statut ACPR invalide';
                            case 'INVALID_FORMATION_STATUS':
                              return 'Formation non valide';
                            case 'NO_PARTNER_SERVICE':
                              return 'Service partenaire non-ouvert';
                            default:
                              return 'Aucun blocage';
                          }
                        })
                        .join('; ')}
                    </Typography>
                  )}
                </>
              );
            case RetailerStatus.BlockedManually:
              return (
                <Typography variant="body2">Bloqué manuellement</Typography>
              );
            case RetailerStatus.Closed:
              return <Typography variant="body2">Fermé</Typography>;
            case RetailerStatus.Open:
              return <Typography variant="body2">Ouvert</Typography>;
            case RetailerStatus.Pending:
              return <Typography variant="body2">En attente</Typography>;
            default:
              return <Typography variant="body2">Inconnu</Typography>;
          }
        }}
      />,
      <LabeledFunctionField
        label="Encours hebdomadaire % au seuil de blocage"
        key="currentValueInCents"
        render={(record: Retailer): string => {
          if (record.velocity) {
            return `${safeDivide(
              record.velocity.currentValueInCents,
              100,
            )}€/${safeDivide(record.velocity.limitValueInCents, 100)}€`;
          } else {
            return '0€/6000€';
          }
        }}
      />,
      <LabeledFunctionField
        key="limitValue"
        label="Encours hebdomadaire % au seuil d’alerte"
        render={(record: Retailer): string => {
          if (record.velocity) {
            const formattedLimitValue = safeDivide(
              record.velocity.limitValueInCents,
              100,
            );
            return `${safeDivide(
              record.velocity.currentValueInCents,
              100,
            )}€/${safeMultiply(formattedLimitValue, 0.8)}€`;
          } else {
            return '0€/4800€';
          }
        }}
      />,
    ],
  },
  {
    title: 'Point de vente',
    fields: [
      <LabeledTextField source="rdiPointOfSaleCode" key="rdiPointOfSaleCode" />,
      <LabeledTextField source="name" key="name" />,
      <LabeledTextField source="address" key="address" />,
    ],
  },
  {
    title: 'Agent',
    fields: [
      <LabeledTextField
        source="rdiAcprAgentCode"
        key="rdiAcprAgentCode"
        label="Numéro agent ACPR"
      />,
      <LabeledTextField
        source="acprAgentStatus"
        key="acprAgentStatus"
        label="Statut agent ACPR"
      />,
      <LabeledTextField
        source="acprFormationStatus"
        key="acprFormationStatus"
        label="Statut formation ACPR"
      />,
    ],
  },
];

const RetailerGrid = (): JSX.Element => {
  const record = useRecordContext();

  const listContext = useList({
    data: record.serviceOffers as ServiceOfferDto[], // Cast in serviceOffer bc type any in react admin
    perPage: 10,
    page: 1,
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false} sx={{boxShadow: 'none'}}>
        <TextField label="Code" source="formattedId" />
        <TextField label="Libellé" source="label" />
        <TextField
          label="Codes produits correspondants"
          source="productCategoryCodes"
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export const RetailerShow = (): JSX.Element => {
  const translate = useTranslate();
  const retailerSingularName = translate('resources.Retailer.name', {
    // eslint-disable-next-line camelcase
    smart_count: 1,
  });

  const buildTitle = useCallback(
    (record?: RaRecord): string => {
      const code = (record?.rdiRoleCode as string) || '';
      return `${retailerSingularName} #${code}`;
    },
    [retailerSingularName],
  );

  return (
    <PermissionGuard permission={PermissionDto.RetailerRead}>
      <CustomShow
        renderTitle={buildTitle}
        topPapers={dataTab.map(item => ({
          title: item.title,
          content: item.fields,
        }))}
        mainPaperTitle="Offres de services"
        mainPaper={[
          {
            content: <RetailerGrid />,
          },
        ]}
      />
    </PermissionGuard>
  );
};
