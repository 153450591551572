import {styled} from '@mui/system';
import {FC} from 'react';
import {Button, useLogout, useResourceDefinitions} from 'react-admin';
import {useLocation} from 'react-router-dom';

import Nirio from '../../assets/logo.svg';
import {SideBarZIndex} from '../../constants';
import {isPermission, usePermissions} from '../../hooks/usePermissions';
import {palette} from '../../theme/palette';
import {nirioTheme} from '../../theme/theme';
import {intentionallyFloatingPromiseReturn} from '../../utils/intentionallyFloatingPromiseReturn';
import {IconComponentType} from '../Icon';
import {useFeatures} from './features';
import {SideBarItem} from './SideBarItem';

const SCROLLBAR_BACKGROUND_COLOR = palette.grey.light;
const SCROLLBAR_COLOR = palette.grey.mild;

const StyledSideBar = styled('div')({
  position: 'sticky',
  top: '0',
  height: '100vh',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `transparent ${SCROLLBAR_BACKGROUND_COLOR}`,
  '::-webkit-scrollbar': {width: nirioTheme.sidebar.scrollbar.width},
  '::-webkit-scrollbar-track': {
    background: SCROLLBAR_BACKGROUND_COLOR,
  },
  '::-webkit-scrollbar-thumb': {
    background: 'transparent',
    borderRadius: nirioTheme.sidebar.scrollbar.borderRadius,
  },
  ':hover': {
    scrollbarColor: `${SCROLLBAR_COLOR} ${SCROLLBAR_BACKGROUND_COLOR}`,
    '::-webkit-scrollbar-thumb': {
      background: SCROLLBAR_COLOR,
    },
  },
  zIndex: SideBarZIndex,
  boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
});

const StyledButtonContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  marginBottom: '32px',
});

const StyledIcon = styled('img')({
  height: '40px',
  alignSelf: 'flex-start',
  margin: '32px',
});

const toExclude = [
  'CarrouselImage',
  'CessationRaWorkflow',
  'PromotionSettings',
  'NirioBankWelcomePromotion',
];

export const SideBar: FC = () => {
  const resources = useResourceDefinitions();
  const features = useFeatures();
  const logout = useLogout();
  const location = useLocation();
  const pathname = location.pathname.split('/')[2];
  const {can} = usePermissions();

  return (
    <StyledSideBar sx={{minWidth: {sm: 300}}}>
      <StyledIcon
        src={Nirio}
        alt="Nirio"
        sx={{display: {xs: 'none', sm: 'block'}}}
      />
      {Object.entries(resources)
        .filter(([_, resource]) => !toExclude.includes(resource.name))
        .map(([name, resource]) => {
          const permission = (resource.options as Record<string, unknown>)
            ?.permission;

          if (isPermission(permission) && !can(permission)) {
            return null;
          }

          const path = `/admin/${name}`;
          // since resource.options is typed as any, we need to cast it
          const options = resource.options as
            | {
                label?: string;
              }
            | undefined;
          // since resources[name].name is typed as any we need to cast it
          const leftIcon = resources[name]?.icon as IconComponentType;

          return (
            <SideBarItem
              key={name}
              label={options?.label || name}
              to={path}
              leftIcon={leftIcon}
              isActive={pathname === name}
            />
          );
        })}

      {features.map(f => (
        <SideBarItem
          key={f.name}
          label={f.label}
          to={`/admin/${f.name}`}
          leftIcon={f.icon}
          isActive={pathname === f.name}
        />
      ))}

      <StyledButtonContainer>
        <Button
          onClick={intentionallyFloatingPromiseReturn(logout)}
          label="Déconnexion"
        />
      </StyledButtonContainer>
    </StyledSideBar>
  );
};
