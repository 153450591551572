import {palette} from './palette';
import {typography} from './typography';

export const MuiCardOverride = {
  styleOverrides: {
    root: {
      '& .MuiCardHeader-root': {
        padding: 32,
        paddingBottom: 0,
        '& .MuiCardHeader-title': {
          ...typography.h1,
          color: palette.secondary.main,
        },
      },
      '& .MuiCardContent-root': {
        padding: 32,
      },
    },
  },
};
