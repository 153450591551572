import {palette} from './palette';
import {typography} from './typography';

export const MuiDialogOverride = {
  styleOverrides: {
    root: {
      '& .MuiDialogTitle-root': {
        ...typography.h2,
        color: palette.secondary.main,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
      },
      '& .MuiDialogContent-root': {
        paddingLeft: 32,
        paddingRight: 32,
      },
      '& .MuiDialogActions-root': {
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        justifyContent: 'center',
        '&>:not(:first-of-type)': {
          marginLeft: 12,
        },
      },
      '& .RaConfirm-confirmPrimary': {
        backgroundColor: palette.primary.main,
        color: 'white!important',
      },
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },
  },
};
