import {Paper, Typography, useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import {FC, memo} from 'react';

import {IconComponentType} from '../Icon';

export interface FilterCardProps {
  title: string;
  onClick: () => void;
  count: number;
  icon: IconComponentType;
  isSelected?: boolean;
}

export const FilterCard: FC<FilterCardProps> = memo<FilterCardProps>(
  function FilterCard({title, onClick, icon: Icon, count, isSelected}) {
    const theme = useTheme();

    return (
      <Paper
        variant={isSelected ? 'outlined' : 'elevation'}
        component={Stack}
        onClick={onClick}
        key={title}
        justifyContent="space-between"
        sx={{
          width: 238,
          height: 290,
          padding: theme.spacing(4),
          boxSizing: 'border-box',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Stack direction="row" justifyContent="space-between" gap={1.5}>
          <Icon size={24} />
          <Typography variant="h1" color="secondary">
            {title}
          </Typography>
        </Stack>
        <Typography variant="h1" fontSize={42} textAlign="center">
          {count}
        </Typography>
      </Paper>
    );
  },
);
