/**
 *
 * @param promise
 * @returns {Promise} - the promise that was passed in
 *
 * This function is used to avoid the "no-floating-promises" linting error.
 * TODO: Remove this function when the linting error is fixed.
 */

export function intentionallyFloatingPromiseReturn<ARGS extends unknown[]>(
  fn: (...args: ARGS) => Promise<unknown>,
): (...args: ARGS) => void {
  return (...args) => {
    void fn(...args);
  };
}
