import {AxiosError} from 'axios';

import {parseAxiosError} from '../../utils/error.utils';
import {Alert, AlertInformations, Props as AlertProps} from './Alert';

interface Props extends Omit<AlertProps, 'informations'> {
  /**
   * @default 'error'
   */
  severity?: AlertProps['severity'];
  error: AxiosError | Error;
}
export function ErrorAlert({
  error,
  severity = 'error',
  ...rest
}: Props): JSX.Element {
  const parsedError: AlertInformations =
    error instanceof AxiosError
      ? parseAxiosError(error)
      : {
          title: error.name,
          message: error.message,
          details: typeof error.cause === 'string' ? error.cause : undefined,
        };

  return <Alert severity={severity} informations={parsedError} {...rest} />;
}
