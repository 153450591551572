import get from 'lodash/get';
import {DateTimeInputProps, Validator} from 'react-admin';

import {DatetimePicker} from '../Inputs/DatetimePicker';

interface FieldsType {
  startDate: string;
  endDate: string;
  currentField: string;
}
interface DateTimeFilterProps extends DateTimeInputProps {
  fields: FieldsType;
}

function generateDateValidator({
  startDate: startDateField,
  endDate: endDateField,
  currentField,
}: FieldsType): Validator {
  return (
    _value: string,
    values: {[key: string]: string},
  ): string | undefined => {
    const startDateFieldValue = get(values, startDateField);
    const endDateFieldValue = get(values, endDateField);
    const isStartDateGreaterThanEndDate =
      startDateFieldValue > endDateFieldValue;
    const isFieldEmpty = !startDateFieldValue || !endDateFieldValue;
    const isCurrenFieldVariableInvalid =
      currentField !== endDateField && currentField !== startDateField;

    if (isFieldEmpty) {
      return;
    }
    if (isCurrenFieldVariableInvalid) {
      throw new Error(
        'currentField must be either startDateField or endDateField',
      );
    }
    if (isStartDateGreaterThanEndDate) {
      const message =
        currentField === endDateField
          ? 'La date de fin doit être postérieure ou égale à la date de début.'
          : 'La date de début doit être antérieure ou égale à la date de fin.';
      return message;
    }
  };
}

export const DateFilter: React.FC<Omit<DateTimeFilterProps, 'required'>> = ({
  fields,
  validate,
  ...props
}) => (
  <DatetimePicker
    {...props}
    maxOutSeconds={fields.endDate === fields.currentField}
    validate={[generateDateValidator(fields), validate || []].flat()}
    showHelperText={false}
  />
);
