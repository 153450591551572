import {Link, LinkProps} from 'react-admin';

import {PermissionDto} from '../api/generated';
import {usePermissions} from '../hooks/usePermissions';
import {stopPropagation} from '../utils/stop-propagation';

export type PermissionLinkProps = LinkProps & {
  permission: PermissionDto;
};

export const PermissionLink = ({
  children,
  permission,
  onClick = stopPropagation,
  ...props
}: PermissionLinkProps): JSX.Element => {
  const {can} = usePermissions();

  if (!can(permission)) {
    return <>{children}</>;
  }

  return (
    <Link onClick={onClick} {...props}>
      {children}
    </Link>
  );
};
