import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {ArrayInput, TextField} from 'react-admin';
import {useFieldArray} from 'react-hook-form';

import {PermissionDto} from '../../api/generated';
import {StyledSwitch} from '../../components/Form/Inputs/StyledSwitch';
import {PermissionLink} from '../../components/PermissionLink';

const ColumnTitle = styled(TableCell)({
  fontWeight: 'bold',
  fontFamily: 'Chance',
  color: '#2222B3',
  fontSize: '16px',
});

const PartnerProductsIterator = (): JSX.Element => {
  const {fields} = useFieldArray<
    {products: {id: string}[]},
    'products',
    'indexKey'
  >({
    name: 'products',
    keyName: 'indexKey', // useFieldArray adds a [keyname] field to each object of the array. Default is "id", which would override the products' ids if not changed
  });

  if (!fields.length) {
    return <Typography>Aucun résultat</Typography>;
  }

  return (
    <ArrayInput source="products" label="">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{'& .MuiTableCell-head': {fontWeight: 'bold'}}}>
              <ColumnTitle>Libellé produit</ColumnTitle>
              <ColumnTitle>Catégorie</ColumnTitle>
              <ColumnTitle>Statut</ColumnTitle>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((product, index) => (
              <TableRow hover tabIndex={-1} key={index}>
                <TableCell>
                  <PermissionLink
                    permission={PermissionDto.ProductRead}
                    to={`/admin/Product/${product.id}/show`}>
                    <TextField source={`products[${index}].name`} />
                  </PermissionLink>
                </TableCell>
                <TableCell>
                  <TextField source={`products[${index}].category.label`} />
                </TableCell>
                <TableCell>
                  <StyledSwitch
                    label="Actif"
                    source={`products[${index}].active`}
                    sx={{'& p': {display: 'none'}}}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayInput>
  );
};

export default PartnerProductsIterator;
