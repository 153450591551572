import {ListControllerResult, RaRecord, useListController} from 'react-admin';

import {Override} from '../utils/override-types.utils';

type ListControllerResultTyped = Override<
  ListControllerResult,
  {
    filterValues: Record<string, string | number | boolean>;
    displayedFilters: Record<string, string | number | boolean>;
  }
>;

export function useListControllerTyped<
  T extends RaRecord,
>(): ListControllerResultTyped {
  return useListController<T>();
}
