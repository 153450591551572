import {BuildQueryResult} from 'ra-data-graphql';

import {AppBasicContentHtmlInput, AppBasicContentType} from '../generated';

export const appBasicContentProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'UPDATE') {
    const variables = builtQuery.variables as {
      type: AppBasicContentType;
      content: AppBasicContentHtmlInput;
    };
    return {
      ...builtQuery,
      variables: {
        ...variables,
        // avoid errors due to __typename being included in embedded schema
        // see https://github.com/marmelab/react-admin/issues/2840
        content: {
          ...variables.content,
          __typename: undefined,
        },
      },
    };
  }

  return builtQuery;
};
