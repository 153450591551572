import {styled} from '@mui/system';
import {AxiosError} from 'axios';
import jsQr from 'jsqr';
import {RefObject, useCallback, useContext, useEffect, useRef} from 'react';

import {OnOff} from '../../../../../api/generated';
import {useFeatureFlags} from '../../../../../hooks/useFeatureFlags';
import {makeRequestWithDataMatrixContent} from '../../../api/makeRequestWithDataMatrixContent';
import {SimulationContext} from '../../../context/context';
import {ActionTypes} from '../../../context/reducer.types';
import ViewFinder from './ViewFinder';

const CroppedVideoContainer = styled('div')({
  width: '400px',
  height: '400px',
  overflow: 'hidden',
  position: 'relative',
  ' > video': {
    marginLeft: '-120px',
    transform: 'rotateY(180deg)',
  },
});

function grabFrame(
  videoRef: RefObject<HTMLVideoElement>,
  photoRef: RefObject<HTMLCanvasElement>,
  onResult: (result: string | null) => Promise<void>,
): void {
  const videoElement = videoRef.current;
  const photoElement = photoRef.current;
  if (videoElement && photoElement) {
    const photoElementContext = photoElement.getContext('2d', {
      willReadFrequently: true,
    });
    if (photoElementContext) {
      photoElement.width = videoElement.videoWidth;
      photoElement.height = videoElement.videoHeight;
      photoElementContext.filter = 'contrast(5) brightness(1.5)';
      photoElementContext.drawImage(videoElement, 0, 0, 400, 400);
      const imageData = photoElementContext.getImageData(0, 0, 400, 400);
      const code =
        imageData && jsQr(imageData.data, imageData.width, imageData.height);
      void onResult(code?.data ?? null);
    }
  }
}

function stopVideoStreamAndCapture(
  videoRef: RefObject<HTMLVideoElement>,
): void {
  const videoElement = videoRef.current;
  if (videoElement) {
    const videoStream = videoElement.srcObject as MediaStream;

    videoStream &&
      videoStream.getTracks().forEach(track => {
        track.stop();
      });
    videoElement.srcObject = null;
  }
}

export const Reader: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const {state, dispatch} = useContext(SimulationContext);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({video: true})
      .then(async videoStream => {
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.srcObject = videoStream;
          await videoElement.play();
        }
      })
      .catch((err: Error) => {
        if (err.name === 'NotAllowedError') {
          dispatch({
            type: ActionTypes.SetError,
            payload: {error: new Error("L'accès à la caméra a été refusé")},
          });
        }
        return;
      });
  }, [dispatch]);

  const onResult = useCallback(
    async (result: string | null): Promise<void> => {
      try {
        if (result === null) {
          return;
        }
        if (state.retailerRoleCode === '') {
          dispatch({
            type: ActionTypes.SetError,
            payload: {
              error: new Error("Le code du détaillant n'est pas renseigné"),
            },
          });
          return;
        }
        if (
          featureFlags.operatorAuthorizationCheck === OnOff.On &&
          !state.operatorId
        ) {
          dispatch({
            type: ActionTypes.SetError,
            payload: {
              error: new Error("Le code de l'opérateur n'est pas renseigné"),
            },
          });
          return;
        }
        dispatch({
          type: ActionTypes.SetCode,
          payload: {code: result},
        });
        await makeRequestWithDataMatrixContent(
          state.retailerRoleCode,
          state.operatorId,
          result,
          dispatch,
        );
      } catch (err) {
        if (err instanceof Error || err instanceof AxiosError) {
          dispatch({
            type: ActionTypes.SetError,
            payload: {error: err},
          });
        }
      }
    },
    [
      dispatch,
      state.retailerRoleCode,
      state.operatorId,
      featureFlags.operatorAuthorizationCheck,
    ],
  );

  useEffect(() => {
    const autoScanInterval = setInterval(() => {
      if (!state.locked) {
        grabFrame(videoRef, photoRef, onResult);
      }
    }, 300);
    return () => {
      clearInterval(autoScanInterval);
    };
  }, [state.locked, dispatch, onResult]);

  useEffect(() => {
    if (state.result) {
      stopVideoStreamAndCapture(videoRef);
    }
  }, [state.result]);
  return (
    <>
      <CroppedVideoContainer>
        <ViewFinder />
        <video ref={videoRef} />
      </CroppedVideoContainer>
      <canvas ref={photoRef} hidden />
    </>
  );
};
