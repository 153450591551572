import {Result} from '../dto/service-requests.dto';
import {neptuneClient} from './neptuneClient';

export type TollParams = {
  retailerRoleCode: string;
  licensePlate: string;
  operatorId: string;
  createdAfter: Date | null;
  createdBefore: Date | null;
};

export async function makeTollRequest({
  retailerRoleCode,
  operatorId,
  ...params
}: TollParams): Promise<Result> {
  const client = await neptuneClient(retailerRoleCode, operatorId);

  const response = await client.post<Result>(
    '/serviceRequests',
    {
      ...params,
      // null -> undefined
      createdAfter: params.createdAfter ?? undefined,
      createdBefore: params.createdBefore ?? undefined,
    },
    {
      headers: {
        Accept:
          'application/vnd.fgs.ese.orchestration.servicerequest.pending-3+json, application/vnd.lotsys.falcon.error-1+json',
        'Content-Type':
          'application/vnd.fgs.ese.orchestration.servicerequest.creation-1+json; charset=utf-8',
      },
    },
  );
  return response.data;
}
