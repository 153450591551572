import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {
  Button,
  minValue,
  NumberInput,
  regex,
  required,
  TextInput,
  useGetRecordId,
  useNotify,
  useTranslate,
} from 'react-admin';
import {useQueryClient} from 'react-query';

import {useCreateNirioBankWelcomeCodeMultipleUsageMutation} from '../../../api/generated';
import {Form} from '../../../components/Form/Form';
import {NirioSaveButton} from '../../../components/NirioSaveButton';
import {isNotOnlySpaces} from '../../../utils/input.utils';

const MultipleCodeForm = (): JSX.Element => {
  const translate = useTranslate();
  const validateFormatCode = regex(
    /[a-z]/i,
    'Le code doit contenir au moins une lettre',
  );

  return (
    <div>
      <TextInput
        key="code"
        source="code"
        label={translate('resources.NirioBankWelcomeCode.fields.code')}
        validate={[required(), isNotOnlySpaces(), validateFormatCode]}
        fullWidth
        inputProps={{style: {textTransform: 'uppercase'}}}
      />
      <NumberInput
        key="maxClaimed"
        source="maxClaimed"
        label={translate('resources.NirioBankWelcomeCode.fields.maxClaimed')}
        min={2}
        step={1}
        validate={[
          required(),
          minValue(
            2,
            'Le nombre de codes à générer doit être supérieur ou égal à 2',
          ),
        ]}
        fullWidth
      />
    </div>
  );
};

export const GenerateCodesDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): JSX.Element => {
  const promotionId = useGetRecordId();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [createCodeMultipleUsageMutation, {loading}] =
    useCreateNirioBankWelcomeCodeMultipleUsageMutation();

  const onSubmit = async (data: Record<string, string>): Promise<void> => {
    try {
      const {data: result} = await createCodeMultipleUsageMutation({
        variables: {
          promotionId: String(promotionId),
          code: data.code,
          maxClaimed: Number(data.maxClaimed),
        },
      });
      if (
        result?.createNirioBankWelcomeCodeMultipleUsage &&
        'codeErr' in result.createNirioBankWelcomeCodeMultipleUsage
      ) {
        notify('ra.notification.welcomeCodeCreateError', {type: 'error'});
        return;
      }
      await queryClient.invalidateQueries('NirioBankWelcomeCode');
      onClose();
    } catch (e) {
      notify('ra.notification.welcomeCodeCreateError', {type: 'error'});
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Générer des codes promotionnels</DialogTitle>
      <Form onSubmit={onSubmit}>
        <DialogContent>
          <MultipleCodeForm />
        </DialogContent>
        <DialogActions>
          <Button key="back" label="Annuler" onClick={onClose} />
          <NirioSaveButton key="save" label="Générer" disabled={loading} />
        </DialogActions>
      </Form>
    </Dialog>
  );
};
