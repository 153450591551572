import {Paper, Typography, useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import {FC, memo} from 'react';

import {IconComponentType} from '../Icon';

export interface FilterCardProps {
  title: string;
  count: number | string;
  icon: IconComponentType;
  isSelected?: boolean;
}

export const FilterCardInline: FC<FilterCardProps> = memo<FilterCardProps>(
  function FilterCard({title, icon: Icon, count}) {
    const theme = useTheme();

    return (
      <Paper
        variant="elevation"
        component={Stack}
        key={title}
        justifyContent="center"
        sx={{
          width: 350,
          height: 56,
          padding: theme.spacing(2),
          boxSizing: 'border-box',
        }}>
        <Stack direction="row" justifyContent="space-between" gap={1.5}>
          <Icon size={24} />
          <Typography variant="h2" color="secondary">
            {title}
          </Typography>
          <Typography variant="h2" textAlign="center">
            {count}
          </Typography>
        </Stack>
      </Paper>
    );
  },
);
