import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const partnerProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      //Default Provider doesn't reach down to nested references properties except for their ID, override needed to get products' names and active statuses
      query: gql`
        query Partner($id: ID!) {
          data: Partner(id: $id) {
            id
            codeDetailLB
            datamatrixPartnerId
            datamatrixSignatureConfig
            displayOrderInApp
            isDisplayed
            name
            logo
            createdAt
            products {
              active
              id
              name
              createdAt
              updatedAt
              category {
                label
                code
              }
            }
            partnerAuth {
              username
            }
          }
        }
      `,
    };
  }

  return builtQuery;
};
