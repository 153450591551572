import {Input, InputLabel, Stack} from '@mui/material';
import React from 'react';

import StyledButton from '../../../theme/StyledButton';

const MagicLinkLoginRequestForm = ({
  handleSubmit,
  setInputEmail,
  inputEmail,
}: {
  inputEmail: string;
  setInputEmail: React.Dispatch<string>;
  handleSubmit: React.FormEventHandler<EventTarget>;
}): JSX.Element => (
  <Stack alignItems="center" component="form" onSubmit={handleSubmit}>
    <Stack>
      <InputLabel htmlFor="email">
        Saisissez votre email professionnel
      </InputLabel>
      <Input
        id="email"
        type="email"
        value={inputEmail}
        disableUnderline
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setInputEmail(e.target.value)
        }
        sx={{
          fontSize: 'large',
          padding: '0 6px',
          borderRadius: '6px',
          border: '2px solid darkBlue',
          width: '320px',
        }}
        autoFocus
      />
    </Stack>
    <StyledButton
      onClick={handleSubmit}
      fullWidth
      disabled={inputEmail.length === 0}
      variant="contained">
      Envoyer lien de connexion
    </StyledButton>
  </Stack>
);

export default MagicLinkLoginRequestForm;
