import TurnbackIcon from '@mui/icons-material/KeyboardBackspace';
import {Box} from '@mui/material';
import {
  Datagrid,
  Edit,
  EditButton,
  Link,
  List,
  maxLength,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import {PermissionDto} from '../../api/generated';
import {FormToolbar} from '../../components/Form/FormToolbar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {usePermissions} from '../../hooks/usePermissions';

export const CustomerServiceInfoList = (): JSX.Element => {
  const {can} = usePermissions();
  return (
    <PermissionGuard permission={PermissionDto.CustomerServiceRead}>
      <List>
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="phoneNumber"
            label="Numéro de téléphone"
            sortable={false}
          />
          <TextField
            source="openingHours"
            label="Horaires d'ouvertures"
            sortable={false}
          />
          {can(PermissionDto.CustomerServiceWrite) && <EditButton />}
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};

export const CustomerServiceInfoEdit = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.CustomerServiceWrite}>
      <Edit
        sx={{width: '100%', maxWidth: '1000px', margin: '0 auto'}}
        mutationMode="optimistic">
        <CustomerServiceInfoEditForm />
      </Edit>
    </PermissionGuard>
  );
};

const CustomerServiceInfoEditForm = (): JSX.Element => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <Link to="/admin/CustomerServiceInfo" title="Retour vers la liste">
        <TurnbackIcon />
      </Link>
      <Box
        display={{
          xs: 'block',
          sm: 'flex',
          width: '100%',
          marginTop: '20px',
        }}>
        <Box flex={1}>
          <TextInput
            fullWidth
            label="Numéro de téléphone"
            source="phoneNumber"
            validate={[
              maxLength(
                30,
                'Le numéro de téléphone ne doit pas dépasser 30 caractères',
              ),
            ]}
          />
        </Box>
        <Box flex={1}>
          <TextInput
            fullWidth
            label="Horaires d'ouverture"
            source="openingHours"
            validate={[
              maxLength(
                150,
                "Les horaires d'ouverture ne doivent pas dépasser 150 caractères",
              ),
            ]}
          />
        </Box>
      </Box>
    </SimpleForm>
  );
};
