export const RaListOverride = {
  styleOverrides: {
    root: {
      padding: '0 42px 42px 42px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column-reverse',

      '& .filter-field:has(.filter-spacer)': {
        flex: 1,
      },

      '& .filter-field:has(.filter-card-container)': {
        width: '100%',
      },

      '& .RaFilterFormInput-spacer': {
        display: 'none',
      },

      '& .RaList-actions:not(:last-child)': {
        display: 'flex',
        flexDirection: 'column-reverse',
        margin: '0 0 62px 0',
        width: '100%',
        form: {
          display: 'flex',
          gap: '16px',
          width: '100%',
        },
      },

      '& .MuiFormControl-root': {
        margin: '0 0 0 0',
      },

      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },
  },
};
