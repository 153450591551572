import {defaultTheme} from 'react-admin';

import {MuiAppBarOverride} from './MuiAppBarOverride';
import {MuiButtonOverride} from './MuiButtonOverride';
import {MuiCardOverride} from './MuiCardOverride';
import {MuiDialogOverride} from './MuiDialogOverride';
import {MuiIconButtonOverride} from './MuiIconButtonOverride';
import {MuiLinkOverride} from './MuiLinkOverride';
import {MuiPaperOverride} from './MuiPaperOverride';
import {MuiTablePagination} from './MuiTablePagination';
import {MuiTextFieldOverride} from './MuiTextFieldOverride';
import {palette} from './palette';
import {RaDatagridOverride} from './RaDatagridOverride';
import {RaFilterFormOverride} from './RaFilterFormOverride';
import {RaLabeledOverride} from './RaLabeledOverride';
import {RaListOverride} from './RaListOverride';
import {RaSelectArrayInputOverride} from './RaSelectArrayInputOverride';
import {RaSingleFieldListOverride} from './RaSingleFieldListOverride';
import {typography} from './typography';

export const nirioTheme = {
  palette,
  sidebar: {
    ...defaultTheme.sidebar,
    scrollbar: {
      width: '9px',
      borderRadius: '50px',
    },
  },
  typography: {
    ...defaultTheme.typography,
    ...typography,
  },
  components: {
    ...defaultTheme.components,

    MuiAppBar: MuiAppBarOverride,
    MuiButton: MuiButtonOverride,
    MuiCard: MuiCardOverride,
    MuiDialog: MuiDialogOverride,
    MuiIconButton: MuiIconButtonOverride,
    MuiLink: MuiLinkOverride,
    MuiPaper: MuiPaperOverride,
    MuiTablePagination: MuiTablePagination,
    MuiTextField: MuiTextFieldOverride,

    RaDatagrid: RaDatagridOverride,
    RaFilterForm: RaFilterFormOverride,
    RaList: RaListOverride,
    RaSelectArrayInput: RaSelectArrayInputOverride,
    RaLabeled: RaLabeledOverride,
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    RaSingleFieldList: RaSingleFieldListOverride,
  },
};
