import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {IdentityDocumentTypeInFrench} from '../../../dto/service-requests.dto';

interface ComponentProps {
  personInfo: {
    firstName: string;
    lastName: string;
    birthDate: string;
    birthPlace: string;
    identityDocument?: IdentityDocumentTypeInFrench;
  };
}
const PeopleInformations: React.FC<ComponentProps> = ({
  personInfo,
}): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Prénom</TableCell>
            <TableCell>Nom</TableCell>
            <TableCell>Date de naissance</TableCell>
            <TableCell>Lieu de naissance</TableCell>
            <TableCell>Type de pièce d&apos;identité</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{personInfo.firstName}</TableCell>
            <TableCell>{personInfo.lastName}</TableCell>
            <TableCell>{personInfo.birthDate}</TableCell>
            <TableCell>{personInfo.birthPlace}</TableCell>
            <TableCell>{personInfo.identityDocument}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PeopleInformations;
