import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {AxiosError} from 'axios';
import {useState} from 'react';
import {
  ArrayInput,
  BooleanInput,
  Loading,
  NumberInput,
  SaveButton,
  SimpleFormIterator,
  TextInput,
  Title,
  useTranslate,
} from 'react-admin';
import {useSearchParams} from 'react-router-dom';

import {Form} from '../../Form/Form';
import {JSONViewer} from '../../JSONViewer';
import {neptuneClient} from '../api/neptuneClient';

type OperationSearchForm = {
  rdiRoleCode: string;
  count: number;
  desc: boolean;
  filter: Array<{attribute: string; operation: string; value: string}>;
  offset: number;
  sort: string;
};

export const OperationSearch = (): JSX.Element => {
  const [queryParams] = useSearchParams();

  const translate = useTranslate();

  const defaultValues: OperationSearchForm = {
    rdiRoleCode: queryParams.get('rdiRoleCode') ?? '',
    count: 1,
    desc: true,
    filter: [
      {
        attribute: 'operationType',
        operation: 'eq',
        value: 'ENC',
      },
    ],
    offset: 0,
    sort: 'creationDate',
  };

  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState<unknown>({});

  const handleSubmit = async ({
    rdiRoleCode,
    ...values
  }: OperationSearchForm): Promise<void> => {
    setLoading(true);

    try {
      const client = await neptuneClient(rdiRoleCode);
      const {data} = await client.get<unknown>('/operation/operations/page', {
        params: {
          ...values,
          filter: values.filter.map(obj => Object.values(obj).join(' ')),
        },
      });

      setJson(data);
    } catch (e) {
      setJson((e as AxiosError).response?.data);
    }

    setLoading(false);
  };

  return (
    <Stack spacing={2} sx={{mt: 2, mb: 2}}>
      <Card>
        <Title title="Neptune ⟩ Operation ⟩ Search" />
        <CardContent>
          <Form
            onSubmit={handleSubmit as never}
            defaultValues={defaultValues}
            resetOptions={{keepValues: true}}>
            <Stack>
              <TextInput source="rdiRoleCode" />
              <NumberInput source="count" />
              <BooleanInput source="desc" />
              <ArrayInput source="filter">
                <SimpleFormIterator inline>
                  <TextInput source="attribute" helperText={false} />
                  <TextInput source="operation" helperText={false} />
                  <TextInput source="value" helperText={false} />
                </SimpleFormIterator>
              </ArrayInput>
              <NumberInput source="offset" />
              <TextInput source="sort" />
              <SaveButton
                alwaysEnable
                icon={<SendIcon />}
                label={translate('ra.action.confirm')}
              />
            </Stack>
          </Form>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          {loading ? <Loading /> : <JSONViewer data={json} />}
        </CardContent>
      </Card>
    </Stack>
  );
};
