import {BuildQueryResult, IntrospectionResult} from 'ra-data-graphql';
import buildGraphQLProvider, {buildQuery} from 'ra-data-graphql-simple';

import apolloClient from '../../apolloClient';
import introspection from '../introspection.json';
import {appBasicContentProvider} from './app-basic-content-provider';
import {cartProvider} from './cart-provider';
import {nirioBankDebtProvider} from './nirio-bank-debt-provider';
import {promotionProvider} from './nirio-bank-promotion-provider';
import {partnerProvider} from './partner-provider';
import {payableProvider} from './payable-provider';
import {paymentWorkflowProvider} from './payment-workflow-provider';
import {productProvider} from './product-provider';
import {retailerProvider} from './retailer-provider';
import {settingsProvider} from './settings-provider';

const mapRaResourceToGraphqlResource = (resource: string): string => {
  /**
   * the graphql resource for the path 'Operation' is 'PaymentWorkflow
   * so we create this function to map resources.
   */
  return resource === 'Operation' ? 'PaymentWorkflow' : resource;
};

const myBuildQuery =
  (introspection: IntrospectionResult) =>
  (fetchType: string, resource: string, params: unknown): BuildQueryResult => {
    resource = mapRaResourceToGraphqlResource(resource);
    let builtQuery = buildQuery(introspection)(fetchType, resource, params);

    if (resource === 'PaymentWorkflow') {
      builtQuery = paymentWorkflowProvider(builtQuery, fetchType);
    }

    if (resource === 'Retailer') {
      builtQuery = retailerProvider(builtQuery, fetchType);
    }

    if (resource === 'Payable') {
      builtQuery = payableProvider(builtQuery, fetchType);
    }

    if (resource === 'NirioBankDebt') {
      builtQuery = nirioBankDebtProvider(builtQuery, fetchType);
    }

    if (resource === 'Cart') {
      builtQuery = cartProvider(builtQuery, fetchType);
    }

    if (resource === 'Product') {
      builtQuery = productProvider(builtQuery, fetchType);
    }

    if (resource === 'Partner') {
      builtQuery = partnerProvider(builtQuery, fetchType);
    }

    if (resource === 'AppBasicContent') {
      builtQuery = appBasicContentProvider(builtQuery, fetchType);
    }

    if (resource === 'NirioBankWelcomePromotion') {
      builtQuery = promotionProvider(builtQuery, fetchType);
    }

    if (resource === 'PromotionSettings') {
      builtQuery = settingsProvider(builtQuery, fetchType);
    }

    return builtQuery;
  };

export default buildGraphQLProvider({
  client: apolloClient,
  buildQuery: myBuildQuery,
  introspection: {
    schema: introspection.__schema as never, // Needed to avoid missing property and expected 'OBJECT' but got string
  },
});
