import {FC} from 'react';
import {ArrayField, ArrayFieldProps, Labeled} from 'react-admin';

type LabeledArrayFieldProps = ArrayFieldProps;
export const LabeledArrayField: FC<LabeledArrayFieldProps> = (
  props,
): JSX.Element => {
  return (
    <Labeled>
      <ArrayField {...props} />
    </Labeled>
  );
};
