import {OutlinedInput} from '@mui/material';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import {
  Payable,
  PaymentStatus,
  PermissionDto,
  ProductCategory,
  useGetAllPartnerOptionsQuery,
  useGetAllProductCategoriesQuery,
} from '../../api/generated';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {dateRangeFilters} from '../../components/Form/Filters/dateRangeFilters';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {isUuid} from '../../utils/input.utils';

export const PayableList = (): JSX.Element => {
  const {data: {getAllPartnerOptions: partners} = {}} =
    useGetAllPartnerOptionsQuery();

  const {data: {getAllProductCategories} = {}} =
    useGetAllProductCategoriesQuery();
  const categories: ProductCategory[] | undefined = getAllProductCategories;

  if (!partners || !categories) {
    return <>Chargement ...</>;
  }

  const payableFilters = [
    <TextInput
      key={1}
      source="id"
      validate={isUuid}
      alwaysOn
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <TextInput
      key={2}
      source="partnerPayableId"
      alwaysOn
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <TextInput
      key={3}
      source="partnerCustomerId"
      alwaysOn
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <TextInput
      key={4}
      source="payerId"
      validate={isUuid}
      alwaysOn
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <AutocompleteArrayInput
      alwaysOn
      key={5}
      source="category"
      choices={categories.map(category => ({
        name: category.label,
        id: category.id,
      }))}
    />,
    <AutocompleteArrayInput
      alwaysOn
      key={6}
      source="partner"
      choices={partners.map(partner => ({
        id: partner.id,
        name: partner.label,
      }))}
    />,
    <AutocompleteArrayInput
      alwaysOn
      key={7}
      source="paymentStatus"
      choices={[
        {
          id: PaymentStatus.Paid,
          name: 'Réglée',
        },
        {
          id: PaymentStatus.PartiallyPaid,
          name: 'Partiellement réglée',
        },
        {
          id: PaymentStatus.ToPay,
          name: 'à régler',
        },
      ]}
    />,
    ...dateRangeFilters({from: 'startDate', to: 'endDate'}),
    <FilterSpacer key="spacer" source="spacer" alwaysOn />,
    <FilterResetButton key="reset" source="reset" alwaysOn />,
  ];

  return (
    <PermissionGuard permission={PermissionDto.PayableRead}>
      <List
        actions={<ListTopBar />}
        filters={payableFilters}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid rowClick="show" hover={false} bulkActionButtons={false}>
          <TextField source="id" />

          <FunctionField
            source="partnerPayableId"
            render={(record: Payable): JSX.Element => (
              <OutlinedInput
                value={record.partnerPayableId}
                readOnly
                size="small"
                sx={{fontSize: '12px'}}
                onClick={(e): void => e.stopPropagation()} // prevent row click
              />
            )}
          />
          <TextField source="partnerCustomerId" />
          <DateField source="createdAt" showTime locales="fr-FR" />
          <FunctionField
            source="product.partner.name"
            render={(record: Payable): JSX.Element => (
              <>
                {!!record.product && (
                  <PermissionLink
                    permission={PermissionDto.PartnerRead}
                    to={`/admin/Partner/${record.product.partner.id}/show`}>
                    {record.product.partner.name}
                  </PermissionLink>
                )}
              </>
            )}
          />
          <FunctionField
            source="product.name"
            render={(record: Payable): JSX.Element => (
              <>
                {!!record.product && (
                  <PermissionLink
                    permission={PermissionDto.ProductRead}
                    to={`/admin/Product/${record.productId}/show`}>
                    {record.product.name}
                  </PermissionLink>
                )}
              </>
            )}
          />
          <FunctionField
            source="initialAmountInCents"
            render={(record: Payable): string =>
              formatMaybeAmountInCents(record.initialAmountInCents)
            }
          />

          <FunctionField
            sortable={false}
            source="payedAmount"
            render={(record: Payable): string =>
              formatMaybeAmountInCents(
                record.initialAmountInCents - record.remainingAmountInCents,
              )
            }
          />
          <FunctionField
            sortable={false}
            source="paymentStatus"
            render={(record: Payable): string => {
              if (
                record.remainingAmountInCents === record.initialAmountInCents
              ) {
                return 'à régler';
              } else if (record.remainingAmountInCents === 0) {
                return 'Réglée';
              } else {
                return 'Partiellement réglée';
              }
            }}
          />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
