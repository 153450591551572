import {Buffer} from 'buffer';

export const base64decode = (data: string): string => {
  return Buffer.from(data, 'base64').toString('utf8');
};

export const base64decodeAndParse = <T = unknown>(data: string): T => {
  return JSON.parse(base64decode(data)) as T;
};

export const base64encode = (data: string): string => {
  return Buffer.from(data).toString('base64');
};

export const convertFileToBase64 = (
  rawFile: File,
): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      return resolve(reader.result?.toString());
    };
    reader.onerror = reject;

    reader.readAsDataURL(rawFile);
  });

export const safeConvertFileToBase64 = async (
  file?: {rawFile: File} | null,
): Promise<string | null | undefined> =>
  // convert file to base64 or return value as is (undefined = no change, null = file removed)
  file ? convertFileToBase64(file.rawFile) : file;
