import {createContext, FC} from 'react';

import {FeatureFlags, OnOff, useGetFeatureFlagsQuery} from '../api/generated';

const defaultFeatureFlags: FeatureFlags = {
  neptuneSimulator: OnOff.Off,
  operatorAuthorizationCheck: OnOff.Off,
};

export const FeatureFlagsContext =
  createContext<FeatureFlags>(defaultFeatureFlags);

export interface Props {
  children: React.ReactElement;
}

export const FeatureFlagsProvider: FC<Props> = ({children}: Props) => {
  const {data} = useGetFeatureFlagsQuery();

  return (
    <FeatureFlagsContext.Provider
      value={data?.allFeatureFlags ?? defaultFeatureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
