import {FC} from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Loading,
  ReferenceManyField,
  TextField,
} from 'react-admin';

import {
  CessationRaWorkflow,
  useGetCessationRaReasonsQuery,
} from '../../api/generated';
import {TableSpacer} from '../../components/CustomShow';

export const CessationRaWorkflowTable: FC = () => {
  const {
    data: cessationRaReasons,
    loading: cessationRaReasonsLoading,
    error: cessationRaReasonsError,
  } = useGetCessationRaReasonsQuery();

  if (cessationRaReasonsLoading) {
    return <Loading />;
  }

  if (cessationRaReasonsError || !cessationRaReasons) {
    return <p>Erreur lors de la récupération des raisons de cessation</p>;
  }

  return (
    <TableSpacer>
      <ReferenceManyField reference="CessationRaWorkflow" target="nirioUserId">
        <Datagrid
          sx={{
            boxShadow: 'none',
          }}
          hover={false}
          bulkActionButtons={false}>
          <DateField source="createdAt" />

          <TextField source="source" />

          <TextField source="initiative" />

          <TextField source="iban" />

          <FunctionField
            source="reason"
            render={(record: CessationRaWorkflow): string | null | undefined =>
              cessationRaReasons.getCessationRaReasons.find(
                reason => reason.code === record.reason,
              )?.label ?? record.reason
            }
          />

          <TextField source="reasonComment" />

          <FunctionField
            source="isDowngrade"
            render={(record: CessationRaWorkflow): string =>
              record.isDowngrade ? 'Downgrade à GO' : 'Définitive'
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </TableSpacer>
  );
};
