import {TypedDocumentNode} from '@apollo/client';
import retry from 'async-retry';

import {
  AuthenticationErrors,
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
  RefreshAccessTokenDocument,
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables,
  VerifyTokenDocument,
  VerifyTokenMutation,
  VerifyTokenMutationVariables,
} from './api/generated';
import apolloClient from './apolloClient';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

type Credentials = {accessToken: string; refreshToken: string};

export class TokenStore {
  private fetchingCredentials: Promise<Credentials | undefined> | undefined;

  private storeCredentials(credentials: Credentials): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, credentials.accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, credentials.refreshToken);
  }

  private async waitForNewCredentials(
    credentials: Credentials,
  ): Promise<Credentials | undefined> {
    return retry(
      () => {
        const newCredentials = this.getCredentials();
        if (newCredentials?.refreshToken !== credentials.refreshToken) {
          return newCredentials;
        }
        throw Error();
      },
      {retries: 3},
    ).catch(() => undefined);
  }

  public getCredentials(): Credentials | undefined {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!accessToken || !refreshToken) {
      return undefined;
    }
    return {accessToken, refreshToken};
  }

  private removeCredentials(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  public async loginWithMagicLinkToken(
    token: string,
  ): Promise<Credentials | undefined> {
    if (!this.fetchingCredentials) {
      this.fetchingCredentials = apolloClient
        .mutate<VerifyTokenMutation, VerifyTokenMutationVariables>({
          mutation: VerifyTokenDocument,
          variables: {
            token,
          },
        })
        .then(({data}) => {
          if (
            data?.signinAdminWithMagicLinkToken?.__typename ===
            'SigninAdminWithMagicLinkTokenSuccess'
          ) {
            this.storeCredentials(data.signinAdminWithMagicLinkToken);
            return data.signinAdminWithMagicLinkToken;
          }
          if (
            data?.signinAdminWithMagicLinkToken?.__typename ===
            'SigninAdminWithMagicLinkTokenError'
          ) {
            throw new Error('Sign in admin with magic link token failed', {
              cause: {
                magicLinkTokenCode: data.signinAdminWithMagicLinkToken.code,
              },
            });
          }
          throw new Error('INVALID_TOKEN');
        })
        .finally(() => {
          this.fetchingCredentials = undefined;
        });
    }

    return this.fetchingCredentials;
  }

  public async refreshToken(): Promise<Credentials | undefined> {
    if (!this.fetchingCredentials) {
      const credentials = this.getCredentials();

      if (!credentials) {
        return undefined;
      }

      this.fetchingCredentials = apolloClient
        .mutate<
          RefreshAccessTokenMutation,
          RefreshAccessTokenMutationVariables
        >({
          mutation: RefreshAccessTokenDocument as TypedDocumentNode,
          variables: {
            refreshToken: credentials.refreshToken,
          },
        })
        .then(async ({data}) => {
          // We check credentials in localStorage. If the tokens have been removed, it means
          // the user has logged out in an other tab during the refresh token.
          if (!this.getCredentials()) {
            return undefined;
          }
          if (
            data?.refreshAccessToken.__typename ===
            'RefreshAccessTokenResultSuccess'
          ) {
            this.storeCredentials(data.refreshAccessToken);
            return data.refreshAccessToken;
          }
          if (
            data?.refreshAccessToken.__typename ===
            'RefreshAccessTokenResultError'
          ) {
            // Maybe a BadCredentials error is caused by another tab
            // refreshing the token at the same time. So we must check
            // the storage as new credentials may have been stored or
            // will be stored in few seconds
            if (
              data.refreshAccessToken.code ===
              AuthenticationErrors.BadCredentials
            ) {
              const newCredentials = await this.waitForNewCredentials(
                credentials,
              );
              if (newCredentials) {
                return newCredentials;
              }
            }
            throw new Error(data.refreshAccessToken.code);
          }
          throw new Error('INVALID_TOKEN');
        })
        .catch(e => {
          this.removeCredentials();
          throw e;
        })
        .finally(() => {
          this.fetchingCredentials = undefined;
        });
    }

    return this.fetchingCredentials;
  }

  public async logout(): Promise<void> {
    const credentials = this.getCredentials();

    if (!credentials) {
      return;
    }

    await apolloClient.mutate<LogoutMutation, LogoutMutationVariables>({
      mutation: LogoutDocument as TypedDocumentNode,
      variables: {
        refreshToken: credentials.refreshToken,
      },
    });

    this.removeCredentials();
  }
}

export const tokenStore = new TokenStore();
