import {Form as RAForm, FormProps} from 'react-admin';

/**
 * Override react-admin form and force noValidate to
 * enforces validation consistency by removing the HTML validation popups
 *
 * @example <caption>Do not use html validation anymore:</caption>
 * <TextInput required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
 *
 * @example <caption>Instead use validate:</caption>
 * <TextInput validate={[required(), email()]} />
 *
 * @see https://marmelab.com/react-admin/Validation.html
 */
export const Form = (props: FormProps): JSX.Element => {
  return <RAForm {...props} noValidate />;
};
