import {DateFilter} from './DateFilter';

interface Options {
  from?: string | {source: string; label: string};
  to?: string | {source: string; label: string};
}
export const dateRangeFilters = ({
  from = 'createdAtGte',
  to = 'createdAtLte',
}: Options = {}): JSX.Element[] => {
  const fromSource = typeof from === 'string' ? from : from.source;
  const toSource = typeof to === 'string' ? to : to.source;
  return [
    <DateFilter
      key="dateRangeFrom"
      label={typeof from === 'object' ? from.label : undefined}
      source={fromSource}
      alwaysOn
      fields={{
        startDate: fromSource,
        endDate: toSource,
        currentField: fromSource,
      }}
    />,

    <DateFilter
      key="dateRangeTo"
      label={typeof to === 'object' ? to.label : undefined}
      source={toSource}
      alwaysOn
      fields={{
        startDate: fromSource,
        endDate: toSource,
        currentField: toSource,
      }}
    />,
  ];
};
