import axios, {AxiosInstance} from 'axios';

let jwtToken: string;

export const neptuneClient = async (
  rdiRoleCode: string,
  operatorId = 'operator-id', // we do not need operatorId for some endpoints, but it is required in the Neptune JWT Token, so we explicitly set it to a default value
): Promise<AxiosInstance> => {
  jwtToken = (
    await axios.get<string>('/neptuneToken/sign', {
      baseURL: process.env.REACT_APP_TEST_BASE_URL,
      params: {
        rdiRoleCode,
        operatorId,
      },
    })
  ).data;

  return axios.create({
    baseURL: process.env.REACT_APP_TERMINAL_BASE_URL,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      // Neptune expects Date, but Date cannot be set by a web browser
      // ex : "Wed Oct 12 2022 16:10:53 GMT+0200"
      'X-Date': new Date().toString().split('(')[0].trim(),
      'FDJ-Correlation-id': 'NEP-010.253.142.232-07/07/22 19:07:35,817-041',
      'FDJ-RSN': 'NEP-010.253.142.232-07/07/22 19:07:35,817-041',
    },
  });
};
