import Button, {ButtonProps} from '@mui/material/Button';

interface Props extends ButtonProps {
  text: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const SecondaryButton: React.FC<Props> = ({
  text,
  onClick,
  style,
  ...props
}): JSX.Element => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      style={{marginRight: '0.5rem', ...style}}
      {...props}>
      {text}
    </Button>
  );
};
