import {styled} from '@mui/material';
import {FC} from 'react';
import {
  EditButton,
  FunctionField,
  RecordContextProvider,
  RichTextField,
  useTranslate,
} from 'react-admin';

import {
  MaintenanceSettingsDto,
  PermissionDto,
  useGetMaintenanceSettingsQuery,
} from '../../api/generated';
import {CustomView} from '../../components/CustomShow';
import {PermissionGuard} from '../../components/PermissionGuard';
import {maintenanceSettingsEditPath} from './constants';

const GridContainer = styled('div')({
  display: 'grid',
  width: '100%',
  gap: '24px',
  gridTemplateColumns: '1fr 1fr',
});

const topPapers = [
  {
    title: 'Go',
    content: [
      <FunctionField
        key="go"
        source="go"
        render={({go}: MaintenanceSettingsDto): string =>
          go ? 'Maintenance en cours' : 'Aucune maintenance en cours'
        }
      />,
    ],
  },
  {
    title: 'Premio',
    content: [
      <FunctionField
        key="premio"
        source="premio"
        render={({premio}: MaintenanceSettingsDto): string =>
          premio ? 'Maintenance en cours' : 'Aucune maintenance en cours'
        }
      />,
    ],
  },
  {
    title: "Notice affichée dans l'application",
    content: <RichTextField source="message" emptyText="Message non défini" />,
    style: {gridColumn: 'span 2'},
  },
];

const UnguardedMaintenanceShow: FC = () => {
  const {data} = useGetMaintenanceSettingsQuery();
  const translate = useTranslate();

  const title = translate(`resources.MaintenanceSettings.name`);

  return (
    <RecordContextProvider value={data?.getMaintenanceSettings}>
      <CustomView
        title={title}
        topPapers={topPapers}
        topPapersContainer={GridContainer}
        actions={[
          <EditButton
            key="edit-maintenance-button"
            to={maintenanceSettingsEditPath}
          />,
        ]}
      />
    </RecordContextProvider>
  );
};

export const MaintenanceShow: FC = () => (
  <PermissionGuard permission={PermissionDto.MaintenanceRead}>
    <UnguardedMaintenanceShow />
  </PermissionGuard>
);
