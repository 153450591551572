import {styled} from '@mui/material';

import {Form} from './Form';

// Remove the margin between the inputs when the form is used
export const StyledForm = styled(Form)({
  '& .MuiFormControl-root': {
    margin: '0',
  },
});
