import {
  ArrayField,
  ArrayFieldProps,
  SingleFieldList,
  SingleFieldListProps,
  useRecordContext,
} from 'react-admin';

export const SingleFieldListWithEmptyText = (
  props: ArrayFieldProps & {
    source: string;
    children: SingleFieldListProps['children'];
  },
): JSX.Element => {
  const record = useRecordContext();

  const value = Array.isArray(record[props.source])
    ? (record[props.source] as unknown[])
    : undefined;

  return (
    <ArrayField {...props}>
      {value?.length ? (
        <SingleFieldList linkType={false}>{props.children}</SingleFieldList>
      ) : (
        <span>{props.emptyText}</span>
      )}
    </ArrayField>
  );
};
