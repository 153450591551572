import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, TextField} from '@mui/material';
import {useEffect} from 'react';
import {TextInput, useCreateContext, useRecordContext} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {Product} from '../../api/generated';
import {useFormValues} from '../../hooks/useFormValues';

export function ProductCredentialsInput(): JSX.Element {
  const record = useRecordContext<Product>();
  const createContext = useCreateContext(); // To check if we're in an create or edit context.
  const {setValue} = useFormContext();

  // Password cannot be fetched by BO, so passwordModified keeps track of wherever or not password has been touched.
  // If false, keep old value. If true, replace by new password.
  const {passwordModified, credentialsUsername, credentialsPassword} =
    useFormValues<{
      passwordModified?: boolean;
      credentialsUsername?: string;
      credentialsPassword?: string;
    }>();

  const isInCreateContext = createContext.resource !== null;
  // By default, set password modifications when creating a new product or when there is no credentials yet
  useEffect(() => {
    setValue(
      'passwordModified',
      isInCreateContext || !record?.hasEncryptedCredentials,
    );
  }, [setValue, isInCreateContext, record?.hasEncryptedCredentials]);

  const usernameValidator = (): string | undefined => {
    if (credentialsUsername && credentialsUsername.includes(':')) {
      return "L'identifiant ne peut pas avoir de ':'";
    }

    return ((passwordModified && credentialsPassword) ||
      (!passwordModified && record?.hasEncryptedCredentials)) &&
      !credentialsUsername
      ? 'Il faut spécifier un identifiant avec le mot de passe. Pour enlever les identifiants, laissez les 2 champs vides.'
      : undefined;
  };
  const passwordValidator = (): string | undefined =>
    passwordModified && credentialsUsername && !credentialsPassword
      ? "Il faut spécifier un mot de passe avec l'identifiant. Pour enlever les identifiants, laissez les 2 champs vides."
      : undefined;

  return (
    <>
      <TextInput
        source="credentialsUsername"
        parse={(value: string): string | null => (value === '' ? null : value)}
        defaultValue={null}
        validate={usernameValidator}
      />
      {passwordModified ? (
        <TextInput
          label="Mot de passe fourni par le partenaire"
          autoComplete="off"
          source="credentialsPassword"
          parse={(value: string): string | null =>
            value === '' ? null : value
          }
          defaultValue={null}
          validate={passwordValidator}
        />
      ) : (
        <TextField
          label="Mot de passe fourni par le partenaire"
          variant="outlined"
          type="password"
          defaultValue={
            record?.hasEncryptedCredentials ? '***************' : null
          }
          disabled
        />
      )}

      {!passwordModified && (
        <Button
          variant="contained"
          sx={{alignSelf: 'center', fontSize: '1rem'}}
          size="small"
          startIcon={<EditIcon />}
          onClick={(): void => setValue('passwordModified', true)}>
          Modifier le mot de passe
        </Button>
      )}
      {passwordModified && record?.hasEncryptedCredentials && (
        <Button
          variant="contained"
          sx={{alignSelf: 'center', fontSize: '1rem'}}
          size="small"
          startIcon={<UndoIcon />}
          onClick={(): void => setValue('passwordModified', false)}>
          Annuler la modification
        </Button>
      )}
    </>
  );
}
