import {Card, CardContent, CardHeader, Grid} from '@mui/material';
import {
  email,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';

import {
  Admin,
  GroupDto,
  useGetAdminByEmailLazyQuery,
} from '../../api/generated';
import {isNotOnlySpaces, uniqueValidator} from '../../utils/input.utils';

export const AdminForm = (): JSX.Element => {
  const [getAdminByEmail] = useGetAdminByEmailLazyQuery();
  const record = useRecordContext<Admin>(); // Can be undefined in Create, careful with wrong typing

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader title="Compte" />
          <CardContent>
            <TextInput
              source="firstName"
              validate={[required(), isNotOnlySpaces()]}
              fullWidth
            />
            <TextInput
              source="lastName"
              validate={[required(), isNotOnlySpaces()]}
              fullWidth
            />
            <TextInput
              source="email"
              type="email"
              validate={[
                required(),
                email(),
                uniqueValidator(
                  (email: string) =>
                    getAdminByEmail({variables: {email}}).then(
                      ({data}) => data?.getAdminByEmail,
                    ),
                  record?.email,
                  'Cet email est déjà utilisé',
                ),
              ]}
              fullWidth
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader title="Paramètres" />
          <CardContent>
            <SelectInput
              source="group"
              choices={Object.values(GroupDto as Record<string, string>).map(
                value => ({
                  id: value,
                  name: value.replaceAll('_', ' '),
                }),
              )}
              validate={[required()]}
              fullWidth
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
