import {Typography} from '@mui/material';
import React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  Link,
  RaRecord,
  TextField,
  useShowController,
} from 'react-admin';

import {Cart, CartItem, PermissionDto} from '../../api/generated';
import {CustomShow} from '../../components/CustomShow';
import {PaidAmountTooltip} from '../../components/PaidAmountTooltip';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';

const data = [
  {
    title: 'Authentification',
    fields: [
      <TextField
        source="id"
        key="id"
        sx={{
          fontSize: '14px',
        }}
      />,
      <DateField
        locales="fr-FR"
        showTime={true}
        source="createdAt"
        key="createdAt"
        sx={{
          fontSize: '14px',
        }}
      />,
      <DateField
        locales="fr-FR"
        showTime={true}
        source="updatedAt"
        key="updatedAt"
        sx={{
          fontSize: '14px',
        }}
      />,
      <FunctionField
        sx={{
          fontSize: '14px',
        }}
        label="Statut"
        key="paidStatusLabel"
        render={(record: Cart): string => (record.paidAt ? 'Réglé' : 'Ouvert')}
      />,
      <FunctionField
        sx={{
          fontSize: '14px',
        }}
        key="amountinCents"
        label={<PaidAmountTooltip text="Montant (en €)" />}
        source="item.amountInCents"
        render={(record: Cart): string => {
          const sum = record.items?.reduce(
            (acc, cartItem) =>
              acc + cartItem.amountInCents + cartItem.serviceFeeInCents,
            0,
          );
          return formatMaybeAmountInCents(sum);
        }}
      />,
    ],
  },
  {
    title: 'Client et opérations',
    fields: [
      <FunctionField
        sx={{
          fontSize: '14px',
        }}
        key="payerId"
        source="payerId"
        render={(record: Cart): JSX.Element => (
          <PermissionLink
            permission={PermissionDto.PayerRead}
            to={`/admin/Client/${record.client.id}/show`}>
            {record.payerId}
          </PermissionLink>
        )}
      />,
      <FunctionField
        sx={{
          fontSize: '14px',
        }}
        key="operations"
        source="operation.id"
        render={(record: Cart): JSX.Element => {
          return (
            <>
              {record.paymentWorkflows?.map((payment, i) => {
                return (
                  <Link
                    key={`Payment ${i}`}
                    to={`/admin/Operation/${payment.id}/show`}>
                    <Typography>{payment.id}</Typography>
                  </Link>
                );
              })}
            </>
          );
        }}
      />,
    ],
  },
];

const itemsGrid = (
  <ArrayField source="items">
    <Datagrid
      sx={{
        boxShadow: 'none',
      }}
      bulkActionButtons={false}>
      <FunctionField
        source="item.payable.id"
        render={(record: CartItem): JSX.Element => (
          <PermissionLink
            permission={PermissionDto.PayableRead}
            to={`/admin/Payable/${record.payable.id}/show`}>
            {record.payable.id}
          </PermissionLink>
        )}
      />
      <FunctionField
        source="item.payable.initialAmountInCents"
        render={(record: CartItem): string => {
          return formatMaybeAmountInCents(record.payable.initialAmountInCents);
        }}
      />
      <FunctionField
        source="item.payable.remainingAmountInCents"
        render={(record: CartItem): string => {
          return formatMaybeAmountInCents(
            record.payable.remainingAmountInCents,
          );
        }}
      />
      <FunctionField
        source="item.payable.product.name"
        render={(record: CartItem): JSX.Element => (
          <>
            {!!record.payable.product && (
              <PermissionLink
                permission={PermissionDto.ProductRead}
                to={`/admin/Product/${record.payable.product.id}/show`}>
                {record.payable.product.name}
              </PermissionLink>
            )}
          </>
        )}
      />
      <FunctionField
        source="item.payable.product.partner.name"
        render={(record: CartItem): JSX.Element => (
          <>
            {!!record.payable.product && (
              <PermissionLink
                permission={PermissionDto.PartnerRead}
                to={`/admin/Partner/${record.payable.product.partner.id}/show`}>
                {record.payable.product.partner.name}
              </PermissionLink>
            )}
          </>
        )}
      />
      <TextField
        label="Catégorie produit"
        source="payable.product.category.label"
      />
    </Datagrid>
  </ArrayField>
);

export const CartShow: React.FC = () => {
  const showController = useShowController<RaRecord>();

  const title = showController.defaultTitle;

  return (
    <PermissionGuard permission={PermissionDto.CartRead}>
      <CustomShow
        title={title}
        topPapers={data.map(item => ({
          title: item.title,
          content: item.fields.map((field, index) => (
            <Labeled key={index}>{field}</Labeled>
          )),
        }))}
        mainPaperTitle="Factures"
        mainPaper={[
          {
            content: itemsGrid,
          },
        ]}
      />
    </PermissionGuard>
  );
};
