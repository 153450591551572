import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import {
  NirioBankWelcomePromotion,
  NirioBankWelcomePromotionType,
  PermissionDto,
} from '../../../api/generated';
import {FilterResetButton} from '../../../components/FilterResetButton';
import {FilterSpacer} from '../../../components/FilterSpacer';
import {Icons} from '../../../components/Icon';
import {ListTopBar} from '../../../components/ListTopBar';
import {PermissionGuard} from '../../../components/PermissionGuard';
import {RenderConditionalLabel} from '../../../components/RenderLabel';
import {usePermissions} from '../../../hooks/usePermissions';

export const WelcomePromotionList = (): JSX.Element => {
  const {can} = usePermissions();

  const actions = [];

  if (can(PermissionDto.PromotionWrite)) {
    actions.push(
      <CreateButton
        variant="contained"
        key="create"
        label="Nouvelle promotion"
        icon={<Icons.Add isActive size={17} />}
      />,
    );
  }

  const filters = [
    <TextInput
      key="nameFilter"
      source="nameFilter"
      label="Rechercher par nom"
      resettable
      alwaysOn
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <FilterSpacer key="spacer" source="spacer" alwaysOn />,
    <FilterResetButton key="reset" source="reset" alwaysOn />,
  ];

  return (
    <PermissionGuard permission={PermissionDto.PromotionRead}>
      <List
        actions={<ListTopBar actions={actions} backTo="/admin/AllPromotions" />}
        filters={filters}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid hover={false} rowClick="show" bulkActionButtons={false}>
          <TextField source="name" />
          <DateField
            source="dateStart"
            showTime
            locales="fr-FR"
            emptyText="Ø"
          />
          <DateField source="dateEnd" showTime locales="fr-FR" emptyText="Ø" />
          <FunctionField
            source="type"
            render={(record: NirioBankWelcomePromotion): string =>
              record.type === NirioBankWelcomePromotionType.Monetary
                ? "Versement d'argent"
                : 'Mois offert'
            }
          />
          <FunctionField
            source="isActive"
            render={(record: NirioBankWelcomePromotion): JSX.Element => (
              <RenderConditionalLabel condition={record.isActive} />
            )}
          />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
