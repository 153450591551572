import CircleIcon from '@mui/icons-material/Circle';
import {GetColor} from '@tafalk/material-color-generator';
import {useCallback} from 'react';
import {ChipField, ChipFieldProps, useRecordContext} from 'react-admin';

export const ColoredChipField = (props: ChipFieldProps): JSX.Element => {
  const record = useRecordContext<{[key: string]: string}>(props);

  const getColor = useCallback(
    (str?: string) => (str ? `#${GetColor(str, 'dark')}` : 'black'),
    [],
  );

  const value = props.source ? record?.[props.source] : undefined;

  return (
    <ChipField
      sx={{
        '& .MuiChip-icon': {
          color: getColor(value),
          height: 12,
          width: 12,
        },
      }}
      icon={<CircleIcon />}
      {...props}
    />
  );
};
