import MuiAlert, {AlertProps} from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

export interface AlertInformations {
  title: string;
  message: string;
  details?: string;
}

export interface Props extends AlertProps {
  /**
   * @default 'filled'
   */
  variant?: AlertProps['variant'];
  informations: AlertInformations;
}
export function Alert({
  variant = 'filled',
  informations,
  sx = {mb: 2},
  ...rest
}: Props): JSX.Element {
  return (
    <Box sx={{width: '100%', zIndex: 1000}}>
      <Collapse in>
        <MuiAlert sx={sx} variant={variant} {...rest}>
          <AlertTitle>{informations.title}</AlertTitle>
          {informations.message}
          {informations.details ? (
            <>
              <br />
              {informations.details}
            </>
          ) : null}
        </MuiAlert>
      </Collapse>
    </Box>
  );
}
