import {ButtonBase} from '@mui/material';
import {styled} from '@mui/material/styles';
import {FC} from 'react';
import {useListController} from 'react-admin';

import {filtersHelperTextHeight} from '../constants';
import {palette} from '../theme/palette';

const StyledButtonBase = styled(ButtonBase)({
  padding: '12px 16px',
  borderRadius: '8px',
  width: '113px',
  height: '38px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 700,
  boxShadow: 'none',
});

export interface FilterResetButtonProps {
  alwaysOn?: boolean;
  // The List component create a wrapper over a filter and feeds that wrapper a `key` with value {source}
  // therefore if we use this component without a source it will have no key and raise an error
  source?: string;
}

export const FilterResetButton: FC<FilterResetButtonProps> = () => {
  const listController = useListController();
  const filterValues = listController.filterValues as {[key: string]: string};

  const areFiltersSet = Object.keys(filterValues).some(
    key => filterValues[key] !== undefined,
  );

  const handleReset = (): void => {
    if (filterValues) {
      Object.keys(filterValues).forEach(key => {
        listController.setFilters({[key]: undefined}, {});
      });
    }
  };

  return (
    <StyledButtonBase
      disabled={!areFiltersSet}
      style={{
        backgroundColor: areFiltersSet ? '#E5F8FF' : palette.grey.light,
        color: areFiltersSet ? '#2222B3' : palette.grey[500],
        marginBottom: filtersHelperTextHeight,
      }}
      onClick={handleReset}>
      Réinitialiser
    </StyledButtonBase>
  );
};
