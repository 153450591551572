import {FormHelperText, styled, Typography} from '@mui/material';
import {ReactNode, useState} from 'react';
import {ImageField, ImageInputProps} from 'react-admin';
import {DropEvent, FileRejection} from 'react-dropzone';
import {useFormContext} from 'react-hook-form';

import TrashSVG from '../../../../assets/icons/trash.svg';
import {Icons} from '../../../Icon';
import {CustomImageInput} from './CustomImageInput';

const PlaceHolderInnerContent = styled('div')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
});

/**
 * Replaces the default remove button by a trash icon
 */

function replaceRemoveButton(): void {
  const removeButtonIcon = document.querySelector(
    'svg.RaFileInputPreview-removeIcon',
  );
  if (removeButtonIcon) {
    const removeButton = removeButtonIcon.parentElement;
    if (removeButton) {
      removeButtonIcon.remove();
      const trashIconDomElement = document.createElement('img');
      trashIconDomElement.src = TrashSVG;
      trashIconDomElement.className = 'RaFileInputPreview-removeIcon';
      removeButton.appendChild(trashIconDomElement);
    }
  }
}

/**
 *
 * The image input component requires an object. We must therefore properly format our data before passing it to the component.
 */
const formatUploadedImage = (
  src: string | {src: string; rawFile: File},
): {src: string} => {
  if (typeof src === 'string' || src === null) {
    return {
      src,
    };
  }
  return src;
};

interface LogoUploadPlaceHolderProps {
  isUploading: boolean;
  caption: ReactNode;
}

const LogoUploadPlaceHolder: React.FC<LogoUploadPlaceHolderProps> = ({
  isUploading,
  caption,
}) => {
  if (isUploading) {
    return <Icons.Upload />;
  }
  return (
    <PlaceHolderInnerContent>
      <Icons.UploadLarge size={32} />
      <div
        style={{
          textAlign: 'left',
        }}>
        <Typography
          variant="body2"
          sx={{
            marginBottom: '4px',
          }}>
          Déposer l&apos;image à téléverser ou cliquer pour la sélectionner
        </Typography>
        {caption}
      </div>
    </PlaceHolderInnerContent>
  );
};

interface ImageUploaderProps
  extends Pick<ImageInputProps, 'maxSize' | 'accept' | 'source'> {
  caption: ReactNode;
  parseFileRejectionsOnDropRejected?: (
    fileRejections: FileRejection[],
  ) => string;
}

export const ImageUploader = ({
  source,
  caption,
  accept,
  maxSize, // bytes
  parseFileRejectionsOnDropRejected,
}: ImageUploaderProps): JSX.Element => {
  const {watch} = useFormContext();
  const [error, setError] = useState<string | undefined>();
  const logo: unknown = watch(source);
  const isUploading = Boolean(logo);
  replaceRemoveButton();
  return (
    <>
      <CustomImageInput
        isUploading={isUploading ? 1 : undefined}
        label=""
        maxSize={maxSize}
        source={source}
        accept={accept}
        placeholder={
          <LogoUploadPlaceHolder isUploading={isUploading} caption={caption} />
        }
        options={{
          onDropAccepted: () => setError(undefined),
          onDropRejected:
            parseFileRejectionsOnDropRejected &&
            ((fileRejections: FileRejection[], _event: DropEvent): void => {
              setError(parseFileRejectionsOnDropRejected(fileRejections));
            }),
        }}
        format={formatUploadedImage}>
        <ImageField
          sx={{
            '& .RaImageField-image': {
              width: '200px',
              height: '90px',
              margin: 0,
              paddingTop: '0.20rem',
              objectPosition: 'left',
              borderRadius: '8px',
            },
          }}
          source="src"
        />
      </CustomImageInput>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};
