import {Link} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {AxiosError} from 'axios';
import {useContext} from 'react';

import {OnOff} from '../../../../api/generated';
import {useFeatureFlags} from '../../../../hooks/useFeatureFlags';
import {makeRequestWithDataMatrixContent} from '../../api/makeRequestWithDataMatrixContent';
import {SimulationContext} from '../../context/context';
import {ActionTypes} from '../../context/reducer.types';

const QR_CODE_SERVICE =
  'https://barcode.tec-it.com/barcode.ashx?data={{data}}&code=MobileQRCode&dpi=250&eclevel=L';

const ManualMode: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const {state, dispatch} = useContext(SimulationContext);
  const qrCodeUrl = QR_CODE_SERVICE.replace('{{data}}', state.code);
  return (
    <Card variant="outlined" sx={{width: '50%'}}>
      <CardContent>
        <Stack spacing={2}>
          <TextField
            label="Contenu du QR Code"
            variant="outlined"
            defaultValue={state.code}
            onChange={(event): void =>
              dispatch({
                type: ActionTypes.SetCode,
                payload: {
                  code: event.target.value,
                },
              })
            }
          />
          <Button
            variant="contained"
            style={{width: '100%'}}
            onClick={(): void => {
              if (state.retailerRoleCode === '') {
                dispatch({
                  type: ActionTypes.SetError,
                  payload: {
                    error: new Error(
                      "Le code du détaillant n'est pas renseigné",
                    ),
                  },
                });
                return;
              }
              if (
                featureFlags.operatorAuthorizationCheck === OnOff.On &&
                !state.operatorId
              ) {
                dispatch({
                  type: ActionTypes.SetError,
                  payload: {
                    error: new Error(
                      "Le code de l'opérateur n'est pas renseigné",
                    ),
                  },
                });
                return;
              }
              makeRequestWithDataMatrixContent(
                state.retailerRoleCode,
                state.operatorId,
                state.code,
                dispatch,
              ).catch((err: Error | AxiosError): void => {
                dispatch({
                  type: ActionTypes.SetError,
                  payload: {error: err},
                });
              });
            }}>
            Envoyer
          </Button>
          <Link href={qrCodeUrl}>
            <Button variant="outlined" style={{width: '100%'}}>
              Voir le QR Code
            </Button>
          </Link>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default ManualMode;
