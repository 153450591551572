import {AuthProvider} from 'react-admin';

import apolloClient from '../../apolloClient';
import {tokenStore} from '../../token-store';
import {
  GetMyPermissionsDocument,
  GetMyPermissionsQuery,
  GetMyPermissionsQueryVariables,
  PermissionDto,
} from '../generated';

const authProvider: AuthProvider = {
  login: async (token: string): Promise<void> => {
    await tokenStore.loginWithMagicLinkToken(token);
    return Promise.resolve();
  },
  checkError: (): Promise<void> => Promise.resolve(),
  logout: async (): Promise<void> => {
    await tokenStore.logout();
  },
  getIdentity: (): Promise<{id: number}> => Promise.resolve({id: 1}),
  checkAuth: async (): Promise<void> =>
    tokenStore.getCredentials() ? Promise.resolve() : Promise.reject(),
  getPermissions: async (): Promise<PermissionDto[]> => {
    if (!tokenStore.getCredentials()) {
      return [];
    }

    const {
      data: {
        me: {permissions},
      },
    } = await apolloClient.query<
      GetMyPermissionsQuery,
      GetMyPermissionsQueryVariables
    >({
      query: GetMyPermissionsDocument,
    });
    return permissions;
  },
};

export default authProvider;
