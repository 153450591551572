import {useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import {FC} from 'react';

export interface FilterCardContainerProps {
  children: JSX.Element;
}

export const FilterCardContainer: FC<FilterCardContainerProps> = ({
  children,
}) => {
  const theme = useTheme();

  return (
    <Stack
      className="filter-card-container"
      direction="row"
      gap={theme.spacing(3)}>
      {children}
    </Stack>
  );
};
