import {Fragment} from 'react';
import {ResourceProps} from 'react-admin';

import {Admin as AdminRecord, PermissionDto} from '../../api/generated';
import {AdminCreate} from '../../ressources/admin/AdminCreate';
import {AdminEdit} from '../../ressources/admin/AdminEdit';
import {AdminList} from '../../ressources/admin/AdminList';
import {AdminShow} from '../../ressources/admin/AdminShow';
import {
  AppBasicContentEdit,
  AppBasicContentList,
  CarrouselImageCreate,
  CarrouselImageList,
} from '../../ressources/app-basic-content';
import {CartList} from '../../ressources/cart/CartList';
import {CartShow} from '../../ressources/cart/CartShow';
import {CessationRaWorkflowCreate} from '../../ressources/cessationRaWorkflow/CessationRaWorkflowCreate';
import {ClientList} from '../../ressources/client/ClientList';
import {ClientShow} from '../../ressources/client/ClientShow';
import {
  CustomerServiceInfoEdit,
  CustomerServiceInfoList,
} from '../../ressources/customerServiceInfo/CustomerServiceInfoList';
import {maintenanceSettingsResourceName} from '../../ressources/maintenance/constants';
import {MaintenanceShow} from '../../ressources/maintenance/MaintenanceShow';
import {OperationList} from '../../ressources/operation/OperationList';
import {OperationShow} from '../../ressources/operation/OperationShow';
import {PartnerCreate} from '../../ressources/partner/PartnerCreate';
import {PartnerEdit} from '../../ressources/partner/PartnerEdit';
import {PartnerList} from '../../ressources/partner/PartnerList';
import {PartnerShow} from '../../ressources/partner/PartnerShow';
import {PayableList} from '../../ressources/payable/PayableList';
import {PayableShow} from '../../ressources/payable/PayableShow';
import {ProductCreate} from '../../ressources/product/ProductCreate';
import {ProductEdit} from '../../ressources/product/ProductEdit';
import {ProductList} from '../../ressources/product/ProductList';
import {ProductShow} from '../../ressources/product/ProductShow';
import {SettingsEdit} from '../../ressources/promotion/Settings/SettingsEdit';
import {SettingsShow} from '../../ressources/promotion/Settings/SettingsShow';
import {WelcomePromotionCreate} from '../../ressources/promotion/WelcomePromotion/WelcomePromotionCreate';
import {WelcomePromotionEdit} from '../../ressources/promotion/WelcomePromotion/WelcomePromotionEdit';
import {WelcomePromotionList} from '../../ressources/promotion/WelcomePromotion/WelcomePromotionList';
import {WelcomePromotionShow} from '../../ressources/promotion/WelcomePromotion/WelcomePromotionShow';
import {RetailerList} from '../../ressources/retailer/RetailerList';
import {RetailerShow} from '../../ressources/retailer/RetailerShow';
import {Icons} from '../Icon';

export const resources: ResourceProps[] = [
  {
    name: 'Retailer',
    list: RetailerList,
    show: RetailerShow,
    icon: Icons.Retailer,
    options: {
      label: 'Détaillants',
      permission: PermissionDto.RetailerRead,
    },
  },
  {
    name: 'Admin',
    list: AdminList,
    edit: AdminEdit,
    create: AdminCreate,
    show: AdminShow,
    icon: Icons.Account,
    recordRepresentation: (record: AdminRecord): string =>
      `${record.firstName} ${record.lastName}`,
    options: {
      label: 'Administrateurs',
      permission: PermissionDto.AdminRead,
    },
  },
  {
    name: 'Client',
    list: ClientList,
    icon: Icons.Euro,
    show: ClientShow,
    recordRepresentation: 'email',
    options: {
      label: 'Clients',
      permission: PermissionDto.PayerRead,
    },
  },
  {
    name: 'Product',
    list: ProductList,
    edit: ProductEdit,
    create: ProductCreate,
    show: ProductShow,
    icon: Icons.Wallet,
    recordRepresentation: 'name',
    options: {
      label: 'Produits',
      permission: PermissionDto.ProductRead,
    },
  },
  {
    name: 'Partner',
    list: PartnerList,
    edit: PartnerEdit,
    create: PartnerCreate,
    icon: Icons.Confidance,
    show: PartnerShow,
    recordRepresentation: 'name',
    options: {
      label: 'Partenaires',
      permission: PermissionDto.PartnerRead,
    },
  },
  {
    name: 'Payable',
    list: PayableList,
    show: PayableShow,
    icon: Icons.Sheet,
    options: {
      label: 'Factures',
      permission: PermissionDto.PayableRead,
    },
  },
  {
    name: 'Cart',
    list: CartList,
    show: CartShow,
    icon: Icons.Cart,
    options: {
      label: 'Paniers',
      permission: PermissionDto.CartRead,
    },
  },
  {
    name: 'Operation',
    list: OperationList,
    show: OperationShow,
    icon: Icons.Operation,
    options: {
      label: 'Opérations',
      permission: PermissionDto.PaymentWorkflowRead,
    },
  },
  {
    name: 'CustomerServiceInfo',
    list: CustomerServiceInfoList,
    edit: CustomerServiceInfoEdit,
    icon: Icons.Search,
    options: {
      label: 'Informations Service Client',
      permission: PermissionDto.CustomerServiceRead,
    },
  },
  {
    name: 'AppBasicContent',
    list: AppBasicContentList,
    edit: AppBasicContentEdit,
    icon: Icons.Agenda,
    options: {
      label: 'Contenu statique',
      permission: PermissionDto.CarrouselImageRead,
    },
  },
  {
    name: 'CarrouselImage',
    list: CarrouselImageList,
    create: CarrouselImageCreate,
    options: {
      permission: PermissionDto.CarrouselImageRead,
    },
  },
  {
    name: 'AppFaqContent',
    list: Fragment,
    icon: Icons.Help,
    options: {
      label: 'FAQ',
      permission: PermissionDto.AppContentWrite,
    },
  },
  {
    name: 'NirioBankWelcomePromotion',
    list: WelcomePromotionList,
    edit: WelcomePromotionEdit,
    create: WelcomePromotionCreate,
    show: WelcomePromotionShow,
    icon: Icons.Promotion,
    recordRepresentation: 'name',
    options: {
      permission: PermissionDto.PromotionRead,
    },
  },
  {
    name: 'PromotionSettings',
    icon: Icons.Promotion,
    hasCreate: false,
    show: SettingsShow,
    edit: SettingsEdit,
    recordRepresentation: 'name',
    options: {
      permission: PermissionDto.PromotionRead,
    },
  },
  {
    name: 'AllPromotions',
    icon: Icons.Promotion,
    list: Fragment,
    options: {
      label: 'Promotions',
      permission: PermissionDto.PromotionRead,
    },
  },
  {
    name: maintenanceSettingsResourceName,
    icon: Icons.Agenda,
    list: MaintenanceShow,
    options: {
      label: 'Paramètres de maintenance',
      permission: PermissionDto.MaintenanceRead,
    },
  },
  {
    name: 'CessationRaWorkflow',
    create: CessationRaWorkflowCreate,
  },
];
