import {Paper, styled, Typography} from '@mui/material';
import {
  maxLength,
  minLength,
  NumberInput,
  regex,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';

import {
  Partner,
  useGetPartnerByCodeDetailLbLazyQuery,
  useGetPartnerByDatamatrixPartnerIdLazyQuery,
  useGetPartnerByNameLazyQuery,
} from '../../api/generated';
import {CreateEditTopBar} from '../../components/CreateEditTopBar';
import {StyledSwitch} from '../../components/Form/Inputs/StyledSwitch';
import {PageLayout} from '../../components/PageLayout';
import {alphanumericValidator, uniqueValidator} from '../../utils/input.utils';
import {StyledStack} from './helpers';
import {LogoUploadInput} from './LogoUploadInput';
import {PartnerAuthInputs} from './PartnerAuthInputs';

const FormContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  width: '100%',
  gap: '24px',
  marginBottom: '46px',
});

const CardTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledPaper = styled(Paper)({
  backgroundColor: 'white',
  padding: '32px',
  flex: '1',
});

const PaperTitle = styled(Typography)({
  marginBottom: '24px',
});

interface PartnerFormProps {
  title?: string;
  NavigationButton?: JSX.Element;
}

export const PartnerForm: React.FC<PartnerFormProps> = ({
  title,
  NavigationButton,
}) => {
  const [getPartnerByCodeDetailLB] = useGetPartnerByCodeDetailLbLazyQuery();
  const [getPartnerByDatamatrixPartnerId] =
    useGetPartnerByDatamatrixPartnerIdLazyQuery();
  const [getPartnerByName] = useGetPartnerByNameLazyQuery();
  const record = useRecordContext<Partner>(); // Can be undefined in Create, careful with wrong typing
  return (
    <PageLayout>
      <CreateEditTopBar
        title={title}
        NavigationButton={NavigationButton}
        resourceName="Partner"
      />
      <FormContainer>
        <StyledPaper>
          <CardTitleContainer>
            <PaperTitle variant="h1" color="secondary">
              Partenaire
            </PaperTitle>
            <StyledSwitch
              defaultValue={false}
              label="Visible"
              source="isDisplayed"
            />
          </CardTitleContainer>
          <StyledStack>
            <TextInput
              key="datamatrixPartnerId"
              source="datamatrixPartnerId"
              helperText="L'identifiant partenaire doit être une suite de trois caractères alphanumériques."
              fullWidth
              validate={[
                required(),
                minLength(3),
                maxLength(3),
                alphanumericValidator,
                uniqueValidator(
                  (datamatrixPartnerId: string) =>
                    getPartnerByDatamatrixPartnerId({
                      variables: {datamatrixPartnerId},
                    }).then(({data}) => data?.getPartnerByDatamatrixPartnerId),
                  record?.datamatrixPartnerId,
                  'Cet id est déjà utilisé',
                ),
              ]}
            />
            <TextInput
              key="codeDetailLB"
              source="codeDetailLB"
              fullWidth
              validate={[
                required(),
                uniqueValidator(
                  (codeDetailLB: string) =>
                    getPartnerByCodeDetailLB({
                      variables: {codeDetailLB},
                    }).then(({data}) => data?.getPartnerByCodeDetailLB),
                  record?.codeDetailLB,
                  'Ce code est déjà utilisé',
                ),
              ]}
            />
            <SelectInput
              key="datamatrixSignatureConfig"
              source="datamatrixSignatureConfig"
              fullWidth
              validate={[required()]}
              defaultValue="ONLY_ALLOW_UNSIGNED"
              choices={[
                {id: 'ALLOW_BOTH', name: 'Clair & Signé'},
                {id: 'ONLY_ALLOW_SIGNED', name: 'Signé uniquement'},
                {id: 'ONLY_ALLOW_UNSIGNED', name: 'Clair uniquement'},
              ]}
            />
            <TextInput
              key="name"
              source="name"
              helperText='Le libellé partenaire ne doit pas contenir les caractères suivants & " < > /'
              validate={[
                required(),
                regex(
                  /^[^&"<>/]+$/,
                  'Ne peut contenir les caractères suivants : & " < > /',
                ),
                uniqueValidator(
                  (name: string) =>
                    getPartnerByName({
                      variables: {name},
                    }).then(({data}) => data?.getPartnerByName),
                  record?.name,
                  'Ce libellé est déjà utilisé',
                ),
              ]}
              fullWidth
            />
            <NumberInput
              key="displayOrderInApp"
              source="displayOrderInApp"
              min={0}
              step={1}
              fullWidth
            />
            <PartnerAuthInputs sourceBase="partnerAuth" />
          </StyledStack>
        </StyledPaper>
        <StyledPaper>
          <PaperTitle variant="h1" color="secondary">
            Logo
          </PaperTitle>
          <LogoUploadInput />
        </StyledPaper>
      </FormContainer>
    </PageLayout>
  );
};
