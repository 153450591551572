import {FC} from 'react';
import {DateField, DateFieldProps, Labeled} from 'react-admin';

type LabeledDateFieldProps = DateFieldProps;
export const LabeledDateField: FC<LabeledDateFieldProps> = (
  props,
): JSX.Element => {
  return (
    <Labeled>
      <DateField {...props} />
    </Labeled>
  );
};
