import {ComponentType} from 'react';

import {OnOff, PermissionDto} from '../../api/generated';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {Icons} from '../Icon';

type Feature = {
  name: string;
  label: string;
  icon: ComponentType;
  permission: PermissionDto;
};

export function useFeatures(): Feature[] {
  const featureFlags = useFeatureFlags();

  const features: Feature[] = [];

  if (featureFlags.neptuneSimulator === OnOff.On) {
    features.push({
      name: 'Neptune',
      label: 'Simulateur neptune',
      icon: Icons.Computer,
      permission: PermissionDto.NeptuneRead,
    });
  }

  return features;
}
