import React, {useContext} from 'react';

import {SimulationContext} from '../../context/context';
import {ActionTypes} from '../../context/reducer.types';
import ManualMode from './ManualMode';
import Results from './Results';
import {Reader} from './Scanner/Reader';

const ResultsFromContext: React.FC = () => {
  const {state, dispatch} = useContext(SimulationContext);

  if (!state.result) {
    return null;
  }

  return (
    <Results
      result={state.result}
      datamatrixContent={state.code}
      retailerRoleCode={state.retailerRoleCode}
      operatorId={state.operatorId}
      jsonMode={state.jsonMode}
      onJsonModeChange={(): void =>
        dispatch({
          type: ActionTypes.SetJSONMode,
          payload: {isJson: !state.jsonMode},
        })
      }
      onReset={(): void => dispatch({type: ActionTypes.Reset})}
      rescanText="Scanner à nouveau"
    />
  );
};

type Modes = Record<'scan' | 'manual' | 'results', JSX.Element>;

export const modes: Modes = {
  scan: <Reader />,
  manual: <ManualMode />,
  results: <ResultsFromContext />,
};
