import {ComponentType, FC} from 'react';

import AccountSVG from '../assets/icons/account.svg';
import AccountGreySVG from '../assets/icons/account-grey.svg';
import AddSVG from '../assets/icons/add.svg';
import AddBlueSVG from '../assets/icons/add-blue.svg';
import AgendaSVG from '../assets/icons/agenda.svg';
import AgendaGreySVG from '../assets/icons/agenda-grey.svg';
import ArrowDownSVG from '../assets/icons/arrow-down.svg';
import ArrowDownBlueSVG from '../assets/icons/arrow-down-blue.svg';
import BellSVG from '../assets/icons/bell.svg';
import CalendarSVG from '../assets/icons/calendar.svg';
import CalendarGreySVG from '../assets/icons/calendar-grey.svg';
import CartSVG from '../assets/icons/cart.svg';
import CartGreySVG from '../assets/icons/cart-grey.svg';
import ChevronDownSVG from '../assets/icons/chevron-down.svg';
import ChevronDownGreySVG from '../assets/icons/chevron-down-grey.svg';
import ChevronLeftSVG from '../assets/icons/chevron-left.svg';
import ChevronLeftGreySVG from '../assets/icons/chevron-left-grey.svg';
import CircledCheckSVG from '../assets/icons/circled-check.svg';
import CircledCrossSVG from '../assets/icons/circled-cross.svg';
import ComputerSVG from '../assets/icons/computer.svg';
import ComputerGreySVG from '../assets/icons/computer-grey.svg';
import ConfidanceSVG from '../assets/icons/confidance.svg';
import ConfidanceGreySVG from '../assets/icons/confidance-grey.svg';
import DeleteSVG from '../assets/icons/delete.svg';
import EditSVG from '../assets/icons/edit.svg';
import EuroSVG from '../assets/icons/euro.svg';
import EuroGreySVG from '../assets/icons/euro-grey.svg';
import HelpSVG from '../assets/icons/help.svg';
import HelpGreySVG from '../assets/icons/help-grey.svg';
import LockSVG from '../assets/icons/lock.svg';
import OperationSVG from '../assets/icons/operation.svg';
import OperationGreySVG from '../assets/icons/operation-grey.svg';
import PromotionSVG from '../assets/icons/promotion.svg';
import PromotionGreySVG from '../assets/icons/promotion-grey.svg';
import QrCodeSVG from '../assets/icons/qr-code.svg';
import QrCodeGreySVG from '../assets/icons/qr-code-grey.svg';
import RetailerSVG from '../assets/icons/retailer.svg';
import RetailerGreySVG from '../assets/icons/retailer-grey.svg';
import SearchSVG from '../assets/icons/search.svg';
import SearchGreySVG from '../assets/icons/search-grey.svg';
import SheetSVG from '../assets/icons/sheet.svg';
import SheetGreySVG from '../assets/icons/sheet-grey.svg';
import TrashSVG from '../assets/icons/trash.svg';
import TrashGreySVG from '../assets/icons/trash-grey.svg';
import UploadSVG from '../assets/icons/upload.svg';
import UploadGreySVG from '../assets/icons/upload-grey.svg';
import UploadLargeSVG from '../assets/icons/upload-large.svg';
import UploadLargeGreySVG from '../assets/icons/upload-large-grey.svg';
import WalletSVG from '../assets/icons/wallet.svg';
import WalletSVGGrey from '../assets/icons/wallet-grey.svg';

const icons = {
  Account: [AccountGreySVG, AccountSVG],
  Delete: [DeleteSVG],
  Edit: [EditSVG],
  Euro: [EuroGreySVG, EuroSVG],
  QrCode: [QrCodeGreySVG, QrCodeSVG],
  Sheet: [SheetGreySVG, SheetSVG],
  Wallet: [WalletSVGGrey, WalletSVG],
  Add: [AddBlueSVG, AddSVG],
  ArrowDown: [ArrowDownBlueSVG, ArrowDownSVG],
  Operation: [OperationGreySVG, OperationSVG],
  Retailer: [RetailerGreySVG, RetailerSVG],
  Search: [SearchGreySVG, SearchSVG],
  Help: [HelpGreySVG, HelpSVG],
  Confidance: [ConfidanceGreySVG, ConfidanceSVG],
  Computer: [ComputerGreySVG, ComputerSVG],
  ChevronDown: [ChevronDownGreySVG, ChevronDownSVG],
  Cart: [CartGreySVG, CartSVG],
  Calendar: [CalendarGreySVG, CalendarSVG],
  Agenda: [AgendaGreySVG, AgendaSVG],
  ChevronLeft: [ChevronLeftGreySVG, ChevronLeftSVG],
  Upload: [UploadSVG, UploadGreySVG],
  Trash: [TrashSVG, TrashGreySVG],
  UploadLarge: [UploadLargeSVG, UploadLargeGreySVG],
  Promotion: [PromotionGreySVG, PromotionSVG],

  // These icons doesn't have a grey version
  Bell: [BellSVG, BellSVG],
  CircledChecked: [CircledCheckSVG, CircledCheckSVG],
  CircledCross: [CircledCrossSVG, CircledCrossSVG],
  Lock: [LockSVG, LockSVG],
};

export type IconName = keyof typeof icons;

export interface IconProps {
  name: IconName;
  isActive?: boolean;
  size?: number;
}
export type IconComponentProps = Omit<IconProps, 'name'>;

export type IconComponentType = ComponentType<IconComponentProps>;

export const Icon: FC<IconProps> = ({name, isActive = false, size = 24}) => {
  return (
    <img
      src={icons[name][isActive ? 1 : 0]}
      alt={`${name}-icon`}
      style={{
        width: size,
        height: size,
      }}
    />
  );
};

export const Icons = Object.fromEntries(
  (Object.keys(icons) as IconName[]).map(name => [
    name,
    (props: IconComponentProps): JSX.Element => <Icon name={name} {...props} />,
  ]),
) as Record<IconName, IconComponentType>;
