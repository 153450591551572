import {ApolloProvider} from '@apollo/client';
import {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import client from './apolloClient';
import Admin from './components/Admin';
import MagicLinkVerifyToken from './components/MagicLinkVerifyToken';
import {FeatureFlagsProvider} from './context/FeatureFlagsContext';
import {logBuildInfos} from './utils/logBuildInfos';

function App(): JSX.Element {
  useEffect(() => {
    logBuildInfos();
  }, []);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <FeatureFlagsProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/admin" />} />
            <Route
              path="magic-link-callback"
              element={<MagicLinkVerifyToken />}
            />
            <Route path="/admin/*" element={<Admin />} />
          </Routes>
        </FeatureFlagsProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
