import {
  PaymentDto,
  ServiceRequestPendingDto,
} from '../dto/service-requests.dto';

/**
 * Transform a pending service request object to a list of confirm service request object
 * @param cartData Pending service request object
 * @returns List of service request confirm objects
 */
export function extractPaymentsFromPendingServiceRequestAndUserInput(
  cartData: ServiceRequestPendingDto,
): PaymentDto[] {
  return cartData.paymentAmounts.map(paymentAmount => ({
    amount: paymentAmount.fixedAmount,
    totalAmount: cartData.totalAmount,
  }));
}
