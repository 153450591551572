import {
  Datagrid,
  DateField,
  FunctionField,
  Pagination,
  ReferenceManyField,
  TextField,
} from 'react-admin';

import {NirioBankDebt, NirioBankDebtOrigin} from '../../api/generated';
import {
  MainPaperContainer,
  MainPaperContainerTitle,
  TableSpacer,
} from '../../components/CustomShow';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {
  nirioBankDebtOriginLabelMapping,
  retrieveOperationTypeLabel,
} from './helpers';

type NirioBankDebtTableProps = {
  title: string;
  filter: {
    origins?: NirioBankDebtOrigin[];
  };
};

export const NirioBankDebtTable = ({
  title,
  filter,
}: NirioBankDebtTableProps): JSX.Element | null => (
  <MainPaperContainer>
    <MainPaperContainerTitle variant="h1" color="secondary">
      {title}
    </MainPaperContainerTitle>
    <TableSpacer>
      <ReferenceManyField
        pagination={<Pagination />}
        perPage={5}
        reference="NirioBankDebt"
        target="nirioUserId"
        filter={filter}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid
          sx={{
            boxShadow: 'none',
          }}
          hover={false}
          bulkActionButtons={false}>
          <DateField source="createdAt" />

          <TextField source="cbsId" />

          <FunctionField
            source="origin"
            render={(record: NirioBankDebt): string =>
              nirioBankDebtOriginLabelMapping[record.origin]
            }
          />

          <FunctionField
            source="operationType"
            render={(record: NirioBankDebt): string =>
              retrieveOperationTypeLabel(record.operationType)
            }
          />

          <FunctionField
            source="amountInCents"
            render={(record: NirioBankDebt): string =>
              formatMaybeAmountInCents(record.amountInCents)
            }
          />

          <FunctionField
            source="remainingAmountInCents"
            render={(record: NirioBankDebt): string =>
              formatMaybeAmountInCents(record.remainingAmountInCents)
            }
          />

          <FunctionField
            source="paidAt"
            render={(record: NirioBankDebt): string =>
              `${record.paidAt ? 'Terminé' : 'En cours'}`
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </TableSpacer>
  </MainPaperContainer>
);
