import {styled} from '@mui/system';
import {FC} from 'react';
import {Layout, LayoutProps} from 'react-admin';

import {SideBar} from './SideBar';

const StyledLayout = styled(Layout)({
  '& .RaLayout-appFrame': {
    marginTop: 0,
    maxHeight: '100vh',
    overflow: 'auto',
  },
  '& .RaLayout-content': {
    padding: 0,
    backgroundColor: '#F9FAFC',
    wordWrap: 'break-word',
  },
});

export const CustomLayout: FC<
  JSX.IntrinsicAttributes & LayoutProps
> = props => <StyledLayout {...props} sidebar={SideBar} />;
