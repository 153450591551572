import {ApolloError} from '@apollo/client';
import ErrorCircleIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {Stack} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const MagicLinkLoginRequestInfos = ({
  loading,
  error,
}: {
  loading: boolean;
  error: ApolloError | undefined;
}): JSX.Element => (
  <Stack alignItems="center" direction="row" sx={{height: '64px'}}>
    {loading && (
      <Stack direction="row" alignItems="center">
        <CircularProgress
          size={32}
          color="secondary"
          sx={{margin: '0px 8px'}}
        />
        <span>Vérification en cours</span>
      </Stack>
    )}
    {error && (
      <Stack direction="row" alignItems="center">
        <ErrorCircleIcon
          sx={{
            color: '#DE3D4C',
            margin: '0px 8px',
          }}
        />
        Erreur inconnue du <strong>&nbsp;serveur&nbsp;</strong> lors de
        l&apos;envoi du lien de connexion. Veuillez réessayer plus tard.
      </Stack>
    )}
  </Stack>
);

export default MagicLinkLoginRequestInfos;
