export function logBuildInfos(): void {
  const lastCommitTag = process.env.REACT_APP_CI_COMMIT_TAG;
  const lastCommitHash = process.env.REACT_APP_CI_COMMIT_SHA;

  const jobStartedAt = new Date(process.env.REACT_APP_CI_JOB_STARTED_AT ?? '');
  const isValidDate = (date: Date): boolean => !isNaN(date.getTime());
  const lastBuildDate = isValidDate(jobStartedAt)
    ? new Intl.DateTimeFormat(undefined, {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(jobStartedAt)
    : null;

  // eslint-disable-next-line no-console
  console.log({
    lastCommitTag,
    lastCommitHash,
    lastBuildDate,
  });
}
