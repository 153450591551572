import {CreateBase, ShowButton, useNotify} from 'react-admin';

import {MutationCreatePartnerArgs, PermissionDto} from '../../api/generated';
import {StyledForm} from '../../components/Form/StyledForm';
import {PermissionGuard} from '../../components/PermissionGuard';
import {safeConvertFileToBase64} from '../../utils/base64.utils';
import {PartnerForm} from './PartnerForm';

export const PartnerCreate = (): JSX.Element => {
  const notify = useNotify();

  const onError = (): void =>
    notify('ra.notification.partnerCreateError', {type: 'error'});

  return (
    <PermissionGuard permission={PermissionDto.PartnerRead}>
      <CreateBase
        redirect="list"
        transform={async (
          data: MutationCreatePartnerArgs & {
            logo?: {rawFile: File} | null;
          },
        ): Promise<MutationCreatePartnerArgs> => ({
          ...data,
          partnerAuth:
            data.partnerAuth?.username && data.partnerAuth?.password
              ? data.partnerAuth
              : undefined,
          logo: await safeConvertFileToBase64(data.logo),
          displayOrderInApp: data.displayOrderInApp ?? 0,
          isDisplayed: !!data.isDisplayed,
        })}
        mutationOptions={{onError}}>
        <StyledForm>
          <PartnerForm
            NavigationButton={<ShowButton label="Annuler" icon={<div />} />}
          />
        </StyledForm>
      </CreateBase>
    </PermissionGuard>
  );
};
