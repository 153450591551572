import {
  AutocompleteArrayInputProps,
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  maxLength,
  TextField,
  TextInput,
} from 'react-admin';

import {
  PermissionDto,
  Product,
  useGetAllPartnersQuery,
  useGetAllProductCategoriesQuery,
} from '../../api/generated';
import {ExternalLink} from '../../components/ExternalLink';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {dateRangeFilters} from '../../components/Form/Filters/dateRangeFilters';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {RenderConditionalLabel} from '../../components/RenderLabel';
import {usePermissions} from '../../hooks/usePermissions';
import {alphanumericValidator} from '../../utils/input.utils';

const PartnerSelectInput = (
  props: AutocompleteArrayInputProps,
): JSX.Element => {
  const {data: {getAllPartners} = {}} = useGetAllPartnersQuery();

  return <AutocompleteArrayInput {...props} choices={getAllPartners} />;
};

const CategorySelectInput = (
  props: AutocompleteArrayInputProps,
): JSX.Element => {
  const {data: {getAllProductCategories} = {}} =
    useGetAllProductCategoriesQuery();

  return (
    <AutocompleteArrayInput
      {...props}
      choices={getAllProductCategories}
      optionText="label"
    />
  );
};

const productFilters = [
  <TextInput
    key="idFilter"
    source="partialDatamatrixProductId"
    label="ID produit"
    title="Maximum de cinq caractères (alphanumériques)"
    validate={[maxLength(5), alphanumericValidator]}
    alwaysOn
    resettable
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <AutocompleteInput
    key="statusFilter"
    source="active"
    choices={[
      {id: true, name: 'Actif'},
      {id: false, name: 'Inactif'},
    ]}
    alwaysOn
  />,
  <PartnerSelectInput
    key="partnerFilter"
    label="Libellés partenaire"
    source="partnerIds"
    alwaysOn
  />,
  <CategorySelectInput
    key="categoryFilter"
    label="Catégories produit"
    source="categoryIds"
    alwaysOn
  />,
  ...dateRangeFilters(),
  <FilterSpacer key="spacer" source="spacer" alwaysOn />,
  <FilterResetButton key="reset" source="reset" alwaysOn />,
];

export const ProductList = (): JSX.Element => {
  const {can} = usePermissions();

  const actions: JSX.Element[] = [];

  if (can(PermissionDto.ProductWrite)) {
    actions.push(
      <CreateButton
        variant="contained"
        key="create"
        icon={<Icons.Add isActive size={17} />}
      />,
    );
  }

  return (
    <PermissionGuard permission={PermissionDto.ProductRead}>
      <List
        actions={<ListTopBar actions={actions} />}
        filters={productFilters}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid hover={false} rowClick="show" bulkActionButtons={false}>
          <TextField source="datamatrixProductId" />
          <TextField source="name" />
          <DateField source="createdAt" showTime locales="fr-FR" />
          <FunctionField
            source="cbsMerchantId"
            render={(record: Product): JSX.Element => (
              <ExternalLink href={record.externalUrl}>
                {record.cbsMerchantId}
              </ExternalLink>
            )}
          />
          <FunctionField
            source="category.label"
            render={(record: Product): string =>
              `${record.category.label} - ${record.category.code}`
            }
          />
          <TextField source="partner.name" />
          <FunctionField
            source="partner.datamatrixPartnerId"
            render={(record: Product): JSX.Element => (
              <PermissionLink
                permission={PermissionDto.PartnerRead}
                to={`/admin/Partner/${record.partnerId}/show`}>
                {record.partner.datamatrixPartnerId}
              </PermissionLink>
            )}
          />
          <FunctionField
            source="active"
            render={(record: Product): JSX.Element => (
              <RenderConditionalLabel condition={record.active} />
            )}
          />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
