import {
  ApolloClient,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';

import {tokenStore} from './token-store';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

type Context = Record<string, unknown>;

const authLink = setContext((request, ctx: {headers: Context}): Context => {
  // Do not fetch accessToken for signIn and logout operations
  if (
    ['refreshAccessToken', 'verifyToken', 'logout'].includes(
      request.operationName ?? '',
    )
  ) {
    return ctx;
  }

  const accessToken = tokenStore.getCredentials()?.accessToken;

  return {
    headers: accessToken
      ? {
          ...ctx.headers,
          authorization: `Bearer ${accessToken}`,
        }
      : ctx.headers,
  };
});

const refreshTokenLink = onError(({graphQLErrors, operation, forward}) => {
  if (
    graphQLErrors?.some(({extensions}) => extensions.code === 'UNAUTHENTICATED')
  ) {
    return fromPromise(
      tokenStore.refreshToken().then(credentials => {
        if (!credentials) {
          throw new Error('NO_CREDENTIALS');
        }
        return credentials;
      }),
    ).flatMap(credentials => {
      const {headers: oldHeaders} = operation.getContext();
      operation.setContext({
        // The apollo client setContext function is typed incorrectly
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${credentials.accessToken}`,
        },
      });
      return forward(operation);
    });
  }
});

const apolloClient = new ApolloClient({
  link: from([authLink, refreshTokenLink, httpLink]),
  cache: new InMemoryCache(),
});

export default apolloClient;
