import {
  Datagrid,
  DateField,
  ExportButton,
  FunctionField,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import {
  Client,
  ClientProfile,
  PayerStatus,
  PermissionDto,
} from '../../api/generated';
import {ExternalLink} from '../../components/ExternalLink';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {dateRangeFilters} from '../../components/Form/Filters/dateRangeFilters';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {isUuid} from '../../utils/input.utils';
import {NirioBankDebtCards} from './NirioBankDebtCards';

const actions = [
  <ExportButton key="export" icon={<Icons.ArrowDown size={17} />} />,
];

const indicators = [<NirioBankDebtCards alwaysOn source="debt" key="debt" />]; // inactive KPI cards alongside the active filters

const filters = [
  ...indicators,
  <TextInput
    key="SIPId"
    source="id"
    label="Rechercher par ID SIP"
    validate={isUuid}
    resettable
    alwaysOn
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <TextInput
    key="cbsClientIdFilter"
    source="partialCbsClientId"
    label="Rechercher par Ref# CBS"
    resettable
    alwaysOn
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <TextInput
    key="emailFilter"
    source="partialEmail"
    label="Rechercher par email"
    resettable
    alwaysOn
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <AutocompleteArrayInput
    key="payerStatusFilter"
    source="status"
    label="Statut SIP"
    choices={Object.entries(PayerStatus).map(([_, value]) => ({
      id: value,
    }))}
    optionText="id"
    alwaysOn
  />,
  <AutocompleteArrayInput
    key="profilesFilter"
    source="profiles"
    label="Rechercher par profil"
    choices={Object.values(ClientProfile).map(value => ({
      id: value,
    }))}
    groupBy={(record): string =>
      `${record.id}`.includes('CDR')
        ? 'CDR'
        : `${record.id}`.includes('PEAGE_ANONYME')
        ? 'PEAGE_ANONYME'
        : 'NIRIO'
    }
    optionText="id"
    alwaysOn
  />,
  ...dateRangeFilters(),
  ...dateRangeFilters({
    from: 'nirioBankDebtCreatedAtGte',
    to: 'nirioBankDebtCreatedAtLte',
  }),
  <FilterSpacer key="spacer" source="spacer" alwaysOn />,
  <FilterResetButton key="reset" source="reset" alwaysOn />,
];

export const ClientList = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.PayerRead}>
      <List
        actions={<ListTopBar actions={actions} />}
        filters={filters}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid hover={false} rowClick="show" bulkActionButtons={false}>
          <TextField source="id" />
          <DateField source="createdAt" showTime locales="fr-FR" />
          <FunctionField
            source="cbsClientId"
            render={(record: Client): JSX.Element => (
              <ExternalLink href={record.cbsProfileUrl ?? undefined}>
                {record.cbsClientId ?? 'Ø'}
              </ExternalLink>
            )}
          />
          <TextField source="email" />
          <TextField source="profile" />
          <TextField source="status" />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
