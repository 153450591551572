import {
  Category,
  Edit,
  KeyboardBackspace as TurnbackIcon,
  Warning,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Button,
  Link,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
  useTranslate,
} from 'react-admin';

import {
  AppFaqContentCategoryInput,
  AppFaqContentPostInput,
  PermissionDto,
  useGetLatestAppFaqContentQuery,
  useUpdateAppFaqContentMutation,
} from '../api/generated';
import {FormToolbar} from '../components/Form/FormToolbar';
import {PageLayout} from '../components/PageLayout';
import {PermissionGuard} from '../components/PermissionGuard';
import {TopBar} from '../components/TopBar';

const LINKS = [
  {
    path: 'edit-posts',
    label: 'Editer les articles',
    icon: <Edit />,
  },
  {
    path: 'edit-categories',
    label: 'Modifier les catégories',
    icon: <Category />,
  },
];

export const AppFaqContentGrid = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.AppContentRead}>
    <PageLayout>
      <TopBar>
        <Typography variant="h1" color="secondary">
          FAQ
        </Typography>
        <Typography variant="h2" color="secondary">
          Tableau de bord d&apos;édition du contenu de la FAQ
        </Typography>
      </TopBar>
      <CardContent>
        <Grid container>
          {LINKS.map(link => (
            <Grid key={link.label} item md={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link style={{textDecoration: 'none'}} to={link.path}>
                  <Button
                    sx={{mb: 2, fontSize: '1.3rem', width: '400px'}}
                    variant="contained"
                    label={link.label}>
                    {link.icon}
                  </Button>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </PageLayout>
  </PermissionGuard>
);

const AppFaqContentEditSinglePost = ({
  post,
  objectRoot,
  translateRoot,
  categoryChoices,
}: {
  post: AppFaqContentPostInput & {
    categoryIndex: number;
  };
  objectRoot: string;
  translateRoot: string;
  categoryChoices: {name: string; id: number}[];
}): JSX.Element => {
  const translate = useTranslate();
  const theme = useTheme();
  return (
    <Card sx={{marginBottom: theme.spacing(4)}}>
      <CardContent>
        <Grid container columnSpacing={theme.spacing(2)}>
          <Grid item xs={4}>
            <TextInput
              label={translate(`${translateRoot}.title`)}
              source={`${objectRoot}.title`}
              defaultValue={post.title}
              fullWidth
            />
            <TextInput
              label={translate(`${translateRoot}.subtitle`)}
              source={`${objectRoot}.subtitle`}
              defaultValue={post.subtitle}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} flexDirection="column">
            <TextInput
              label={translate(`${translateRoot}.optionalIdentifier`)}
              source={`${objectRoot}.optionalIdentifier`}
              defaultValue={post.optionalIdentifier}
              fullWidth
            />

            <SelectInput
              label={translate(`${translateRoot}.postCategory`)}
              validate={required()}
              source={`${objectRoot}.newCategoryIndex`}
              defaultValue={post.categoryIndex}
              choices={categoryChoices}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={translate(`${translateRoot}.content`)}
              source={`${objectRoot}.content`}
              defaultValue={post.content}
              minRows={5}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const AppFaqContentEditPosts = (): JSX.Element => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useGetLatestAppFaqContentQuery({fetchPolicy: 'no-cache'});
  // After a mutation, the query does not register that the result
  // is different when the policy is not no-cache

  const [
    updateAppFaqContentMutation,
    {loading: mutationLoading, error: mutationError},
  ] = useUpdateAppFaqContentMutation();

  if (queryLoading || mutationLoading) {
    return <div>Chargement...</div>;
  }
  const content = queryData?.getLatestAppFaqContent.content;
  if (queryError || mutationError || !content) {
    return <div>Erreur serveur</div>;
  }

  const onSubmit = async (
    data: Record<string, object | string>,
  ): Promise<void> => {
    const latestFaq = queryData?.getLatestAppFaqContent;
    const {posts, title} = data as {
      posts: (AppFaqContentPostInput & {newCategoryIndex: number})[];
      title: string;
    };

    const newCategories = latestFaq.content.categories.map(category => ({
      ...category,
      posts: [] as AppFaqContentPostInput[],
      __typename: undefined,
    }));
    posts
      .filter(post => post.title)
      .forEach(
        ({title, subtitle, content, newCategoryIndex, optionalIdentifier}) => {
          newCategories[newCategoryIndex].posts.push({
            title,
            subtitle,
            optionalIdentifier,
            content,
          });
        },
      );

    await updateAppFaqContentMutation({
      variables: {
        content: {
          title,
          categories: newCategories,
        },
      },
    });
    redirect('/admin/AppFaqContent');
  };

  if (!content.categories.length) {
    return <div>Ajoutez des catégories avant d&apos;ajouter des articles</div>;
  }

  const previousPosts = content.categories.flatMap((category, index) =>
    category.posts.map(post => ({
      ...post,
      categoryIndex: index,
    })),
  );

  const newEmptyPost: AppFaqContentPostInput & {categoryIndex: number} = {
    title: '',
    content: '',
    categoryIndex: 0,
  };
  const posts = [...previousPosts, newEmptyPost];

  return (
    <PermissionGuard permission={PermissionDto.AppContentWrite}>
      <PageLayout>
        <TopBar>
          <Link to="/admin/AppFaqContent" title="Retour vers le menu FAQ">
            <TurnbackIcon />
          </Link>

          <Typography variant="h1" color="secondary">
            Éditer les articles de la FAQ
          </Typography>
          <Box>{/*<!-- padding -->*/}</Box>
        </TopBar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}>
          <Warning />

          <Typography variant="body2">
            Tout article sans titre sera supprimé
          </Typography>
        </Box>
        <SimpleForm onSubmit={onSubmit} toolbar={<FormToolbar />}>
          <TextInput
            label={translate(
              'resources.AppFaqContent.fields.content.fields.title',
            )}
            source="title"
            defaultValue={content.title}
            fullWidth
          />

          {posts.map((post, index) => (
            <AppFaqContentEditSinglePost
              post={post}
              translateRoot="resources.AppFaqContent.fields.content.fields.categories.fields.posts.fields"
              objectRoot={`posts[${index}]`}
              key={`${post.title};${index}`}
              categoryChoices={content.categories.map((category, index) => ({
                name: category.title,
                id: index,
              }))}
            />
          ))}
        </SimpleForm>
      </PageLayout>
    </PermissionGuard>
  );
};

const AppFaqContentEditSingleCategory = ({
  category,
  objectRoot,
  translateRoot,
}: {
  category: AppFaqContentCategoryInput;
  objectRoot: string;
  translateRoot: string;
}): JSX.Element => {
  const translate = useTranslate();
  const theme = useTheme();
  return (
    <Card
      sx={{
        marginBottom: theme.spacing(2),
      }}>
      <Stack
        component={CardContent}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        spacing={4}>
        <TextInput
          label={translate(`${translateRoot}.optionalIdentifier`)}
          source={`${objectRoot}.optionalIdentifier`}
          defaultValue={category.optionalIdentifier}
        />
        <TextInput
          label={translate(`${translateRoot}.title`)}
          source={`${objectRoot}.title`}
          defaultValue={category.title}
        />
        <TextInput
          label={translate(`${translateRoot}.description`)}
          source={`${objectRoot}.description`}
          defaultValue={category.description}
        />
        <Typography>
          {((count): string => {
            switch (count) {
              case 0:
                return 'Aucun article';
              case 1:
                return '1 article';
              default:
                return `${count} articles`;
            }
          })(category.posts.length)}{' '}
          dans la catégorie.
        </Typography>
      </Stack>
    </Card>
  );
};

export const AppFaqContentEditCategories = (): JSX.Element => {
  // TODO: Handle subcategories
  const redirect = useRedirect();
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useGetLatestAppFaqContentQuery({fetchPolicy: 'no-cache'});
  // After a mutation, the query does not register that the result
  // is different when the policy is not no-cache

  const [
    updateAppFaqContentMutation,
    {loading: mutationLoading, error: mutationError},
  ] = useUpdateAppFaqContentMutation();

  if (queryLoading || mutationLoading) {
    return <div>Chargement...</div>;
  }
  const content = queryData?.getLatestAppFaqContent.content;
  if (queryError || mutationError || !content) {
    return <div>Erreur serveur</div>;
  }

  const newEmptyCategory: AppFaqContentCategoryInput = {
    title: '',
    posts: [],
  };
  const categories = [...content.categories, newEmptyCategory];

  const onSubmit = async (
    data: Record<string, object | string>,
  ): Promise<void> => {
    const latestFaq = queryData?.getLatestAppFaqContent;
    const {newCategories} = data as {
      newCategories: AppFaqContentCategoryInput[];
    };

    const newCategoriesInput = categories
      .filter((_, index) => newCategories[index].title)
      .map((category, index) => ({
        title: newCategories[index].title,
        description: newCategories[index].description,
        optionalIdentifier: newCategories[index].optionalIdentifier,
        posts: category.posts.map(post => ({...post, __typename: undefined})),
        __typename: undefined,
      }));

    await updateAppFaqContentMutation({
      variables: {
        content: {
          title: latestFaq.content.title,
          categories: newCategoriesInput,
        },
      },
    });
    redirect('/admin/AppFaqContent');
  };

  return (
    <PermissionGuard permission={PermissionDto.AppContentWrite}>
      <PageLayout>
        <TopBar>
          <Link to="/admin/AppFaqContent" title="Retour vers le menu FAQ">
            <TurnbackIcon />
          </Link>

          <Typography variant="h1" color="secondary">
            Éditer les catégories d&apos;article de la FAQ
          </Typography>
          <Box>{/*<!-- padding -->*/}</Box>
        </TopBar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}>
          <Warning />

          <Typography variant="body2">
            Toute catégorie sans titre sera supprimée
          </Typography>
        </Box>
        <SimpleForm
          onSubmit={onSubmit}
          toolbar={
            <Toolbar>
              <Button type="submit" label="Enregistrer" />
            </Toolbar>
          }>
          <div>
            {categories.map(
              (category, index): JSX.Element => (
                <AppFaqContentEditSingleCategory
                  category={category}
                  translateRoot="resources.AppFaqContent.fields.content.fields.categories.fields"
                  objectRoot={`newCategories[${index}]`}
                  key={`${category.title};${index}`}
                />
              ),
            )}
          </div>
        </SimpleForm>
      </PageLayout>
    </PermissionGuard>
  );
};
