import {styled} from '@mui/material';
import {FC} from 'react';
import {
  Button,
  Link,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

import {
  PermissionDto,
  useGetMaintenanceSettingsQuery,
  useUpdateMaintenanceSettingsMutation,
} from '../../api/generated';
import {CustomView} from '../../components/CustomShow';
import {PermissionGuard} from '../../components/PermissionGuard';
import {maintenanceSettingsShowPath} from './constants';

const GridContainer = styled('div')({
  display: 'grid',
  width: '100%',
  gap: '24px',
  gridTemplateColumns: '1fr 1fr',
});

export const MaintenanceEdit: FC = () => (
  <PermissionGuard permission={PermissionDto.MaintenanceWrite}>
    <UnguardedMaintenanceEdit />
  </PermissionGuard>
);

interface UpdateMaintenanceSettingsVariables {
  go: boolean;
  premio: boolean;
  message: string | null;
}

const UnguardedMaintenanceEdit: FC = () => {
  const {loading, data, updateQuery} = useGetMaintenanceSettingsQuery();
  const [update] = useUpdateMaintenanceSettingsMutation();

  const redirect = useRedirect();
  const notify = useNotify();

  const onSubmit = async (
    variables: UpdateMaintenanceSettingsVariables,
  ): Promise<void> => {
    await update({
      variables,
      onCompleted: updated => {
        updateQuery(old => ({
          ...old,
          getMaintenanceSettings: updated.updateMaintenanceSettings,
        }));
        notify('Les paramètres de maintenance ont été modifiés', {
          type: 'success',
        });
        redirect(maintenanceSettingsShowPath);
      },
      onError: () => {
        notify("Les paramètres de maintenance n'ont pas pu être modifiés", {
          type: 'error',
        });
      },
    });
  };

  if (loading) {
    return null;
  }

  return (
    <SimpleForm
      record={data?.getMaintenanceSettings}
      onSubmit={onSubmit as never}
      sx={{padding: 0, margin: 0}}
      toolbar={false}>
      <CustomView
        title="Paramètres de maintenance"
        actions={[
          <Link to={maintenanceSettingsShowPath} key="cancel-button">
            <Button label="Annuler" />
          </Link>,
          <SaveButton key="save-button" />,
        ]}
        topPapersContainer={GridContainer}
        topPapers={[
          {
            title: 'Go',
            content: [
              <RadioButtonGroupInput
                key="go"
                label={false}
                source="go"
                choices={ongoingMaintenanceChoices}
                parse={parseRadioBooleanInput}
              />,
            ],
          },
          {
            title: 'Premio',
            content: [
              <RadioButtonGroupInput
                label={false}
                key="premio"
                source="premio"
                choices={ongoingMaintenanceChoices}
                parse={parseRadioBooleanInput}
              />,
            ],
          },
          {
            title: 'Notice',
            content: [
              <TextInput
                label={false}
                key="message"
                source="message"
                multiline
                resettable
              />,
            ],
            style: {gridColumn: 'span 2'},
          },
        ]}
      />
    </SimpleForm>
  );
};

const ongoingMaintenanceChoices = [
  {id: true, name: 'Maintenance en cours'},
  {id: false, name: 'Pas de maintenance'},
];

const radioBooleanInputMapping: Record<string, boolean | undefined> = {
  false: false,
  true: true,
};

const parseRadioBooleanInput = (value: string): boolean | undefined =>
  radioBooleanInputMapping[value];
