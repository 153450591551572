import Typography from '@mui/material/Typography/Typography';
import {FC} from 'react';
import {FileRejection} from 'react-dropzone';

import {ImageUploader} from '../../components/Form/Inputs/ImageInput/ImageUploader';

const caption = (
  <Typography variant="caption">
    Le fichier ne doit pas dépasser 1 Mo.
    <br />
    Le format requis est BMP 1 bit (monochrome).
    <br />
    La hauteur et la largeur ne doivent pas excéder 512 pixels.
    <br />
    La largeur en pixels doit être un multiple de 8.
  </Typography>
);

const parseFileRejections = (fileRejections: FileRejection[]): string => {
  if (fileRejections.length === 0) {
    return '';
  }
  for (const error of fileRejections[0].errors) {
    if (error.code === 'file-invalid-type') {
      return 'Seul est pris en charge le format BMP.';
    }
  }
  return '';
};

export const NeptuneLogoUploadInput: FC = () => (
  <ImageUploader
    caption={caption}
    source="neptuneReceiptLogo"
    accept="image/bmp"
    maxSize={1_000_000}
    parseFileRejectionsOnDropRejected={parseFileRejections}
  />
);
