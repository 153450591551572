import {FC} from 'react';
import {Labeled, TextField, TextFieldProps} from 'react-admin';

type LabeledTextFieldProps = TextFieldProps;
export const LabeledTextField: FC<LabeledTextFieldProps> = (
  props,
): JSX.Element => {
  return (
    <Labeled>
      <TextField {...props} />
    </Labeled>
  );
};
