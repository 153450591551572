import {AxiosError} from 'axios';
import {Dispatch} from 'react';

import {Action, ActionTypes, SimulationMode} from '../context/reducer.types';
import {Result} from '../dto/service-requests.dto';
import {neptuneClient} from './neptuneClient';

export async function makeRequestWithDataMatrixContent(
  retailerRoleCode: string,
  operatorId: string,
  dataMatrixContent: string,
  dispatch: Dispatch<Action>,
): Promise<void> {
  try {
    dispatch({
      type: ActionTypes.SetLoading,
      payload: {loading: true},
    });
    dispatch({
      type: ActionTypes.SetLocked,
      payload: {locked: true},
    });
    const client = await neptuneClient(retailerRoleCode, operatorId);

    const response = await client.post<Result>(
      '/serviceRequests',
      {
        dataMatrixContent,
      },
      {
        headers: {
          Accept:
            'application/vnd.fgs.ese.orchestration.servicerequest.pending-3+json, application/vnd.lotsys.falcon.error-1+json',
          'Content-Type':
            'application/vnd.fgs.ese.orchestration.servicerequest.creation-1+json; charset=utf-8',
        },
      },
    );
    dispatch({type: ActionTypes.SetResult, payload: {result: response.data}});

    dispatch({
      type: ActionTypes.SetSimulationMode,
      payload: {simulationMode: SimulationMode.Results},
    });
  } catch (err) {
    if (err instanceof AxiosError || err instanceof Error) {
      dispatch({
        type: ActionTypes.SetError,
        payload: {error: err},
      });
    }
    dispatch({
      type: ActionTypes.SetLocked,
      payload: {locked: false},
    });
  } finally {
    dispatch({type: ActionTypes.SetLoading, payload: {loading: false}});
  }
}
