import {Cart, PaymentWorkflow, PaymentWorkflowStatus} from '../api/generated';

export const getLastOperationConfirmed = (
  record: Cart,
): PaymentWorkflow | null => {
  const lastOperations = record.paymentWorkflows?.filter(
    operation => operation.status === PaymentWorkflowStatus.Confirmed,
  );
  if (lastOperations?.length && lastOperations.length > 0) {
    const lastPayment = lastOperations?.reduce(
      (acc: PaymentWorkflow, operation: PaymentWorkflow) => {
        if (!acc || acc.createdAt < operation.createdAt) {
          acc = operation;
        }
        return acc;
      },
    );
    return lastPayment;
  } else {
    return null;
  }
};
