import {Card, CardContent} from '@mui/material';
import React from 'react';

import {base64encode} from '../../../../utils/base64.utils';
import {JSONViewer} from '../../../JSONViewer';
import {
  PendingOperationWithSimulationParameters,
  Result,
} from '../../dto/service-requests.dto';
import PrettyInformations from './PrettyInformations';
import ControlBar from './PrettyInformations/ControlBar';

function constructPendingOpWithParams(
  result: Result | null,
): PendingOperationWithSimulationParameters {
  if (!result) {
    throw new Error('no result');
  }
  return {
    payment: result,
  };
}

type ResultsProps = {
  result: Result;
  datamatrixContent?: string;
  retailerRoleCode: string;
  operatorId: string;
  jsonMode: boolean;
  onJsonModeChange: (jsonMode: boolean) => void;
  onReset: () => void;
  rescanText: string;
};
const Results: React.FC<ResultsProps> = ({
  result,
  datamatrixContent,
  retailerRoleCode,
  operatorId,
  jsonMode,
  onJsonModeChange,
  onReset,
  rescanText,
}) => {
  return (
    <Card>
      <CardContent>
        {jsonMode ? (
          <JSONViewer data={result} />
        ) : (
          <PrettyInformations
            result={result}
            datamatrixContent={datamatrixContent}
          />
        )}
        <ControlBar
          jsonFunc={(): void => onJsonModeChange(!jsonMode)}
          rescanFunc={onReset}
          confirmationLink={`confirmations?paymentWorkflow=${base64encode(
            JSON.stringify(constructPendingOpWithParams(result)),
          )}&rdiRoleCode=${retailerRoleCode}&operatorId=${operatorId}`}
          rescanText={rescanText}
        />
      </CardContent>
    </Card>
  );
};

export default Results;
