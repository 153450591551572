import {FC} from 'react';
import {FunctionField, FunctionFieldProps, Labeled} from 'react-admin';

type LabeledFunctionFieldProps = FunctionFieldProps;
export const LabeledFunctionField: FC<LabeledFunctionFieldProps> = (
  props,
): JSX.Element => {
  return (
    <Labeled>
      <FunctionField {...props} />
    </Labeled>
  );
};
