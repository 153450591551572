import CheckBox, {CheckboxProps} from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup, {FormGroupProps} from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import clsx from 'clsx';
import {FieldTitle, useInput} from 'ra-core';
import {useCallback} from 'react';
import {
  CommonInputProps,
  InputHelperText,
  sanitizeInputRestProps,
} from 'react-admin';

import {Override} from '../../../utils/override-types.utils';

export type BooleanInputProps = Override<
  CommonInputProps,
  {defaultValue: boolean; options?: CheckboxProps}
> &
  Omit<CheckboxProps, 'defaultValue' | 'required'> &
  Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>;

export const CheckBoxBooleanInput = (props: BooleanInputProps): JSX.Element => {
  const {
    className,
    row = false,
    defaultValue = false,
    format,
    label,
    helperText,
    onBlur,
    onChange,
    onFocus,
    disabled,
    parse,
    resource,
    source,
    validate,
    options,
    sx,
    ...rest
  } = props;
  const {
    id,
    field,
    isRequired,
    fieldState: {error, isTouched},
    formState: {isSubmitted},
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    onBlur,
    onChange,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event: unknown) => {
      field.onChange(event);
      // Ensure field is considered as touched
      field.onBlur();
    },
    [field],
  );

  return (
    <FormGroup
      className={clsx('ra-input', `ra-input-${source}`, className)}
      row={row}
      sx={sx}>
      <FormControlLabel
        control={
          <CheckBox
            id={id}
            name={field.name}
            color="primary"
            onChange={handleChange}
            onFocus={onFocus}
            checked={Boolean(field.value)}
            {...sanitizeInputRestProps(rest)}
            {...options}
            disabled={disabled}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
      <FormHelperText error={(isTouched || isSubmitted) && !!error}>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  );
};
