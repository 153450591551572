import {Paper, Stack, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Property} from 'csstype';
import {CSSProperties, FC, Fragment, ReactNode} from 'react';
import {
  Link,
  LinkProps,
  RaRecord,
  ShowBase,
  useShowController,
} from 'react-admin';

import {basename} from '../constants';
import {Icons} from './Icon';

const PageLayout = styled('div')({
  width: '100%',
  padding: '0 42px 0 42px',
  maxHeight: '100vh',
  overflow: 'auto',
});

const ShowTitleContainer = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  textDecoration: 'none',
});

const ShowTitle = styled(Typography)({
  '&:first-letter': {
    textTransform: 'capitalize',
  },
});

export const TopPapersFlexContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '24px',
  marginBottom: '46px',
});

export const StyledTopPaper = styled(Paper)({
  backgroundColor: 'white',
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
});

export const TopPaperTitle = styled(Typography)({
  marginBottom: '10px',
});

export const MainPaperContainer = styled(Paper)({
  backgroundColor: 'white',
  marginBottom: '42px',
});

export const MainPaperContainerTitle = styled(Typography)({
  margin: '32px',
});

export const TableSpacer = styled('div')({
  rowGap: '40px',
});

const TopBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '60px 0 0 0',
  minHeight: '56px',
  width: '100%',
  marginBottom: '44px',
});

const ActionsContainer = styled('div')({
  display: 'flex',
  gap: '12px',
});

export interface CustomShowProps {
  backTo?: string;
  title?: string;
  titleTextTransform?: Property.TextTransform;
  // we need a renderTitle because whoever consumes this comp won't have access
  // to showContext (provided by ShowBase) and therefore the Record
  renderTitle?: (record?: RaRecord) => string;
  topPapers?: {
    title?: string;
    content: JSX.Element | JSX.Element[];
    style?: CSSProperties;
  }[];
  topPapersContainer?: FC<{children: ReactNode}>;
  mainPaper?: {
    title?: string;
    content: JSX.Element;
  }[];
  mainPaperTitle?: string;
  actions?: JSX.Element[];
}

export const CustomShow: FC<CustomShowProps> = props => {
  const showController = useShowController<RaRecord>();

  const title =
    props.title ||
    (props.renderTitle && props.renderTitle(showController.record)) ||
    showController.defaultTitle;

  const backTo = props.backTo || `${basename}/${showController.resource}`;

  if (!showController.record) {
    return null;
  }

  return (
    <ShowBase>
      <CustomView {...props} backTo={backTo} title={title} />
    </ShowBase>
  );
};

export type CustomShowViewProps = Pick<
  CustomShowProps,
  | 'actions'
  | 'backTo'
  | 'mainPaper'
  | 'mainPaperTitle'
  | 'titleTextTransform'
  | 'topPapers'
  | 'topPapersContainer'
> & {
  title: string;
};

export const CustomView: FC<CustomShowViewProps> = props => {
  const theme = useTheme();
  const TopPapersContainer = props.topPapersContainer ?? TopPapersFlexContainer;

  return (
    <PageLayout>
      <TopBar>
        <OptionalBackTo to={props.backTo}>
          <ShowTitle
            variant="h1"
            color="secondary"
            textTransform={props.titleTextTransform || 'capitalize'}>
            {props.title}
          </ShowTitle>
        </OptionalBackTo>
        {props.actions && <ActionsContainer>{props.actions}</ActionsContainer>}
      </TopBar>

      <TopPapersContainer>
        {props.topPapers?.map((item, index) => (
          <StyledTopPaper style={item.style} key={index}>
            {item.title && (
              <TopPaperTitle variant="h1" color="secondary">
                {item.title}
              </TopPaperTitle>
            )}
            <Stack direction="column" rowGap={theme.spacing(1)}>
              {item.content}
            </Stack>
          </StyledTopPaper>
        ))}
      </TopPapersContainer>

      <MainPaperContainer>
        <>
          {props.mainPaperTitle && (
            <MainPaperContainerTitle variant="h1" color="secondary">
              {props.mainPaperTitle}
            </MainPaperContainerTitle>
          )}
          <TableSpacer>
            {props.mainPaper?.map((item, index) => (
              <Fragment key={index}>
                <MainPaperContainerTitle variant="h2">
                  {item.title}
                </MainPaperContainerTitle>
                {item.content}
              </Fragment>
            ))}
          </TableSpacer>
        </>
      </MainPaperContainer>
    </PageLayout>
  );
};

const OptionalBackTo: FC<
  Omit<LinkProps, 'to'> & {
    to: LinkProps['to'] | undefined;
  }
> = props =>
  props.to ? (
    <ShowTitleContainer to={props.to}>
      <Icons.ChevronLeft size={20} />
      {props.children}
    </ShowTitleContainer>
  ) : (
    <>{props.children}</>
  );
