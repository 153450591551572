import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const paymentWorkflowProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_LIST' || fetchType === 'GET_MANY_REFERENCE') {
    return {
      ...builtQuery,
      query: gql`
        query allPaymentWorkflows(
          $page: Int!
          $perPage: Int!
          $sortField: String!
          $sortOrder: String!
          $filter: PaymentWorkflowFilterDto!
        ) {
          items: allPaymentWorkflows(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            id
            createdAt
            updatedAt
            status
            paymentMethod
            amountInCents
            retailer {
              id
              rdiRoleCode
              departmentCode
              zipCode
            }
            cart {
              id
              paidAt
              client {
                profile
              }
              items {
                cbsTransactionId
                cartId
                amountInCents
                serviceFeeInCents
                payable {
                  id
                  client {
                    profile
                  }
                  product {
                    partner {
                      id
                      name
                    }
                    id
                    name
                    category {
                      label
                    }
                  }
                }
              }
            }
          }
          total: _allPaymentWorkflowsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
          ) {
            count
          }
        }
      `,
    };
  }

  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      query: gql`
        query PaymentWorkflow($id: String!) {
          data: PaymentWorkflow(id: $id) {
            id
            createdAt
            updatedAt
            status
            error
            paymentMethod
            amountInCents
            cart {
              id
              paidAt
              payerId
              client {
                id
                cbsProfileUrl
                profile
                cbsClientId
              }
              items {
                cbsTransactionId
                cartId
                payable {
                  id
                  client {
                    id
                    profile
                    cbsClientId
                    cbsProfileUrl
                  }
                  product {
                    name
                    id
                    datamatrixProductId
                    partner {
                      id
                      name
                    }
                    category {
                      label
                    }
                  }
                }
              }
            }
            retailer {
              name
              id
              rdiRoleCode
              zipCode
              departmentCode
            }
          }
        }
      `,
    };
  }

  return builtQuery;
};
