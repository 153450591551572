import {EditBase, RaRecord, ShowButton, useEditController} from 'react-admin';

import {
  MutationUpdateProductArgs,
  PermissionDto,
  ProductAuthenticationMode,
} from '../../api/generated';
import {StyledForm} from '../../components/Form/StyledForm';
import {PermissionGuard} from '../../components/PermissionGuard';
import {safeConvertFileToBase64} from '../../utils/base64.utils';
import {ProductForm} from './ProductForm';

export const productCreateEditTransform = async ({
  passwordModified,
  credentialsUsername,
  credentialsPassword,
  ...data
}: MutationUpdateProductArgs & {
  neptuneReceiptLogo?: {rawFile: File} | null;
  passwordModified: boolean | undefined;
  credentialsUsername: string | null;
  credentialsPassword: string | null;
  category: {id: string};
}): Promise<MutationUpdateProductArgs> => {
  // No credentials without auth
  const hasAuthMode =
    data.authenticationMode !== null && data.authenticationMode !== undefined;

  return {
    ...data,
    credentialsUsername: hasAuthMode ? credentialsUsername : null,
    credentialsPassword: hasAuthMode
      ? passwordModified // if password has not been changed, keep old password (undefined)
        ? credentialsPassword
        : undefined
      : null,
    neptuneReceiptLogo:
      typeof data.neptuneReceiptLogo == 'string'
        ? undefined
        : await safeConvertFileToBase64(data.neptuneReceiptLogo),
    cbsMerchantId: data.cbsMerchantId ? data.cbsMerchantId : undefined, // we want to parse empty string to undefined
    // Only defined for Oauth
    authenticationUrl:
      data.authenticationMode === ProductAuthenticationMode.Oauth
        ? data.authenticationUrl
        : null,
  };
};

export const ProductEdit = (): JSX.Element => {
  const editController = useEditController<RaRecord>();

  const title = editController.record?.name as string;

  return (
    <PermissionGuard permission={PermissionDto.ProductWrite}>
      <EditBase
        actions={false}
        mutationMode="pessimistic"
        transform={productCreateEditTransform}>
        <StyledForm shouldUnregister>
          <ProductForm
            title={`Produit ${title}`}
            NavigationButton={<ShowButton label="Annuler" icon={<div />} />}
          />
        </StyledForm>
      </EditBase>
    </PermissionGuard>
  );
};
