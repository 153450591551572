import {Datagrid, FunctionField, List, TextField, TextInput} from 'react-admin';

import {PermissionDto, Retailer, RetailerStatus} from '../../api/generated';
import {ExternalLink} from '../../components/ExternalLink';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {RetailerCards} from './RetailerCards';

const retailerFilter = [
  <RetailerCards alwaysOn key="test" source="cards" />,
  <TextInput
    source="rdiRoleCode"
    key="rdiRoleCode"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <TextInput
    source="rdiPointOfSaleCode"
    key="rdiPointOfSaleCode"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <TextInput
    source="name"
    key="name"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <AutocompleteArrayInput
    source="status"
    alwaysOn
    key={4}
    choices={[
      {
        id: 'OPEN',
        name: 'Ouvert',
      },
      {
        id: 'PENDING',
        name: 'En attente',
      },
      {
        id: 'CLOSED',
        name: 'Fermé',
      },
      {
        id: 'BLOCKED_MANUALLY',
        name: 'Bloqué manuellement',
      },
      {
        id: 'BLOCKED_BY_RDI',
        name: 'Bloqué par RDI',
      },
    ]}
  />,
  <FilterSpacer key="spacer" source="spacer" alwaysOn />,
  <FilterResetButton key="reset" source="reset" alwaysOn />,
];

export const RetailerList = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.RetailerRead}>
      <List
        exporter={false}
        filters={retailerFilter}
        actions={<ListTopBar />}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid rowClick="show" hover={false} bulkActionButtons={false}>
          <TextField source="rdiRoleCode" />
          <FunctionField
            source="cbsAgentId"
            render={(record: Retailer): JSX.Element => (
              <ExternalLink href={record.externalUrl}>
                {record.cbsAgentId ?? 'Ø'}
              </ExternalLink>
            )}
          />
          <TextField source="rdiPointOfSaleCode" />
          <TextField source="name" />
          <FunctionField
            sortable={false}
            label="Codes offres de services"
            render={(record: Retailer): string =>
              record.serviceOffers
                .map(({formattedId}) => formattedId)
                .join('; ')
            }
          />
          <FunctionField
            label="Statut détaillant"
            render={(record: Retailer): string => {
              switch (record.status) {
                case RetailerStatus.BlockedByRdi:
                  return 'Bloqué par RDI';
                case RetailerStatus.BlockedManually:
                  return 'Bloqué manuellement';
                case RetailerStatus.Closed:
                  return 'Fermé';
                case RetailerStatus.Open:
                  return 'Ouvert';
                case RetailerStatus.Pending:
                  return 'En attente';
                default:
                  return 'Inconnu';
              }
            }}
          />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
