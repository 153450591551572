import {Route, Routes} from 'react-router-dom';

import {NeptuneHome} from './pages/NeptuneHome';
import {OperationReport} from './pages/OperationReport';
import {OperationSearch} from './pages/OperationSearch';
import PaymentsConfirmations from './pages/PaymentsConfirmations/PaymentsConfirmations';
import PaymentSimulation from './pages/PaymentSimulation/PaymentSimulation';
import {TollSimulator} from './pages/TollSimulator';

export const NeptuneRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<NeptuneHome />} />
      <Route path="/scan" element={<PaymentSimulation />} />
      <Route path="/toll" element={<TollSimulator />} />
      <Route path="/operation/search" element={<OperationSearch />} />
      <Route path="/operation/report" element={<OperationReport />} />
      <Route path="/confirmations" element={<PaymentsConfirmations />} />
    </Routes>
  );
};
