import {styled, Typography} from '@mui/material';
import {
  ArrayField,
  DateField,
  EditButton,
  FunctionField,
  ImageField,
  Labeled,
  useShowController,
  WithListContext,
} from 'react-admin';

import {
  PermissionDto,
  Product,
  ProductAuthenticationMode,
} from '../../api/generated';
import {CustomShow} from '../../components/CustomShow';
import {ExternalLink} from '../../components/ExternalLink';
import {LabeledFunctionField} from '../../components/Fields/LabeledFunctionField';
import {LabeledTextField} from '../../components/Fields/LabeledTextField';
import {PermissionGuard} from '../../components/PermissionGuard';
import {RenderConditionalLabel} from '../../components/RenderLabel';
import {usePermissions} from '../../hooks/usePermissions';
import {palette} from '../../theme/palette';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {GenerateReportButton} from './GenerateReportButton';
import {
  renderProductAllowedPaymentMethods,
  renderProductDetails,
} from './helpers';

const StyledCardsContentWithoutMargin = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledCardsContent = styled('div')({
  marginTop: '23px',
});

const LabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '7px',

  '& .MuiTypography-root': {
    fontSize: '14px',
  },
  '& .RaLabeled-label': {
    color: palette.grey[500],
    fontSize: '12px',
  },
});

const LeftCardTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '30px',
});

const GridContainer = styled('div')({
  display: 'grid',
  width: '100%',
  gap: '24px',
  gridTemplateColumns: '1fr 1fr',
});

const leftCardContent = [
  <LabeledTextField source="category.label" key="category" />,
  <LabeledTextField source="partner.name" key="partner" />,
  <LabeledTextField source="name" key="name" />,
  <LabeledTextField source="datamatrixProductId" key="datamatrixProductId" />,
  <Labeled key="createdAt">
    <DateField
      source="createdAt"
      showTime={true}
      key="createdAt"
      locales="fr-FR"
    />
  </Labeled>,
  <LabeledTextField source="category.collectionType" key="collectionType" />,
  <LabeledTextField source="category.serviceOffer.label" key="serviceOffer" />,
  <LabeledTextField
    source="serviceFeeDescription"
    key="serviceFeeDescription"
    emptyText="Ø"
  />,
];

const rightCardContent = [
  <LabeledFunctionField
    key="allowedPaymentMethods"
    source="allowedPaymentMethods"
    render={renderProductAllowedPaymentMethods}
  />,

  <LabeledFunctionField
    key="minimumPaymentInCents"
    render={(record: Product): string =>
      record.minimumPaymentInCents
        ? formatMaybeAmountInCents(record.minimumPaymentInCents)
        : '0'
    }
    source="minimumPaymentInCents"
  />,

  <LabeledFunctionField
    key="maximumPaymentInCents"
    render={(record: Product): string =>
      record.maximumPaymentInCents
        ? formatMaybeAmountInCents(record.maximumPaymentInCents)
        : '0'
    }
    source="maximumPaymentInCents"
  />,
  <LabeledFunctionField
    render={renderProductDetails}
    key="details"
    label="Détails"
  />,
];
const CBSCardContent = [
  <Labeled key="cbsMerchantId">
    <FunctionField
      source="cbsMerchantIdFormPage"
      render={(record: Product): JSX.Element => (
        <ExternalLink href={record.externalUrl}>
          {record.cbsMerchantId}
        </ExternalLink>
      )}
    />
  </Labeled>,
];

const apiCardContent = (record: Product | undefined): JSX.Element[] => [
  <LabeledFunctionField
    key="authenticationMode"
    source="authenticationMode"
    render={(record: Product): string =>
      record.authenticationMode === null ||
      record.authenticationMode === undefined
        ? 'Ø'
        : record.authenticationMode === ProductAuthenticationMode.Basic
        ? 'Basic Auth'
        : 'Oauth'
    }
  />,
  <LabeledTextField key="apiUrl" source="apiUrl" emptyText="Ø" />,
  <LabeledTextField
    key="transactionNoticeUrl"
    source="transactionNoticeUrl"
    emptyText="Ø"
  />,
  ...(record?.authenticationMode === ProductAuthenticationMode.Oauth
    ? [<LabeledTextField key="authenticationUrl" source="authenticationUrl" />]
    : []),
  <LabeledTextField
    key="credentialsUsername"
    source="credentialsUsername"
    emptyText="Ø"
  />,
  <LabeledFunctionField
    key="hasEncryptedCredentials"
    source="hasEncryptedCredentials"
    render={(record: Product): string =>
      record.hasEncryptedCredentials ? '***************' : 'Ø'
    }
  />,
  <LabeledTextField
    key="oauthBodyEncoding"
    source="oauthBodyEncoding"
    emptyText="Ø"
  />,
  <Labeled key="oauthBody">
    <ArrayField source="oauthBody">
      <WithListContext
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        render={({data}) => (
          <ul>
            {data.map(({key, value}) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <li key={key}>
                {key}: {value}
              </li>
            ))}
          </ul>
        )}
      />
    </ArrayField>
  </Labeled>,
];

const defaultTopPapers = [
  {
    content: (
      <StyledCardsContentWithoutMargin>
        <LeftCardTitleContainer>
          <Typography variant="h1" color="secondary">
            Produit
          </Typography>
          <FunctionField
            render={(record: Product): JSX.Element => (
              <RenderConditionalLabel condition={record.active} />
            )}
          />
        </LeftCardTitleContainer>
        <LabelContainer>{leftCardContent}</LabelContainer>
      </StyledCardsContentWithoutMargin>
    ),
  },
  {
    title: 'Règles de gestion',
    content: (
      <StyledCardsContent>
        <LabelContainer>{rightCardContent}</LabelContainer>
      </StyledCardsContent>
    ),
  },
];

const partnerApiConfiguration = (
  record: Product | undefined,
): {title: string; content: JSX.Element; style: {gridColumn: string}} => ({
  title: "Paramétrage de l'API du partenaire",
  content: (
    <StyledCardsContent>
      <LabelContainer>{apiCardContent(record)}</LabelContainer>
    </StyledCardsContent>
  ),
  style: {
    gridColumn: 'span 2',
  },
});

const cbsConfiguration = (
  isDepositOrWithdrawal: boolean,
): {title: string; content: JSX.Element; style: {gridColumn?: string}} => ({
  title: 'Paramétrage avec le CBS',
  content: (
    <StyledCardsContent>
      <LabelContainer>{CBSCardContent}</LabelContainer>
    </StyledCardsContent>
  ),
  style: isDepositOrWithdrawal
    ? {}
    : {
        gridColumn: 'span 2',
      },
});

const logoTopPaper = {
  title: 'Logo Reçu Neptune',
  content: (
    <StyledCardsContent>
      <LabelContainer>
        {[
          <Labeled key="neptuneReceiptLogo">
            <ImageField
              source="neptuneReceiptLogo"
              label={false}
              sx={{
                '& .RaImageField-image': {
                  /** Overriding default width (200px) and height (100px) to keep original proportions,
                   *  resizing so that larger side always equals 200px.
                   * */
                  width: '100%',
                  height: '100%',
                  maxHeight: 200,
                  maxWidth: 200,
                },
              }}
              emptyText="Aucun logo n'a encore été téléversé pour ce produit."
            />
          </Labeled>,
        ]}
      </LabelContainer>
    </StyledCardsContent>
  ),
};

export const ProductShow = (): JSX.Element => {
  const {can} = usePermissions();

  const actions = [<GenerateReportButton key="generate-report" />];
  if (can(PermissionDto.ProductWrite)) {
    actions.push(
      <EditButton
        icon={<div />}
        key="edit-button"
        label="Modifier"
        variant="contained"
      />,
    );
  }

  const {record} = useShowController<Product>();

  const isDepositOrWithdrawal =
    !!record &&
    (record.category.code === 'RET' || record.category.code === 'DEP');

  const topPapers = defaultTopPapers.concat(
    cbsConfiguration(isDepositOrWithdrawal),
    isDepositOrWithdrawal ? logoTopPaper : [],
    partnerApiConfiguration(record),
  );

  return (
    <PermissionGuard permission={PermissionDto.ProductRead}>
      <CustomShow
        actions={actions}
        topPapersContainer={GridContainer}
        topPapers={topPapers}
      />
    </PermissionGuard>
  );
};
