import {styled, Typography} from '@mui/material';

const StyledPill = styled('div')({
  borderRadius: '8px',
  padding: '4px 8px',
  width: 'fit-content',
});

interface RenderConditionalLabelProps {
  condition: boolean;
  trueText?: string;
  falseText?: string;
}

export const RenderConditionalLabel = ({
  condition,
  trueText = 'Actif',
  falseText = 'Inactif',
}: RenderConditionalLabelProps): JSX.Element => {
  return (
    <StyledPill
      style={{
        background: condition
          ? 'linear-gradient(169.02deg, #2222B3 38.86%, #00B9FF 113.77%)'
          : 'linear-gradient(110.51deg, #FFCB8F 0%, #FFB259 100%)',
      }}>
      <Typography variant="body1" color="primary.contrastText" fontWeight={700}>
        {condition ? trueText : falseText}
      </Typography>
    </StyledPill>
  );
};
