import {Stack, styled} from '@mui/material';
import {AutocompleteArrayInputProps, ImageField} from 'react-admin';

import {useGetAllPartnersQuery} from '../../api/generated';
import {AutocompleteArrayInput} from '../../components/Form/Inputs/AutocompleteArrayInput';

export const RoundedImageField = styled(ImageField)({
  ' & .RaImageField-image': {
    height: '90px',
    width: '90px',
    transform: 'translateY(2px)',
    margin: '0px',
  },
  ' & .MuiBox-root': {
    height: '90px',
    width: '90px',
  },
  img: {borderRadius: '8px'},
});

export const StyledStack = styled(Stack)({
  ' .MuiFormControl-root': {margin: '15px 0'},
});

export const NamesSelectInput = (
  props: AutocompleteArrayInputProps,
): JSX.Element => {
  const {data: {getAllPartners} = {}} = useGetAllPartnersQuery();
  const partners: {name: string}[] | undefined = getAllPartners?.map(
    ({name}) => ({
      name,
    }),
  );

  return (
    <AutocompleteArrayInput {...props} choices={partners} optionValue="name" />
  );
};
