import {Alert} from '@mui/material';
import {FC} from 'react';
import {
  FormDataConsumer,
  Loading,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import {useParams} from 'react-router-dom';

import {
  CessationRaInitiative,
  PermissionDto,
  useGetBeneficiariesQuery,
  useGetCessationRaReasonsQuery,
} from '../../api/generated';
import {MainPaperContainer} from '../../components/CustomShow';
import {NirioCreate} from '../../components/NirioCreate';
import {PermissionGuard} from '../../components/PermissionGuard';

export const CessationRaWorkflowCreate: FC = () => {
  const {userId} = useParams();

  const {
    data: beneficiaries,
    loading: beneficiariesLoading,
    error: beneficiariesError,
  } = useGetBeneficiariesQuery({variables: {nirioUserId: userId ?? ''}});

  const {
    data: cessationRaReasons,
    loading: cessationRaReasonsLoading,
    error: cessationRaReasonsError,
  } = useGetCessationRaReasonsQuery();

  if (!userId) {
    return (
      <p>Erreur lors de la récupération de l&apos;identifiant utilisateur</p>
    );
  }

  if (cessationRaReasonsLoading || beneficiariesLoading) {
    return <Loading />;
  }

  if (beneficiariesError || !beneficiaries) {
    return <p>Erreur lors de la récupération des bénéficiaires</p>;
  }

  if (cessationRaReasonsError || !cessationRaReasons) {
    return <p>Erreur lors de la récupération des raisons de cessation</p>;
  }

  return (
    <PermissionGuard permission={PermissionDto.CraWrite}>
      <MainPaperContainer>
        <NirioCreate
          backTo={`/admin/Client/${userId}/show`}
          redirect={(): string => `Client/${userId}/show`}
          resource="CessationRaWorkflow"
          title="Cessation de la relation d'affaire">
          <Alert severity="error" sx={{mb: '0.5em', fontSize: '1.5em'}}>
            Attention, cette action est <strong>définitive</strong> et&nbsp;
            <strong>irréversible</strong>.
          </Alert>
          <Alert severity="warning" sx={{mb: '2em', fontSize: '1.5em'}}>
            À la clôture définitive des comptes et projets, le solde total* est
            transféré vers un bénéficiaire. Ce bénéficiaire doit être créé&nbsp;
            <strong>avant</strong> remplissage de ce formulaire. Veuillez
            vérifier que le bénéficiaire existe bien, et le créer via
            l&apos;espace partenaire Xpollens si ce n&apos;est pas le cas.
            <br />
            *A noter : Une potentielle régularisation des frais de service peut
            impacter le solde total. Cela se fait automatiquement sans action de
            votre part.
          </Alert>

          <TextInput
            source="nirioUserId"
            validate={[required()]}
            defaultValue={userId}
            label="Id de l'utilisateur dans le SIP"
            fullWidth
            disabled
          />
          <SelectInput
            source="initiative"
            choices={[
              {id: CessationRaInitiative.Bank, name: 'La banque'},
              {id: CessationRaInitiative.Client, name: 'Le client'},
            ]}
            label="Qui est l'initiateur de la demande ?"
            parse={(value: string): string | null =>
              value === '' ? null : value
            }
            defaultValue={null}
            validate={[required()]}
            fullWidth
          />

          <FormDataConsumer<{initiative: CessationRaInitiative}>>
            {({formData}): JSX.Element => {
              return formData.initiative === CessationRaInitiative.Client ? (
                <>
                  <SelectInput
                    source="reason"
                    choices={cessationRaReasons.getCessationRaReasons.map(
                      reason => ({
                        id: reason.code,
                        name: reason.label,
                      }),
                    )}
                    label="Quelle est sa raison de cessation ?"
                    parse={(value: string): string | null =>
                      value === '' ? null : value
                    }
                    defaultValue={null}
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="reasonComment"
                    multiline={true}
                    fullWidth
                    label="Précisions apportées par le client"
                    parse={(value: string): string | null =>
                      value === '' ? null : value
                    }
                    defaultValue={null}
                  />
                </>
              ) : (
                <></>
              );
            }}
          </FormDataConsumer>

          <SelectInput
            source="iban"
            choices={beneficiaries.getBeneficiaries.map(beneficiary => ({
              id: beneficiary.iban,
              name: `${beneficiary.displayName} - ${beneficiary.iban}`,
            }))}
            label="À la clôture définitive des comptes et projets, le solde total est transféré vers le bénéficiaire sélectionné. Sélectionnez un bénéficiaire."
            validate={[required()]}
            fullWidth
          />

          <Alert severity="info" sx={{mb: '15px', fontSize: '1em'}}>
            <p>
              <strong>
                Le client souhaite-t-il downgrader à une offre Go ou bien cesser
                totalement la relation d&apos;affaires ?
              </strong>
            </p>
            <br />
            Pour rappel, voici les avantages de Nirio Go à transmettre au client
            :
            <br />
            <div style={{marginTop: '0.5em'}}>
              <cite>
                Avec Nirio Go, vous pouvez :
                <ul style={{marginBlockStart: '0.25em'}}>
                  <li>
                    Régler vos factures dans le 1er réseau de proximité en
                    France, plus de 10 000 points de paiement
                  </li>
                  <li>
                    Gérer le paiement de vos factures comme vous le voulez, en
                    espèces ou en carte bancaire
                  </li>
                  <li>
                    C&apos;est 100 % sécurisé et sans abonnement, 0€ / mois
                  </li>
                </ul>
              </cite>
            </div>
            Lien vers la liste des partenaires :&nbsp;
            <a href="https://www.nirio.fr/#Partners">
              Payer ses factures et son loyer avec Nirio - Groupe FDJ
            </a>
          </Alert>

          <SelectInput
            source="isDowngrade"
            choices={[
              {id: true, name: 'Downgrader vers Nirio Go'},
              {id: false, name: 'Fermer le compte'},
            ]}
            label="Downgrader à une offre GO ?"
            parse={(value: string): string | null =>
              value === '' ? null : value
            }
            defaultValue={null}
            validate={[required()]}
            fullWidth
          />

          <Alert severity="error" sx={{mb: '0.5em', fontSize: '1.5em'}}>
            Appuyez sur le bouton “Enregistrer” en haut de la page pour valider
            de manière <strong>irréversible</strong> votre demande de clôture du
            compte Premio du client.
          </Alert>
        </NirioCreate>
      </MainPaperContainer>
    </PermissionGuard>
  );
};
