import {Box, Stack, Typography} from '@mui/material';
import {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

import {Icons} from './Icon';

export type BreadCrumbHeaderProps = {
  actions?: ReactNode[];
  backTo?: string;
  defaultTitle?: string;
  resource?: string;
  title?: string;
};

export const BreadCrumbHeader = (props: BreadCrumbHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const goBack = (): void => {
    if (props.backTo) {
      navigate(props.backTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <Stack direction="row" alignItems="center" minHeight={56} spacing={2}>
      <Stack
        alignItems="center"
        component="a"
        direction="row"
        onClick={goBack}
        spacing={2}
        sx={{
          cursor: 'pointer',
        }}>
        <Icons.ChevronLeft size={20} />
        <Typography
          color="secondary"
          sx={{'&:first-letter': {textTransform: 'uppercase'}}}
          variant="h1">
          {props.title ?? props.defaultTitle}
        </Typography>
      </Stack>
      <Box flex={1} />
      {props.actions}
    </Stack>
  );
};
