import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {FC, ReactElement} from 'react';
import {Link, useListController} from 'react-admin';

import {Icons} from './Icon';

const StyledAppBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '60px 0 0 0',
  minHeight: '56px',
  width: '100%',
  marginBottom: '44px',
});

const StyledActionsContainer = styled('div')({
  display: 'flex',
  gap: '12px',
});

const ShowTitleContainer = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  textDecoration: 'none',
});

export interface ListTopBarProps {
  actions?: false | ReactElement[];
  backTo?: string;
}

const TitleComponent: FC<{title: string | undefined; backTo?: string}> = ({
  title,
  backTo,
}) =>
  backTo ? (
    <ShowTitleContainer to={backTo}>
      <Icons.ChevronLeft size={20} />
      <Typography variant="h1" color="secondary">
        {title}
      </Typography>
    </ShowTitleContainer>
  ) : (
    <Typography variant="h1" color="secondary">
      {title}
    </Typography>
  );

export const ListTopBar: FC<ListTopBarProps> = ({
  actions,
  backTo,
}): ReactElement => {
  const {defaultTitle} = useListController();

  return (
    <StyledAppBar>
      <TitleComponent title={defaultTitle} backTo={backTo} />
      {actions && <StyledActionsContainer>{actions}</StyledActionsContainer>}
    </StyledAppBar>
  );
};
