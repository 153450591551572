import {FC} from 'react';
import {
  AutocompleteArrayInput as DefaultRaAutocompleteArrayInput,
  AutocompleteArrayInputProps,
} from 'react-admin';

const styleReadOnly = (
  readOnly: boolean,
):
  | false
  | Pick<
      AutocompleteArrayInputProps,
      'forcePopupIcon' | 'classes' | 'className' | 'sx'
    > =>
  readOnly && {
    forcePopupIcon: false,
    classes: {
      tag: 'Mui-disabled',
    },
    className: 'disable-text-cursor',
    sx: {'&.disable-text-cursor *': {cursor: 'default'}},
  };

export const AutocompleteArrayInput: FC<AutocompleteArrayInputProps> = ({
  disableCloseOnSelect = true,
  blurOnSelect = false,
  ...props
}) => (
  <DefaultRaAutocompleteArrayInput
    {...props}
    disableCloseOnSelect={disableCloseOnSelect}
    blurOnSelect={blurOnSelect}
    {...styleReadOnly(!!props.readOnly)}
  />
);
