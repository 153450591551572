import ClockCircleIcon from '@mui/icons-material/AccessTimeOutlined';
import ErrorCircleIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {Backdrop, Box, CircularProgress, Modal, Stack} from '@mui/material';
import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import authProvider from '../../api/providers/auth-provider';
import StyledErrorModal from '../../theme/StyledErrorModal';

function useToken(): string | null {
  const {search} = useLocation();
  return React.useMemo(
    () => new URLSearchParams(search).get('token'),
    [search],
  );
}

const MagicLinkVerifyToken = (): JSX.Element => {
  const [error, setError] = React.useState<Error>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const token = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      authProvider
        .login(token)
        .then(() => {
          navigate('/admin');
        })
        .catch((e: Error) => setError(e))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, token]);

  if (error) {
    const view =
      error.name === 'TOKEN_EXPIRED'
        ? {
            Icon: ClockCircleIcon,
            title: "Ce lien n'est plus valide",
            description: "La date d'expiration de ce lien est dépassée",
          }
        : {
            Icon: ErrorCircleIcon,
            title: 'Ce lien est invalide',
            description: "Nous n'avons pas pu vérifier votre lien de connexion",
          };

    return (
      <Modal open={true}>
        <StyledErrorModal>
          <Stack direction="row" alignItems="center">
            <view.Icon
              sx={{
                color: '#DE3D4C',
                margin: '0px 8px',
              }}
            />
            {view.title}
          </Stack>
          <Box sx={{mt: 2}}>{view.description}</Box>
        </StyledErrorModal>
      </Modal>
    );
  }
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: (theme): number => theme.zIndex.drawer + 1}}
      open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default MagicLinkVerifyToken;
