import {FC, useEffect, useState} from 'react';
import {
  Admin as ReactAdminComponent,
  CustomRoutes,
  DataProvider,
  Resource,
} from 'react-admin';
import {Route} from 'react-router-dom';

import {OnOff, PermissionDto} from '../../api/generated';
import authProvider from '../../api/providers/auth-provider';
import graphQlDataProvider from '../../api/providers/data-provider';
import {i18nProvider} from '../../api/providers/i18n-provider';
import {basename} from '../../constants';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {hasPermission} from '../../hooks/usePermissions';
import {
  AppFaqContentEditCategories,
  AppFaqContentEditPosts,
  AppFaqContentGrid,
} from '../../ressources/app-faq-content';
import {CessationRaWorkflowCreate} from '../../ressources/cessationRaWorkflow/CessationRaWorkflowCreate';
import {maintenanceSettingsResourceName} from '../../ressources/maintenance/constants';
import {MaintenanceEdit} from '../../ressources/maintenance/MaintenanceEdit';
import {PromotionHome} from '../../ressources/promotion/PromotionHome';
import {nirioTheme} from '../../theme/theme';
import MagicLinkLoginRequest from '../MagicLinkLoginRequest';
import {NeptuneRouter} from '../Neptune/NeptuneRouter';
import {HomePage} from './HomePage';
import {CustomLayout} from './Layout';
import {resources} from './resources';

const Admin: FC = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    // useEffect collides with no-floating-promises: https://github.com/typescript-eslint/typescript-eslint/issues/1184
    void graphQlDataProvider.then(dataProvider =>
      setDataProvider(() => dataProvider),
    );
  }, []);

  if (!dataProvider) {
    return <div>Loading...</div>;
  }

  return (
    <ReactAdminComponent
      authProvider={authProvider}
      basename={basename}
      dataProvider={dataProvider}
      dashboard={HomePage}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      loginPage={MagicLinkLoginRequest}
      requireAuth
      theme={nirioTheme}>
      {(permissions: PermissionDto[] = []): JSX.Element => (
        <>
          <CustomRoutes>
            {hasPermission(permissions, PermissionDto.AppContentWrite) && (
              <>
                <Route path="AppFaqContent" element={<AppFaqContentGrid />} />
                <Route
                  path="AppFaqContent/edit-posts"
                  element={<AppFaqContentEditPosts />}
                />
                <Route
                  path="AppFaqContent/edit-categories"
                  element={<AppFaqContentEditCategories />}
                />
              </>
            )}

            {hasPermission(permissions, PermissionDto.PromotionRead) && (
              <Route path="AllPromotions/" element={<PromotionHome />} />
            )}

            {hasPermission(permissions, PermissionDto.MaintenanceWrite) && (
              <Route
                path={`${maintenanceSettingsResourceName}/edit`}
                element={<MaintenanceEdit />}
              />
            )}

            {featureFlags.neptuneSimulator === OnOff.On &&
              hasPermission(permissions, PermissionDto.NeptuneRead) && (
                <Route path="Neptune/*" element={<NeptuneRouter />} />
              )}

            <Route
              path="Client/:userId/CessationRaWorkflow/create"
              element={<CessationRaWorkflowCreate />}
            />
          </CustomRoutes>

          {resources.map(resource => (
            <Resource key={resource.name} {...resource} />
          ))}
        </>
      )}
    </ReactAdminComponent>
  );
};

export default Admin;
