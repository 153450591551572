// Object.keys returns a string[]. This is by design as described in this issue (https://github.com/Microsoft/TypeScript/issues/12870)
// One way is to assert explicitly `Object.keys(obj) as Array<keyof typeof obj>`
// Or implicitly in a function like here:
export const getKeys = Object.keys as <T extends object>(
  obj: T,
) => Array<keyof T>;

/**
 * Check that a string variable is one of the enum's values
 * @param value String variable which needs to be checked
 * @param enumVariable Enum type to validate against
 * @returns boolean and type the value
 */
export const isValueOfEnum = <T extends string, TEnumValue extends string>(
  value: string,
  enumVariable: {[key in T]: TEnumValue},
): value is TEnumValue => {
  return Object.values(enumVariable).includes(value);
};

/**
 * Home implementation of lodash.omit(collection, arr)
 */
export function omit<T extends K, V, K extends string>(
  filterValues: {[key in K]: V},
  keys: T[],
): {[key in K]: V} {
  const out = {...filterValues};
  keys.forEach(keyName => {
    delete out[keyName];
  });
  return out;
}

/**
 * Home implementation of lodash.mapValues(collection, transformer)
 */
export function mapValues<T, V, K extends string>(
  collection: {[key in K]: V},
  transformer: (val: V, key: K) => T,
): {[key in K]: T} {
  // @ts-expect-error: better to put something ugly in a controlled utils than peppered through the code
  const l: {[key in K]: T} = {};
  getKeys(collection).forEach(keyName => {
    l[keyName] = transformer(collection[keyName], keyName);
  });
  return l;
}

/**
 * Home implementation of lodash.pick(collection, arr)
 */
export function pick<T extends string, E>(
  collection: Record<string, E>,
  keys: T[],
): {
  [key in T]: E;
} {
  return keys.reduce(
    (acc, val) => {
      if (collection[val] !== undefined) {
        acc[val] = collection[val];
      }
      return acc;
    },
    {} as {
      [key in T]: E;
    },
  );
}
