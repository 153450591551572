import {merge} from 'lodash';
import {ImageInput, ImageInputProps} from 'react-admin';
interface CustomImageInputProps extends ImageInputProps {
  isUploading: number | undefined;
}

const staticStyle = {
  border: '1px solid #E8E8E8',
  borderRadius: '8px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  flexRow: 'wrap',
  height: 'max(90px, fit-content)',

  '& .previews': {
    flex: 1,
  },

  '& .RaFileInput-removeButton': {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    height: 90,

    button: {
      opacity: 1,
      top: 0,
      right: 0,
      position: 'relative',
      transform: 'translate3D(36px,-2px,0)',
    },
  },

  '& .RaFileInput-dropZone': {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 1,
  },
  '& .MuiTypography-body1': {
    display: 'none',
  },
};

export const CustomImageInput: React.FC<CustomImageInputProps> = props => {
  const conditionalStyle = {
    '& .RaFileInput-dropZone': {
      flex: props.isUploading ? 0 : 1,
      webkitFlex: props.isUploading ? 0 : 1,
      transform: props.isUploading ? 'translate3D(-30px, 0, 10px)' : 'none',
    },
    '& .previews': {
      display: props.isUploading ? 'flex' : 'none',
    },
  };

  const sx = merge(staticStyle, conditionalStyle);
  return <ImageInput {...props} sx={sx} />;
};
