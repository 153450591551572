import {Typography} from '@mui/material';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  SingleFieldList,
  TextField,
} from 'react-admin';

import {
  CartItem,
  PaymentWorkflow,
  PaymentWorkflowStatus,
  PermissionDto,
} from '../../api/generated';
import {CustomShow} from '../../components/CustomShow';
import {ExternalLink} from '../../components/ExternalLink';
import {LabeledArrayField} from '../../components/Fields/LabeledArrayField';
import {LabeledFunctionField} from '../../components/Fields/LabeledFunctionField';
import {LabeledTextField} from '../../components/Fields/LabeledTextField';
import {PaidAmountTooltip} from '../../components/PaidAmountTooltip';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';

const paymentFields = [
  <Labeled key="createdAt">
    <DateField locales="fr-FR" showTime={true} source="createdAt" />
  </Labeled>,
  <FunctionField
    source="cart.paidAt"
    key="cart.paidAt"
    render={(record: PaymentWorkflow): JSX.Element | null => {
      if (record.status === PaymentWorkflowStatus.Confirmed) {
        return (
          // The default unit it 'em' but we want 'rem' here, because  'em' is relative to the font-size of the parent element
          // and we want the font-size to be relative to the font-size of the root element, as everywhere else in the app.
          <Labeled sx={{'& .RaLabeled-label': {fontSize: '0.75rem'}}}>
            <DateField locales="fr-FR" showTime={true} source="cart.paidAt" />
          </Labeled>
        );
      }
      return null;
    }}
  />,
  <LabeledTextField source="id" key="id" />,

  <LabeledArrayField
    source="cart.items"
    label="Références CBS"
    key="cbsTransactionId">
    <SingleFieldList
      linkType={false}
      sx={{flexDirection: 'column', rowGap: '0.5rem'}}>
      <TextField source="cbsTransactionId" />
    </SingleFieldList>
  </LabeledArrayField>,

  <LabeledFunctionField
    key="cart.id"
    source="cart.id"
    render={(record: PaymentWorkflow): JSX.Element => (
      <PermissionLink
        permission={PermissionDto.CartRead}
        to={`/admin/Cart/${record.cart.id}/show`}>
        {record.cart.id}
      </PermissionLink>
    )}
  />,
  <LabeledFunctionField
    key="amountInCents"
    label={<PaidAmountTooltip />}
    sortable={false}
    source="amountInCents"
    render={(record: PaymentWorkflow): string => {
      return formatMaybeAmountInCents(Number(record.amountInCents));
    }}
  />,
  <LabeledTextField key="paymentMethod" source="paymentMethod" />,
  <LabeledFunctionField
    key="status"
    source="status"
    render={(record: PaymentWorkflow): React.ReactElement => {
      switch (record.status) {
        case PaymentWorkflowStatus.Blocked:
          return (
            <>
              <Typography variant="body2">Bloquée</Typography>
              {record.error && (
                <Typography
                  variant="body2"
                  style={{color: 'red', fontStyle: 'italic'}}>
                  {record.error}
                </Typography>
              )}
            </>
          );
        case PaymentWorkflowStatus.Confirmed:
          return <Typography variant="body1">Confirmée</Typography>;
        case PaymentWorkflowStatus.Pending:
          return <Typography variant="body1">En attente</Typography>;
        case PaymentWorkflowStatus.Closed:
          return <Typography variant="body1">Fermée</Typography>;
        default:
          return <Typography variant="body1">Inconnu</Typography>;
      }
    }}
  />,
  <LabeledTextField
    key="payer.profile"
    sortable={false}
    source="cart.client.profile"
  />,
];
const payerFields = [
  <LabeledFunctionField
    key="cart.payerId"
    source="cart.payerId"
    render={(record: PaymentWorkflow): JSX.Element => (
      <PermissionLink
        permission={PermissionDto.PayerRead}
        to={`/admin/Client/${record.cart.payerId}/show`}>
        {record.cart.payerId}
      </PermissionLink>
    )}
  />,

  <LabeledFunctionField
    key="cbsClientId"
    source="cbsClientId"
    render={(record: PaymentWorkflow): JSX.Element => {
      return (
        <ExternalLink href={record.cart.client.cbsProfileUrl ?? undefined}>
          {record.cart.client.cbsClientId ?? 'Ø'}
        </ExternalLink>
      );
    }}
  />,
];

const productFields = (
  <ArrayField source="cart.items">
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="payable.product.name"
        label="Libellé produit"
        render={(record: CartItem): JSX.Element => (
          <>
            {!!record.payable.product && (
              <PermissionLink
                permission={PermissionDto.ProductRead}
                to={`/admin/Product/${record.payable.product.id}/show`}>
                {record.payable.product.name}
              </PermissionLink>
            )}
          </>
        )}
      />

      <TextField
        source="payable.product.category.label"
        label="Catégorie produit"
      />

      <FunctionField
        source="payable.product.partner.name"
        label="Libellé partenaire"
        render={(record: CartItem): JSX.Element => (
          <>
            {!!record.payable.product && (
              <PermissionLink
                permission={PermissionDto.PartnerRead}
                to={`/admin/Partner/${record.payable.product.partner.id}/show`}>
                {record.payable.product.partner.name}
              </PermissionLink>
            )}
          </>
        )}
      />
    </Datagrid>
  </ArrayField>
);
const retailerFields = [
  <LabeledFunctionField
    key="retailer.rdiRoleCode"
    source="retailer.rdiRoleCode"
    render={(record: PaymentWorkflow): JSX.Element => (
      <PermissionLink
        permission={PermissionDto.RetailerRead}
        to={`/admin/Retailer/${record.retailer.id}/show`}>
        {record.retailer.rdiRoleCode}
      </PermissionLink>
    )}
  />,
  <LabeledFunctionField
    key="retailer.name"
    source="retailer.name"
    render={(record: PaymentWorkflow): JSX.Element => (
      <PermissionLink
        permission={PermissionDto.RetailerRead}
        to={`/admin/Retailer/${record.retailer.id}/show`}>
        {record.retailer.name}
      </PermissionLink>
    )}
  />,
  <LabeledTextField key="retailer.zipCode" source="retailer.zipCode" />,
  <LabeledTextField
    key="retailer.departmentCode"
    source="retailer.departmentCode"
  />,
];

const data = [
  {
    title: 'Opération',
    fields: paymentFields,
  },
  {
    title: 'Client',
    fields: payerFields,
  },
  {
    title: 'Produits',
    fields: productFields,
  },
  {
    title: 'Détaillant',
    fields: retailerFields,
  },
];
export const OperationShow = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.PaymentWorkflowRead}>
    <CustomShow
      topPapers={data.map(item => ({
        title: item.title,
        style: {
          flex: '1 0 40%',
        },
        content: item.fields,
      }))}
    />
  </PermissionGuard>
);
