import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {CollectionType} from '../../../dto/service-requests.dto';

interface ComponentProps {
  uuid: string;
  externalId: string;
  datamatrixContent?: string;
  partnerName: string;
  isFinancial: boolean;
  collectionType: CollectionType;
  collectionTypeName: string;
}

const TechnicalDetails: React.FC<ComponentProps> = ({
  uuid,
  externalId,
  datamatrixContent,
  partnerName,
  isFinancial,
  collectionType,
  collectionTypeName,
}): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>UUID</TableCell>
            <TableCell>External ID</TableCell>
            {datamatrixContent !== undefined && (
              <TableCell>Contenu de la datamatrix</TableCell>
            )}
            <TableCell>Nom du partenaire</TableCell>
            <TableCell>Est financier</TableCell>
            <TableCell>Type d&apos;encaissement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{uuid}</TableCell>
            <TableCell>{externalId}</TableCell>
            {datamatrixContent !== undefined && (
              <TableCell>{datamatrixContent}</TableCell>
            )}
            <TableCell>{partnerName}</TableCell>
            <TableCell>{isFinancial ? 'Oui' : 'Non'}</TableCell>
            <TableCell>
              {collectionTypeName} ({collectionType})
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TechnicalDetails;
