import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const promotionProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_LIST' || fetchType === 'GET_MANY_REFERENCE') {
    return {
      ...builtQuery,
      query: gql`
        query allNirioBankWelcomePromotions(
          $page: Int!
          $perPage: Int!
          $sortField: String!
          $sortOrder: String!
          $filter: NirioBankWelcomePromotionFilterDto!
        ) {
          items: allNirioBankWelcomePromotions(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            id
            name
            isActive
            dateStart
            dateEnd
            gainAmountInCents
            freeMonthsCount
            createdAt
            type
            offers
          }
          total: _allNirioBankWelcomePromotionsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
          ) {
            count
          }
        }
      `,
    };
  }

  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      query: gql`
        query NirioBankWelcomePromotion($id: String!) {
          data: NirioBankWelcomePromotion(id: $id) {
            id
            name
            isActive
            dateStart
            dateEnd
            type
            gainAmountInCents
            freeMonthsCount
            createdAt
            offers
          }
        }
      `,
    };
  }
  return builtQuery;
};
