import {Stack} from '@mui/material';
import {FC} from 'react';

export interface FilterSpacerProps {
  alwaysOn?: boolean;
  // The List component create a wrapper over a filter and feeds that wrapper a `key` with value {source}
  // therefore if we use this component without a source it will have no key and raise an error
  source?: string;
}

export const FilterSpacer: FC<FilterSpacerProps> = () => (
  <Stack className="filter-spacer" flexGrow={1} />
);
