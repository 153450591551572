import {Paper, styled, Typography} from '@mui/material';
import {
  EditBase,
  RaRecord,
  ShowButton,
  useEditController,
  useNotify,
} from 'react-admin';

import {MutationUpdatePartnerArgs, PermissionDto} from '../../api/generated';
import {StyledForm} from '../../components/Form/StyledForm';
import {PermissionGuard} from '../../components/PermissionGuard';
import {safeConvertFileToBase64} from '../../utils/base64.utils';
import {stripTypename} from '../../utils/strip-typename';
import {PartnerForm} from './PartnerForm';
import PartnerProductsIterator from './PartnerProductsIterator';

const PageLayout = styled('div')({
  padding: '0 42px 0 42px',
  height: '100vh',
  overflow: 'auto',
});

const StyledPaper = styled(Paper)({
  backgroundColor: 'white',
  padding: '32px',
  flex: '1',
});

const PaperTitle = styled(Typography)({
  marginBottom: '24px',
});

export const PartnerEdit = (): JSX.Element => {
  const editController = useEditController<RaRecord>();

  const title = editController.record?.name as string;
  const notify = useNotify();

  const onError = (): void =>
    notify('ra.notification.partnerEditError', {type: 'error'});

  return (
    <PermissionGuard permission={PermissionDto.PartnerWrite}>
      <PageLayout>
        <EditBase
          mutationOptions={{onError}}
          actions={false}
          transform={async (
            data: MutationUpdatePartnerArgs & {
              logo?: {rawFile: File} | null;
            },
          ): Promise<MutationUpdatePartnerArgs> => ({
            ...stripTypename(data),
            partnerAuth:
              data.partnerAuth?.password && data.partnerAuth?.username
                ? {
                    username: data.partnerAuth.username,
                    password: data.partnerAuth.password,
                  }
                : undefined,
            logo:
              typeof data.logo == 'string'
                ? undefined
                : await safeConvertFileToBase64(data.logo),
            displayOrderInApp: data.displayOrderInApp ?? 0,
            isDisplayed: !!data.isDisplayed,
          })}>
          <StyledForm>
            <PartnerForm
              title={`Partenaire ${title}`}
              NavigationButton={<ShowButton label="Annuler" icon={<div />} />}
            />
            <StyledPaper>
              <PaperTitle variant="h1" color="secondary">
                Produits
              </PaperTitle>
              <PartnerProductsIterator />
            </StyledPaper>
          </StyledForm>
        </EditBase>
      </PageLayout>
    </PermissionGuard>
  );
};
