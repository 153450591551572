import {Action, ActionTypes, SimulationMode, State} from './reducer.types';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SetSimulationMode:
      return {
        ...state,
        simMode: action.payload.simulationMode,
      };
    case ActionTypes.SetRetailerRoleCode:
      return {
        ...state,
        retailerRoleCode: action.payload.retailerRoleCode,
      };
    case ActionTypes.SetCode:
      return {
        ...state,
        code: action.payload.code,
      };
    case ActionTypes.SetLoading:
      return {
        ...state,
        isLoading: action.payload.loading,
      };
    case ActionTypes.SetResult:
      return {
        ...state,
        result: action.payload.result,
      };
    case ActionTypes.SetError:
      return {
        ...state,
        error: action.payload.error,
        alert: true,
      };
    case ActionTypes.Reset:
      return {
        isLoading: false,
        error: null,
        result: null,
        code: '',
        jsonMode: false,
        urlParams: null,
        simMode: SimulationMode.Scan,
        retailerRoleCode: '',
        locked: false,
        alert: false,
        operatorId: '',
      };
    case ActionTypes.SetJSONMode:
      return {
        ...state,
        jsonMode: action.payload.isJson,
      };
    case ActionTypes.SetUrlParams:
      return {
        ...state,
        urlParams: action.payload.urlParms,
      };
    case ActionTypes.SetLocked:
      return {
        ...state,
        locked: action.payload.locked,
      };
    case ActionTypes.SetAlert:
      return {
        ...state,
        alert: action.payload.alertOpen,
      };
    case ActionTypes.SetOperatorId:
      return {
        ...state,
        operatorId: action.payload.operatorId,
      };
    default:
      return state;
  }
};
