import {OutlinedInput} from '@mui/material';
import styled from '@mui/material/styles/styled';
import {
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  Pagination,
  ReferenceManyField,
  TextField,
  WithRecord,
} from 'react-admin';

import {
  CartItem,
  Payable,
  PaymentWorkflow,
  PaymentWorkflowStatus,
  PermissionDto,
} from '../../api/generated';
import {CustomShow} from '../../components/CustomShow';
import {DataMatrix} from '../../components/DataMatrix';
import {SingleFieldListWithEmptyText} from '../../components/Fields/SingleFieldListWithEmptyText';
import {PaidAmountTooltip} from '../../components/PaidAmountTooltip';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {palette} from '../../theme/palette';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {
  calculatePayableRemainingAmountAtTimeOfPaymentConfirmation,
  calculatePaymentStatus,
  fetchPaidAmount,
  ParentPayableRecordProvider,
} from './helpers';

const StyledLabeled = styled(Labeled)({
  '& .RaLabeled-label': {
    color: palette.grey[500],
    lineHeight: '1',
    fontFamily: 'Inter',
    fontSize: '12px',
  },
});

const data = [
  {
    title: 'Facture',
    fields: [
      <FunctionField
        source="partnerPayableId"
        key="partnerPayableId"
        render={(record: Payable): JSX.Element => (
          <OutlinedInput
            value={record.partnerPayableId}
            readOnly
            size="small"
            sx={{fontSize: '12px', width: '100%'}}
            inputProps={{
              style: {
                padding: '2px',
              },
            }}
          />
        )}
      />,
      <TextField source="id" key="id" />,
      <DateField
        source="createdAt"
        showTime={true}
        key="createdAt"
        locales="fr-FR"
      />,
      <SingleFieldListWithEmptyText
        source="items"
        label="ID panier"
        emptyText="Cette facture n'est associée à aucun panier."
        key="items">
        <FunctionField
          source="cartId"
          render={(record: CartItem): JSX.Element => (
            <PermissionLink
              permission={PermissionDto.CartRead}
              to={`/admin/Cart/${record.cartId}/show`}>
              {record.cartId}
            </PermissionLink>
          )}
        />
      </SingleFieldListWithEmptyText>,
      <TextField source="currency" key="currency" />,
      <FunctionField
        source="initialAmountInCents"
        render={(record: Payable): string =>
          formatMaybeAmountInCents(record.initialAmountInCents)
        }
        key="initialAmountInCents"
      />,
      <FunctionField
        sortable={false}
        source="paymentStatus"
        render={calculatePaymentStatus}
        key="paymentStatus"
      />,
      <DateField
        source="expiresAt"
        showTime={true}
        emptyText="Ø"
        key="expiresAt"
      />,
      <TextField source="datamatrixContent" key="datamatrixContent" />,
      <WithRecord
        render={(record: Payable): JSX.Element =>
          record.datamatrixContent ? (
            <DataMatrix
              data={record.datamatrixContent}
              sx={{width: 150, height: 150}}
            />
          ) : (
            <></>
          )
        }
        key="datamatrix"
      />,
    ],
  },
  {
    title: 'Partenaire et produit',
    fields: [
      <TextField
        source="product.datamatrixProductId"
        key="product.datamatrixProductId"
      />,
      <FunctionField
        source="product.name"
        render={(record: Payable): JSX.Element => (
          <>
            {!!record.productId && !!record.product && (
              <PermissionLink
                permission={PermissionDto.ProductRead}
                to={`/admin/Product/${record.productId}/show`}>
                {record.product.name}
              </PermissionLink>
            )}
          </>
        )}
        key="product.name"
      />,
      <TextField
        source="product.category.label"
        key="product.category.label"
      />,
      <TextField
        source="product.partner.datamatrixPartnerId"
        key="product.partner.datamatrixPartnerId"
      />,
      <FunctionField
        source="product.partner.name"
        render={(record: Payable): JSX.Element => (
          <>
            {!!record.product && (
              <PermissionLink
                permission={PermissionDto.PartnerRead}
                to={`/admin/Partner/${record.product.partnerId}/show`}>
                {record.product.partner.name}
              </PermissionLink>
            )}
          </>
        )}
        key="product.partner.name"
      />,
    ],
  },
  {
    title: 'Client',
    fields: [
      <FunctionField
        source="payerId"
        render={(record: Payable): JSX.Element => (
          <PermissionLink
            permission={PermissionDto.PayerRead}
            to={`/admin/Client/${record.payerId}/show`}>
            {record.payerId}
          </PermissionLink>
        )}
        key="payerId"
      />,
      <TextField
        source="partnerCustomerId"
        emptyText="Ø"
        key="partnerCustomerId"
      />,
    ],
  },
];

const paymentsGrid = (
  <ReferenceManyField
    label="Opérations"
    pagination={<Pagination />}
    perPage={10}
    reference="Operation"
    target="payableId"
    sort={{field: 'cart.paidAt', order: 'DESC'}}>
    <Datagrid bulkActionButtons={false}>
      <DateField source="cart.paidAt" showTime />
      <FunctionField
        source="id"
        render={(record: PaymentWorkflow): JSX.Element => (
          <PermissionLink
            permission={PermissionDto.PaymentWorkflowRead}
            to={`/admin/Operation/${record.id}/show`}>
            {record.id}
          </PermissionLink>
        )}
      />
      <FunctionField
        source="retailer.rdiRoleCode"
        render={(record: PaymentWorkflow): JSX.Element => (
          <PermissionLink
            permission={PermissionDto.PaymentWorkflowRead}
            to={`/admin/retailer/${record.retailer?.id}/show`}>
            {record.retailer.rdiRoleCode}
          </PermissionLink>
        )}
      />
      <FunctionField
        source="status"
        render={(record: PaymentWorkflow): string => {
          switch (record.status) {
            case PaymentWorkflowStatus.Blocked:
              return 'Bloquée';
            case PaymentWorkflowStatus.Confirmed:
              return 'Confirmée';
            case PaymentWorkflowStatus.Pending:
              return 'En attente';
            case PaymentWorkflowStatus.Closed:
              return 'Fermée';
            default:
              return 'Inconnue';
          }
        }}
      />
      <ParentPayableRecordProvider label={<PaidAmountTooltip />}>
        <FunctionField render={fetchPaidAmount} />
      </ParentPayableRecordProvider>

      <ParentPayableRecordProvider label="Montant restant (en €)">
        <FunctionField
          render={calculatePayableRemainingAmountAtTimeOfPaymentConfirmation}
        />
      </ParentPayableRecordProvider>
    </Datagrid>
  </ReferenceManyField>
);

export const PayableShow = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.PayableRead}>
    <CustomShow
      topPapers={data.map(item => ({
        title: item.title,
        content: item.fields.map((field, index) => (
          <StyledLabeled key={index}>{field}</StyledLabeled>
        )),
      }))}
      mainPaperTitle="Opérations"
      mainPaper={[
        {
          content: paymentsGrid,
        },
      ]}
    />
  </PermissionGuard>
);
