import {ReactElement} from 'react';
import {
  RecordContextProvider,
  useRecordContext,
  useShowContext,
} from 'react-admin';

import {
  Payable,
  PaymentWorkflow,
  PaymentWorkflowStatus,
} from '../../api/generated';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';

export function calculatePaymentStatus(
  record: Payable,
): 'À régler' | 'Réglée' | 'Partiellement réglée' {
  if (record.remainingAmountInCents === record.initialAmountInCents) {
    return 'À régler';
  } else if (record.remainingAmountInCents === 0) {
    return 'Réglée';
  } else {
    return 'Partiellement réglée';
  }
}

export function fetchPaidAmount({
  payableRecord,
  paymentWorkflowRecord,
}: {
  payableRecord: Payable;
  paymentWorkflowRecord: PaymentWorkflow;
}): string {
  const cartItem = paymentWorkflowRecord.cart.items.find(
    item => item.payable.id === payableRecord.id,
  );

  return cartItem &&
    paymentWorkflowRecord.status === PaymentWorkflowStatus.Confirmed
    ? formatMaybeAmountInCents(
        cartItem.amountInCents + cartItem.serviceFeeInCents,
      )
    : 'Opération non confirmée.';
}

export function calculatePayableRemainingAmountAtTimeOfPaymentConfirmation({
  payableRecord,
  paymentWorkflowRecord,
}: {
  payableRecord: Payable;
  paymentWorkflowRecord: PaymentWorkflow;
}): string {
  if (paymentWorkflowRecord.status === PaymentWorkflowStatus.Confirmed) {
    const allPaidBeforeAndCurrentCartItems = payableRecord.items?.filter(
      item =>
        item.cart.paidAt &&
        item.cart.paidAt <= paymentWorkflowRecord.cart.paidAt,
    );
    return formatMaybeAmountInCents(
      allPaidBeforeAndCurrentCartItems?.reduce(
        (acc, curr) => acc - curr.amountInCents,
        payableRecord.initialAmountInCents,
      ),
    );
  }
  return 'Opération non confirmée.';
}

export const ParentPayableRecordProvider = ({
  children,
}: {
  children: JSX.Element;
  label: string | ReactElement | boolean;
}): JSX.Element => {
  const {record: payableRecord} = useShowContext<Payable>();
  const paymentWorkflowRecord = useRecordContext<PaymentWorkflow>();

  const record = {payableRecord, paymentWorkflowRecord};

  return payableRecord && paymentWorkflowRecord ? (
    <RecordContextProvider value={record}>{children}</RecordContextProvider>
  ) : (
    <></>
  );
};
