import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import {PermissionDto} from '../../api/generated';
import {ColoredChipField} from '../../components/Fields/ColoredChipField';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {usePermissions} from '../../hooks/usePermissions';

export const AdminList = (): JSX.Element => {
  const {can} = usePermissions();

  const actions = [
    <ExportButton key="export" icon={<Icons.ArrowDown size={17} />} />,
  ];

  if (can(PermissionDto.AdminWrite)) {
    actions.push(
      <CreateButton
        variant="contained"
        key="create"
        icon={<Icons.Add isActive size={17} />}
      />,
    );
  }

  const filters = [
    <TextInput
      key="emailPart"
      source="emailPart"
      label="Rechercher par email"
      resettable
      alwaysOn
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <FilterSpacer key="spacer" source="spacer" alwaysOn />,
    <FilterResetButton key="reset" source="reset" alwaysOn />,
  ];

  return (
    <PermissionGuard permission={PermissionDto.AdminRead}>
      <List actions={<ListTopBar actions={actions} />} filters={filters}>
        <Datagrid hover={false} rowClick="show" bulkActionButtons={false}>
          <TextField source="lastName" />
          <TextField source="firstName" />
          <TextField source="email" />
          <ColoredChipField source="group" size="small" />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
