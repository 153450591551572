import {Card, CardContent, CardHeader, Grid} from '@mui/material';
import {SimpleShowLayout, TextField} from 'react-admin';

import {PermissionDto} from '../../api/generated';
import {ColoredChipField} from '../../components/Fields/ColoredChipField';
import {NirioShow} from '../../components/NirioShow';
import {PermissionGuard} from '../../components/PermissionGuard';
import {usePermissions} from '../../hooks/usePermissions';

export const AdminShow = (): JSX.Element => {
  const {can} = usePermissions();

  return (
    <PermissionGuard permission={PermissionDto.AdminRead}>
      <NirioShow
        canEdit={can(PermissionDto.AdminWrite)}
        canDelete={can(PermissionDto.AdminDelete)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card sx={{flex: 1}}>
              <CardHeader title="Compte" />
              <CardContent>
                <SimpleShowLayout>
                  <TextField source="firstName" />
                  <TextField source="lastName" />
                  <TextField source="email" />
                </SimpleShowLayout>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{flex: 1}}>
              <CardHeader title="Paramètres" />
              <CardContent>
                <SimpleShowLayout>
                  <ColoredChipField source="group" size="small" />
                </SimpleShowLayout>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </NirioShow>
    </PermissionGuard>
  );
};
