import HelpIcon from '@mui/icons-material/HelpOutline';
import {SvgIconProps} from '@mui/material/SvgIcon';
import Tooltip, {TooltipProps} from '@mui/material/Tooltip';

export const LabelWithTooltip = ({
  text,
  title,
  tooltipProps,
  helpIconProps,
}: {
  text: string;
  title: string;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  helpIconProps?: SvgIconProps;
}): JSX.Element => (
  <>
    <span>{text} </span>
    <Tooltip placement="top" title={title} {...tooltipProps}>
      <span>
        <HelpIcon sx={{fontSize: '2ex'}} {...helpIconProps} />
      </span>
    </Tooltip>
  </>
);
