import Typography from '@mui/material/Typography/Typography';
import {FC} from 'react';
import {FileRejection} from 'react-dropzone';

import {ImageUploader} from '../../components/Form/Inputs/ImageInput/ImageUploader';

const caption = (
  <Typography variant="caption">
    Le fichier ne doit pas dépasser 50 ko. Les formats acceptés sont : PNG,
    JPEG.
  </Typography>
);

const parseFileRejections = (fileRejections: FileRejection[]): string => {
  if (fileRejections.length === 0) {
    return '';
  }
  for (const error of fileRejections[0].errors) {
    if (error.code === 'file-too-large') {
      return 'Le fichier ne doit pas dépasser 50 ko.';
    }
    if (error.code === 'file-invalid-type') {
      return 'Seuls sont pris en charge les formats PNG et JPEG.';
    }
  }
  return '';
};

export const LogoUploadInput: FC = () => (
  <ImageUploader
    caption={caption}
    source="logo"
    accept="image/png, image/jpeg"
    maxSize={50_000}
    parseFileRejectionsOnDropRejected={parseFileRejections}
  />
);
