import {FormControlLabel, Radio, RadioGroup, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {AxiosError} from 'axios';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {OnOff} from '../../../../api/generated';
import {useFeatureFlags} from '../../../../hooks/useFeatureFlags';
import {formatMaybeAmountInCents} from '../../../../utils/format-maybe-amount-in-cents';
import {confirmPayment} from '../../api/confirmPayment';
import {PaymentMethod} from '../../context/reducer.types';
import {PaymentDto} from '../../dto/service-requests.dto';

interface ComponentProps {
  paymentInformationsWaitingConfirmation: PaymentDto[];
  serviceRequestId: string;
  onSuccess: (value: unknown) => void;
  onError: (err: AxiosError | Error) => void;
  onSubmitted: () => void;
}
const PaymentsTable: React.FC<ComponentProps> = ({
  paymentInformationsWaitingConfirmation,
  serviceRequestId,
  onError,
  onSuccess,
  onSubmitted,
}): JSX.Element => {
  const featureFlags = useFeatureFlags();
  const [queryParams] = useSearchParams();
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Card);
  const [operatorId, setOperatorId] = useState(
    queryParams.get('operatorId') ?? '',
  );

  const rdiRoleCode = queryParams.get('rdiRoleCode') ?? '';

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Code de l&apos;opérateur</TableCell>
            <TableCell>Montant de l&apos;opération</TableCell>
            <TableCell>Devise</TableCell>
            <TableCell>Opération réalisée</TableCell>
            <TableCell>Montant total</TableCell>
            <TableCell>Confirmation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentInformationsWaitingConfirmation.map((payment, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  placeholder="Code de l'opérateur"
                  required={
                    featureFlags.operatorAuthorizationCheck === OnOff.On
                  }
                  variant="outlined"
                  error={
                    featureFlags.operatorAuthorizationCheck === OnOff.On &&
                    !operatorId
                  }
                  helperText={
                    featureFlags.operatorAuthorizationCheck === OnOff.On &&
                    !operatorId &&
                    "Le code de l'opérateur doit être renseigné"
                  }
                  value={operatorId}
                  onChange={(e): void => setOperatorId(e.target.value)}
                />
              </TableCell>
              <TableCell>
                {formatMaybeAmountInCents(payment.amount?.amount)}
              </TableCell>
              <TableCell>{payment.amount?.currency}</TableCell>
              <TableCell>
                <RadioGroup
                  row
                  value={paymentMethod}
                  onChange={(_, paymentMethod): void =>
                    setPaymentMethod(paymentMethod as PaymentMethod)
                  }>
                  <FormControlLabel
                    value={PaymentMethod.Card}
                    control={<Radio />}
                    label="par carte"
                  />
                  <FormControlLabel
                    value={PaymentMethod.Cash}
                    control={<Radio />}
                    label="en espèces"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                {formatMaybeAmountInCents(payment.totalAmount.amount)}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  disabled={
                    featureFlags.operatorAuthorizationCheck === OnOff.On &&
                    !operatorId
                  }
                  onClick={(): void => {
                    onSubmitted();
                    void confirmPayment(
                      rdiRoleCode,
                      operatorId,
                      serviceRequestId,
                      payment,
                      paymentMethod,
                      onError,
                      onSuccess,
                    );
                  }}>
                  Confirmer ce paiement
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentsTable;
