import {defaultTheme} from 'react-admin';

export const palette = {
  ...defaultTheme.palette,
  primary: {
    contrastText: '#fff',
    dark: '#282D87',
    light: '#282D87',
    main: '#282D87',
  },
  secondary: {
    ...defaultTheme.palette?.secondary,
    main: '#2222B3',
    contrastText: '#CBCBDD',
  },
  grey: {
    '500': '#909090',
    light: '#EFEFEF',
    mild: 'gainsboro',
  },
} as const;
