import React, {createContext, Dispatch} from 'react';

import {reducer} from './reducer';
import {Action, SimulationMode, State} from './reducer.types';

const initialState: State = {
  simMode: SimulationMode.Scan,
  retailerRoleCode: '',
  operatorId: '',
  code: '',
  error: null,
  isLoading: false,
  result: null,
  jsonMode: false,
  urlParams: new URLSearchParams(window.location.search),
  locked: false,
  alert: false,
};

const SimulationContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AppProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <SimulationContext.Provider value={{state, dispatch}}>
      {children}
    </SimulationContext.Provider>
  );
};

export {AppProvider, SimulationContext};
