import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {AxiosError} from 'axios';
import {useState} from 'react';
import {Loading} from 'react-admin';
import {useSearchParams} from 'react-router-dom';

import {base64decodeAndParse} from '../../../../utils/base64.utils';
import {Alert} from '../../../Alerts/Alert';
import {ErrorAlert} from '../../../Alerts/ErrorAlert';
import {SecondaryButton} from '../../../Buttons/SecondaryButton';
import {JSONViewer} from '../../../JSONViewer';
import {PendingOperationWithSimulationParameters} from '../../dto/service-requests.dto';
import {extractPaymentsFromPendingServiceRequestAndUserInput} from '../../utils/extractPaymentsFromPendingServiceRequestAndUserInput';
import PaymentsTable from './PaymentsTable';

type State =
  | {
      state: 'PENDING';
    }
  | {
      state: 'LOADING';
    }
  | {
      state: 'ERROR';
      err: AxiosError | Error;
    }
  | {
      state: 'SUCCESS';
      value: unknown;
    };

const PaymentsConfirmations: React.FC = (): JSX.Element => {
  const [request, setRequest] = useState<State>({state: 'PENDING'});
  const [showJson, setShowJson] = useState(false);
  const [searchParams] = useSearchParams();
  const paymentWorkflow = searchParams.get('paymentWorkflow') || '';

  const data =
    base64decodeAndParse<PendingOperationWithSimulationParameters>(
      paymentWorkflow,
    );

  const serviceRequestId = data.payment.uuid;
  const pendingPaymentsInformations =
    extractPaymentsFromPendingServiceRequestAndUserInput(data.payment);

  return (
    <div>
      {request.state === 'ERROR' && (
        <ErrorAlert
          error={request.err}
          onClose={(): void => setRequest({state: 'PENDING'})}
        />
      )}
      {request.state === 'SUCCESS' && (
        <Alert
          informations={{
            title: 'Succès',
            message: 'Opération confirmée avec succès',
          }}
        />
      )}
      {request.state === 'LOADING' && <Loading />}
      <Typography variant="h6" component="h6">
        Service Request - {serviceRequestId}
      </Typography>
      <PaymentsTable
        paymentInformationsWaitingConfirmation={pendingPaymentsInformations}
        serviceRequestId={serviceRequestId}
        onSuccess={(value): void => setRequest({state: 'SUCCESS', value})}
        onError={(err): void => setRequest({state: 'ERROR', err})}
        onSubmitted={(): void => setRequest({state: 'LOADING'})}
      />
      {request.state === 'SUCCESS' && (
        <Box m="1rem">
          <SecondaryButton
            text="Activer/Désactiver le mode JSON"
            onClick={(): void => setShowJson(!showJson)}
          />

          {showJson && <JSONViewer data={request.value} />}
        </Box>
      )}
    </div>
  );
};

export default PaymentsConfirmations;
