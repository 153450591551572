import {NotFound} from 'react-admin';
import {Navigate} from 'react-router-dom';

import {isPermission, usePermissions} from '../../hooks/usePermissions';
import {resources} from './resources';

// We need a home page to conditionnaly redirect to the first accessible resource
// Otherwise, the default home page would be the first resource
// See: https://marmelab.com/react-admin/Admin.html#dashboard
export const HomePage = (): JSX.Element => {
  const {can} = usePermissions();

  const firstResource = resources.find(
    resource =>
      resource.options &&
      isPermission(resource.options.permission) &&
      can(resource.options.permission),
  );

  return firstResource ? <Navigate to={firstResource.name} /> : <NotFound />;
};
