import {useCallback, useEffect, useState} from 'react';

import {
  Retailer,
  RetailerMonitoringCountDto,
  useGetRetailerMonitoringCountQuery,
} from '../../api/generated';
import {useListControllerTyped} from '../../hooks/useListControllerTyped';
import {mapValues, omit, pick} from '../../utils/collection.utils';

type RetailerMonitoringFiltersT = keyof Omit<
  RetailerMonitoringCountDto,
  '__typename'
>;

type FilterValue = string | number | boolean;

const monitoringFilterNames: Array<RetailerMonitoringFiltersT> = [
  'aboveVelocityThreshold',
  'blocked',
  'bonuses',
  'penalties',
];

const dataEmpty: {[key in RetailerMonitoringFiltersT]: number} = {
  bonuses: 0,
  blocked: 0,
  penalties: 0,
  aboveVelocityThreshold: 0,
};

function omitMonitoringFilters(filterValues: Record<string, FilterValue>): {
  [key in string]: FilterValue;
} {
  return omit(filterValues, monitoringFilterNames);
}

function pickMonitoringFilters(filterValues: Record<string, FilterValue>): {
  [key in RetailerMonitoringFiltersT]: boolean;
} {
  return mapValues(pick(filterValues, monitoringFilterNames), val =>
    Boolean(val),
  );
}

function checkIfHasRegularFilter(
  filterValues: Record<string, FilterValue>,
): boolean {
  return Object.keys(filterValues).some(filterName => {
    const asArrayOfString: string[] = monitoringFilterNames;
    const isMonitoringFilter = asArrayOfString.includes(filterName);
    const seemsToHaveAValue =
      filterValues[filterName] !== null &&
      filterValues[filterName] !== undefined;
    return !isMonitoringFilter && seemsToHaveAValue;
  });
}

export function useRetailerMonitoringFiltersCount(): {
  loading: boolean;
  filterCount: RetailerMonitoringCountDto;
  filterSelected: {[key in RetailerMonitoringFiltersT]: boolean};
  toggleFilter: (filterName: RetailerMonitoringFiltersT) => void;
} {
  const {data, error, loading} = useGetRetailerMonitoringCountQuery();

  const listControllerTyped = useListControllerTyped<Retailer>();
  const {filterValues, setFilters, displayedFilters} = listControllerTyped;
  const [previousHasRegularFilter, setPreviousHasRegularFilter] = useState(
    checkIfHasRegularFilter(filterValues),
  );

  useEffect(() => {
    const hasRegularFilter = checkIfHasRegularFilter(filterValues);
    if (!previousHasRegularFilter && hasRegularFilter) {
      const filtersWithoutMonitoring = omitMonitoringFilters(filterValues);
      setFilters(filtersWithoutMonitoring, displayedFilters);
    }
    setPreviousHasRegularFilter(hasRegularFilter);
  }, [
    displayedFilters,
    filterValues,
    listControllerTyped,
    previousHasRegularFilter,
    setFilters,
  ]);

  const toggleFilter = useCallback(
    (filterName: RetailerMonitoringFiltersT): void => {
      const newValue = !filterValues[filterName];
      const newFilters = newValue ? {[filterName]: true} : {};
      setFilters(newFilters, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters],
  );

  const dataOut = {
    loading,
    filterCount: dataEmpty,
    toggleFilter,
    filterSelected: pickMonitoringFilters(filterValues),
  };
  if (!error && data) {
    // @todo raise error in the logs
  }
  if (data) {
    dataOut.filterCount = data.retailerMonitoringCount;
  }

  return dataOut;
}
