import {CreateBase, ListButton} from 'react-admin';

import {PermissionDto} from '../../api/generated';
import {StyledForm} from '../../components/Form/StyledForm';
import {PermissionGuard} from '../../components/PermissionGuard';
import {productCreateEditTransform} from './ProductEdit';
import {ProductForm} from './ProductForm';

export const ProductCreate = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.ProductRead}>
      <CreateBase redirect="list" transform={productCreateEditTransform}>
        <StyledForm>
          <ProductForm
            NavigationButton={<ListButton label="Annuler" icon={<div />} />}
          />
        </StyledForm>
      </CreateBase>
    </PermissionGuard>
  );
};
