import {filtersHelperTextHeight} from '../constants';
import {muiTheme} from './defaultMuiTheme';

export const RaFilterFormOverride = {
  styleOverrides: {
    root: {
      '& .MuiFormControl-root': {
        width: '332px',
      },
      '& .MuiInputBase-root.Mui-error': {
        borderColor: muiTheme.palette.error.main,
      },
      '& .Mui-error': {
        color: muiTheme.palette.error.main,
      },
      '& .MuiFormHelperText-root': {
        display: 'block',
        boxSizing: 'border-box',
        margin: '0px',
        padding: '1px 14px 0px',
        height: filtersHelperTextHeight,
        width: '100%',
      },
    },
  },
} as const;
