import {Card, CardContent, CardHeader, Grid} from '@mui/material';
import {useEffect} from 'react';
import {
  FormDataConsumer,
  minValue,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  useEditContext,
} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {
  NirioBankWelcomePromotion,
  NirioBankWelcomePromotionType,
  useGetAllNirioBankPromotionOffersQuery,
} from '../../../api/generated';
import {dateRangeFilters} from '../../../components/Form/Filters/dateRangeFilters';
import {AutocompleteArrayInput} from '../../../components/Form/Inputs/AutocompleteArrayInput';
import {StyledSwitch} from '../../../components/Form/Inputs/StyledSwitch';
import {useFormValues} from '../../../hooks/useFormValues';
import {
  isNotOnlySpaces,
  numberInputFormatFromCents,
  numberInputParseToCents,
} from '../../../utils/input.utils';

export const WelcomePromotionForm = (): JSX.Element => {
  const {data: {allNirioBankPromotionOffers: offers} = {}} =
    useGetAllNirioBankPromotionOffersQuery();
  const {setValue} = useFormContext();
  const {resource} = useEditContext();
  const isInCreateContext = resource === null;
  const isFreeMonthTypeDisabled = true; // TODO: remove when free month type is implemented

  const {type} = useFormValues<NirioBankWelcomePromotion>();

  useEffect(() => {
    const requiredPayments = [
      'requiredPaymentsCount',
      'requiredPaymentsMinimumAmountInCents',
      'requiredPaymentsSlidingDays',
    ];

    const value = type === NirioBankWelcomePromotionType.Monetary ? 0 : null;
    requiredPayments.forEach(field => setValue(field, value));
  }, [type, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader
            title="Promotion"
            action={
              <StyledSwitch
                defaultValue={true}
                label="Actif"
                source="isActive"
              />
            }
          />
          <CardContent>
            <TextInput
              key="name"
              source="name"
              validate={[required(), isNotOnlySpaces()]}
              fullWidth
              disabled={!isInCreateContext}
            />
            <AutocompleteArrayInput
              key="offers"
              source="offers"
              choices={(offers ?? []).map(offer => ({
                id: offer.id,
                name: offer.name,
              }))}
              fullWidth
              validate={required()}
            />
            {dateRangeFilters({
              from: {source: 'dateStart', label: 'Date de début'},
              to: {source: 'dateEnd', label: 'Date de fin'},
            })}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader title="Paramètres" />
          <CardContent>
            <SelectInput
              source="type"
              choices={[
                {
                  id: NirioBankWelcomePromotionType.Monetary,
                  name: "Versement d'argent",
                },
                {
                  id: NirioBankWelcomePromotionType.FreeMonths,
                  name: 'Mois offert',
                },
              ]}
              validate={[required()]}
              fullWidth
              defaultValue={NirioBankWelcomePromotionType.Monetary}
              disabled={!isInCreateContext || isFreeMonthTypeDisabled}
            />
            <FormDataConsumer<{type: string}>>
              {({formData}): JSX.Element => {
                return formData.type ===
                  NirioBankWelcomePromotionType.Monetary ? (
                  <NumberInput
                    source="gainAmountInCents"
                    min={0}
                    step={0.01}
                    format={numberInputFormatFromCents}
                    parse={numberInputParseToCents}
                    validate={[
                      required(),
                      minValue(
                        0,
                        'Le montant minimum autorisé doit être supérieur ou égal à 0',
                      ),
                    ]}
                    fullWidth
                  />
                ) : (
                  <NumberInput
                    key="freeMonthsCount"
                    source="freeMonthsCount"
                    min={0}
                    step={1}
                    validate={[
                      required(),
                      minValue(
                        0,
                        'Le nombre de mois minimum autorisé doit être supérieur ou égal à 0',
                      ),
                    ]}
                    fullWidth
                    disabled={!isInCreateContext}
                  />
                );
              }}
            </FormDataConsumer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
