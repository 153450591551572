import {Box, Button, CardContent, Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

import {PromotionSettingsType} from '../../api/generated';
import {PageLayout} from '../../components/PageLayout';
import {TopBar} from '../../components/TopBar';

export const PromotionHome = (): JSX.Element => {
  const links: Array<{path: string; label: string}> = [];

  links.push(
    {
      path: '/admin/NirioBankWelcomePromotion',
      label: 'Promotion de bienvenue',
    },
    {
      path: `/admin/PromotionSettings/${PromotionSettingsType.RetailerPromotion}/show`,
      label: 'Promotion des détaillants',
    },
    {
      path: `/admin/PromotionSettings/${PromotionSettingsType.ReferrerPromotion}/show`,
      label: 'Promotion de parrainage',
    },
  );

  return (
    <PageLayout>
      <TopBar>
        <Typography variant="h1" color="secondary">
          Promotions
        </Typography>
        <Typography variant="h2" color="secondary">
          Tableau de bord des promotions
        </Typography>
      </TopBar>
      <CardContent>
        <Grid container>
          {links.map(link => (
            <Grid key={link.label} item md={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link style={{textDecoration: 'none'}} to={link.path}>
                  <Button
                    sx={{mb: 2, fontSize: '1.2rem', width: '400px'}}
                    variant="contained">
                    {link.label}
                  </Button>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </PageLayout>
  );
};
