import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import TurnbackIcon from '@mui/icons-material/KeyboardBackspace';
import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import {useRef, useState} from 'react';
import {
  CreateBase,
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  Link,
  List,
  ListButton,
  required,
  Resource,
  SaveHandler,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useEditContext,
  useNotify,
  useRecordContext,
} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {
  AppBasicContent,
  InternalUrl,
  MutationCreateCarrouselImageArgs,
  PermissionDto,
} from '../api/generated';
import {resources} from '../components/Admin/resources';
import {CreateEditTopBar} from '../components/CreateEditTopBar';
import {FormToolbar} from '../components/Form/FormToolbar';
import {StyledForm} from '../components/Form/StyledForm';
import {HtmlEditor} from '../components/HtmlEditor';
import {nirioToolbar} from '../components/HtmlEditor/toolbar';
import {Icons} from '../components/Icon';
import {PageLayout} from '../components/PageLayout';
import {PermissionGuard} from '../components/PermissionGuard';
import {usePermissions} from '../hooks/usePermissions';
import {safeConvertFileToBase64} from '../utils/base64.utils';
import {stripTypename} from '../utils/strip-typename';

const StyledAppBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '60px 0 0 0',
  width: '100%',
  flex: '1',
  paddingRight: '44px',
  marginTop: '0px',
});

const FormContainer = styled('div')({
  width: '100%',
  gap: '24px',
});

const StyledPaper = styled(Paper)({
  display: 'flex',
  backgroundColor: 'white',
  padding: '32px',
  flexDirection: 'column',
  rowGap: '24px',
  '& .MuiFormHelperText-root:not(.Mui-error)': {
    display: 'none',
  },

  '& .MuiFormControlLabel-root': {
    margin: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '0',
    marginRight: '10px',
  },
});

const PaperTitle = styled(Typography)({
  marginBottom: '24px',
});

const CardTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const maxImageSize = (max: number) => {
  return (value: {rawFile: {size: number}}): string | undefined => {
    const sizeIsLarge = (val: {rawFile: {size: number}}): boolean =>
      val?.rawFile?.size >= max;
    return (Array.isArray(value) ? value.some(sizeIsLarge) : sizeIsLarge(value))
      ? "L'image est trop lourde. Elle ne doit pas dépasser les 200ko"
      : undefined;
  };
};

export interface CarrouselFormProps {
  title?: string;
  NavigationButton?: JSX.Element;
}

export const CarrouselImageForm = ({
  NavigationButton,
}: CarrouselFormProps): JSX.Element => {
  enum URL {
    ExternalUrl = 'externalUrl',
    InternalUrl = 'internalUrl',
    NoUrl = 'noUrl',
  }
  const [urlToChoose, setUrl] = useState(URL.NoUrl);
  const {setValue} = useFormContext();
  setValue('urlToChoose', urlToChoose);

  const appearanceOrder = Array.from({length: 5}, (_, i) => i + 1).map(
    number => ({
      id: number,
      name: number,
      label: number,
    }),
  );

  const offerTypeList = [
    {id: 'GO', name: 'Go'},
    {id: 'PREMIO', name: 'Premio'},
  ];
  const internalUrlList = Object.entries(InternalUrl).map(([key, value]) => ({
    id: value,
    name: key,
  }));

  return (
    <PageLayout>
      <CreateEditTopBar
        title="Nouvelle image pour le carrousel"
        NavigationButton={NavigationButton}
        resourceName="AppBasicContent"
      />
      <FormContainer>
        <StyledPaper>
          <CardTitleContainer>
            <PaperTitle variant="h1" color="secondary">
              Image du carrousel
            </PaperTitle>
          </CardTitleContainer>
          <ImageInput
            key="imageBytes"
            source="imageBytes"
            accept="image/png, image/jpeg, image/jpg"
            sx={{
              '& .previews': {
                // Center the preview
                textAlign: 'center',
              },
            }}
            validate={[maxImageSize(201 * 1024), required()]}
            placeholder={
              <div
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div style={{padding: '1rem'}}>
                  <Icons.UploadLarge />
                </div>
                <div>
                  <p style={{color: 'grey'}}>
                    Déposez l&apos;image à uploader, ou cliquez pour la
                    sélectionner.
                  </p>
                  <p>
                    Le fichier ne doit pas dépasser 200 ko. Les formats acceptés
                    sont : PNG, JPEG.
                  </p>
                </div>
              </div>
            }>
            <ImageField source="src" title="title" />
          </ImageInput>

          <SelectInput
            key="offerType"
            source="offerType"
            defaultValue={offerTypeList[0].id}
            choices={offerTypeList}
            translateChoice={false}
            validate={required()}
          />
          <SelectInput
            key="appearanceOrder"
            source="appearanceOrder"
            choices={appearanceOrder}
            translateChoice={false}
            validate={required()}
          />
          <TextInput
            key="name"
            source="name"
            inputProps={{maxLength: 40}}
            validate={required()}
          />

          <TextInput
            key="description"
            source="description"
            inputProps={{maxLength: 200}}
            multiline
          />
          <RadioGroup
            row
            value={urlToChoose}
            defaultValue={URL.NoUrl}
            onChange={(_, chosenUrl): void => {
              setUrl(chosenUrl as URL);
              setValue('urlToChoose', chosenUrl);
            }}>
            <FormControlLabel
              value={URL.NoUrl}
              control={<Radio />}
              label="Aucune redirection"
            />
            <FormControlLabel
              value={URL.ExternalUrl}
              control={<Radio />}
              label="Url externe"
            />
            <FormControlLabel
              value={URL.InternalUrl}
              control={<Radio />}
              label="Url interne"
            />
          </RadioGroup>
          {urlToChoose === URL.ExternalUrl && (
            <TextInput
              key="externalUrl"
              source="externalUrl"
              validate={required()}
            />
          )}
          {urlToChoose === URL.InternalUrl && (
            <SelectInput
              key="internalUrl"
              source="internalUrl"
              choices={internalUrlList}
              defaultValue=""
              translateChoice={false}
              validate={required()}
            />
          )}
        </StyledPaper>
      </FormContainer>
    </PageLayout>
  );
};

export const CarrouselImageCreate = (): JSX.Element => {
  const notify = useNotify();
  const onError = (): void =>
    notify('ra.notification.carrouselImageCreateError', {type: 'error'});

  return (
    <PermissionGuard permission={PermissionDto.CarrouselImageWrite}>
      <CreateBase
        mutationOptions={{onError}}
        redirect="../../AppBasicContent"
        transform={async ({
          urlToChoose,
          ...data
        }: MutationCreateCarrouselImageArgs & {
          urlToChoose: string;
        }): Promise<MutationCreateCarrouselImageArgs> => {
          const convertedImageBytes = await safeConvertFileToBase64(
            data.imageBytes as never,
          );
          return {
            ...stripTypename(data),
            imageBytes:
              typeof convertedImageBytes === 'string'
                ? convertedImageBytes
                : 'undefined',
            internalUrl:
              urlToChoose === 'internalUrl' &&
              data.internalUrl &&
              data.internalUrl.length !== 0
                ? data.internalUrl
                : undefined,
            externalUrl:
              urlToChoose === 'externalUrl' &&
              data.externalUrl &&
              data.externalUrl.length !== 0
                ? data.externalUrl
                : undefined,
          };
        }}>
        <StyledForm>
          <CarrouselImageForm
            NavigationButton={
              <ListButton
                resource="AppBasicContent"
                label="Annuler"
                icon={<div />}
              />
            }
          />
        </StyledForm>
      </CreateBase>
    </PermissionGuard>
  );
};

const UrlTextField = ({source}: {source: string}): JSX.Element => {
  // To display the attributed url, or nothing if there is no
  const record = useRecordContext();
  if (record && record[source]) {
    return <TextField source={source} sortable={false} />;
  }
  if (record && record['internalUrl']) {
    return <TextField source="internalUrl" sortable={false} />;
  }
  return <TextField />;
};

export const CarrouselImageList = (): JSX.Element => {
  const {can} = usePermissions();

  return (
    <PermissionGuard permission={PermissionDto.CarrouselImageRead}>
      <List
        filter={{offerType: 'GO'}}
        actions={
          // Title
          <StyledAppBar>
            <Typography variant="h1" color="secondary">
              Go
            </Typography>
          </StyledAppBar>
        }
        empty={<EmptyListComponent title="Go" />}>
        <Datagrid bulkActionButtons={false}>
          <ImageField source="imageBytes" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField
            source="description"
            sortable={false}
            style={{lineBreak: 'anywhere'}}
          />
          <TextField source="appearanceOrder" sortable={false} />
          <UrlTextField source="externalUrl" />
          {can(PermissionDto.CarrouselImageWrite) && (
            <DeleteButton redirect={false} resource="CarrouselImage" />
          )}
        </Datagrid>
      </List>

      <List
        filter={{offerType: 'PREMIO'}}
        actions={
          // Title
          <StyledAppBar>
            <Typography variant="h1" color="secondary">
              Premio
            </Typography>
          </StyledAppBar>
        }
        empty={<EmptyListComponent title="Premio" />}>
        <Datagrid bulkActionButtons={false}>
          <ImageField source="imageBytes" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField
            source="description"
            sortable={false}
            style={{lineBreak: 'anywhere'}}
          />
          <TextField source="appearanceOrder" sortable={false} />
          <UrlTextField source="externalUrl" />
          {can(PermissionDto.CarrouselImageWrite) && (
            <DeleteButton redirect={false} resource="CarrouselImage" />
          )}
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};

export const AppBasicContentList = (): JSX.Element => {
  const {can} = usePermissions();
  const carrouselImageRessource = resources.find(
    resource => resource.name === 'CarrouselImage',
  );

  return (
    // Everybody has this permission
    <PermissionGuard permission={PermissionDto.CarrouselImageRead}>
      {can(PermissionDto.AppContentRead) && (
        <PermissionGuard permission={PermissionDto.AppContentRead}>
          <List>
            <Datagrid bulkActionButtons={false}>
              <TextField source="type" sortable={false} />
              <TextField source="createdAt" sortable={false} />
              <TextField source="content.title" sortable={false} />
              {can(PermissionDto.AppContentWrite) && <EditButton />}
            </Datagrid>
          </List>
        </PermissionGuard>
      )}
      <StyledAppBar
        style={{
          marginTop: '20px',
          marginRight: '50px',
          marginBottom: '0px',
        }}>
        <Typography
          variant="h1"
          align="center"
          color="secondary"
          style={{marginLeft: 'auto', flex: 'auto'}}>
          Images du carrousel
        </Typography>
      </StyledAppBar>
      {can(PermissionDto.CarrouselImageWrite) && (
        <CreateButton
          resource="CarrouselImage"
          variant="contained"
          key="create"
          icon={<Icons.Add isActive size={17} />}
          style={{
            marginLeft: 'auto',
            marginRight: '42px',
            marginBottom: '10px',
          }}
        />
      )}

      {carrouselImageRessource && (
        <Resource
          key={carrouselImageRessource.name}
          {...carrouselImageRessource}
          create={CarrouselImageCreate}
        />
      )}
    </PermissionGuard>
  );
};

export const AppBasicContentEdit = (): JSX.Element => {
  return (
    <PermissionGuard permission={PermissionDto.AppContentWrite}>
      <Edit
        sx={{width: '100%', maxWidth: '1000px', margin: '0 auto'}}
        mutationMode="optimistic">
        <AppBasicContentEditForm />
      </Edit>
    </PermissionGuard>
  );
};

const AppBasicContentEditForm = (): JSX.Element => {
  const editorRef = useRef<{getHtml: () => string}>();

  const {save} = useEditContext() as {save: SaveHandler<AppBasicContent>};
  const record = useRecordContext<{content: {html: string}}>();

  const onSubmit = async (data: Record<string, object>): Promise<void> => {
    if (!editorRef.current) {
      throw new Error('Editor state inaccessible');
    }

    await save({
      ...data,
      content: {...data?.content, html: editorRef.current.getHtml()},
    });
  };

  return (
    <SimpleForm onSubmit={onSubmit} toolbar={<FormToolbar />}>
      <Link
        to="/admin/AppBasicContent"
        title="Retour vers la liste des contenus simples">
        <TurnbackIcon />
      </Link>
      <Box
        display={{
          xs: 'block',
          sm: 'flex',
          width: '100%',
        }}>
        <Box flex={1}>
          Type: <TextField source="type" />
        </Box>
        <Box flex={1}>
          <TextField source="createdAt" />
        </Box>
      </Box>
      <TextInput fullWidth source="content.title" />
      <HtmlEditor
        initialHtml={record.content.html}
        ref={editorRef}
        toolbar={nirioToolbar}
      />
    </SimpleForm>
  );
};
const EmptyListComponent = ({title}: {title: string}): JSX.Element => {
  return (
    <div style={{flexDirection: 'column'}}>
      <StyledAppBar>
        <Typography variant="h1" color="secondary">
          {title}
        </Typography>
      </StyledAppBar>
      <div style={{textAlign: 'center', color: 'GrayText'}}>
        <p style={{fontSize: '2.125rem'}}>
          Le carrousel n’est pas encore configuré
        </p>
        <p style={{fontSize: '14px'}}>
          Cliquez sur “Créer” pour ajouter une image.
        </p>
      </div>
    </div>
  );
};
