import {useEffect, useState} from 'react';
import {Button, useRecordContext} from 'react-admin';

import {
  Product,
  TransactionsExportFormat,
  useGenerateProductTransactionsExportLinkLazyQuery,
} from '../../api/generated';
import {isValueOfEnum} from '../../utils/collection.utils';
import {ReportModal} from './ReportModal';

export const GenerateReportButton = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const record = useRecordContext<Product>();

  const datamatrixProductId = record.datamatrixProductId;
  const datamatrixPartnerId = record.partner.datamatrixPartnerId;

  const [generateProductTransactionsExportLink, {data}] =
    useGenerateProductTransactionsExportLinkLazyQuery();

  useEffect(() => {
    if (data?.generateProductTransactionsExportLink) {
      window.open(data.generateProductTransactionsExportLink, '_blank');
      setIsOpen(false);
    }
  }, [data]);

  function handleSubmit(
    data: Record<string, string>,
  ): Record<string, string> | void {
    if (!data.startDate) {
      return {startDate: 'Date requise'};
    }
    if (!data.endDate) {
      return {endDate: 'Date requise'};
    }
    if (!data.format) {
      return {format: 'Format requis'};
    }
    if (!isValueOfEnum(data.format, TransactionsExportFormat)) {
      return {format: 'Format invalide'};
    }

    void generateProductTransactionsExportLink({
      variables: {
        datamatrixProductId: String(datamatrixProductId),
        datamatrixPartnerId: String(datamatrixPartnerId),
        format: data.format,
        startDate: data.startDate,
        endDate: data.endDate,
      },
    });
  }

  return (
    <>
      <Button onClick={(): void => setIsOpen(true)} label="Générer rapport" />
      <ReportModal
        isOpen={isOpen}
        onClose={(): void => setIsOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};
