import {PaperProps} from '@mui/material/Paper/Paper';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

import {StyleOverrideRoot} from './types';

export const MuiPaperOverride = {
  styleOverrides: {
    root: ({
      ownerState,
      theme,
    }: StyleOverrideRoot<PaperProps>): OverridesStyleRules => ({
      // we override only the default elevation, so we're still able to use elevation = x
      ...(ownerState.elevation === 1 && {
        borderRadius: theme.spacing(1),
        overflow: 'hidden',
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
      }),
    }),
  },
};
