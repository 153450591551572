import TvIcon from '@mui/icons-material/Tv';
import {ReactElement} from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import {
  Cart,
  OnOff,
  PermissionDto,
  useGetAllPartnersQuery,
  useGetAllProductCategoriesQuery,
} from '../../api/generated';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {dateRangeFilters} from '../../components/Form/Filters/dateRangeFilters';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PaidAmountTooltip} from '../../components/PaidAmountTooltip';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {usePermissions} from '../../hooks/usePermissions';
import {formatMaybeAmountInCents} from '../../utils/format-maybe-amount-in-cents';
import {getLastOperationConfirmed} from '../../utils/getLastOperationConfirmed.utils';
import {isUuid} from '../../utils/input.utils';
import {stopPropagation} from '../../utils/stop-propagation';

export const CartList = (): JSX.Element => {
  const featureFlags = useFeatureFlags();
  const {can} = usePermissions();
  const {data: {getAllPartners: partners} = {}} = useGetAllPartnersQuery();
  const {data: {getAllProductCategories: categories = []} = {}} =
    useGetAllProductCategoriesQuery();

  if (!partners || !categories) {
    return <>Chargement...</>;
  }

  const cartFilters = [
    <TextInput
      key={1}
      source="id"
      alwaysOn
      resettable
      validate={isUuid}
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <TextInput
      key={2}
      source="payerId"
      alwaysOn
      resettable
      validate={isUuid}
      InputProps={{
        endAdornment: <Icons.Search size={20} />,
      }}
    />,
    <AutocompleteInput
      key={3}
      source="status"
      alwaysOn
      choices={[
        {
          id: 'TO_PAY',
          name: 'Ouvert',
        },
        {
          id: 'PAID',
          name: 'Réglé',
        },
      ]}
    />,
    ...dateRangeFilters({from: 'createdAtStartDate', to: 'createdAtEndDate'}),
    <FilterSpacer key="spacer" source="spacer" alwaysOn />,
    <FilterResetButton key="reset" source="reset" alwaysOn />,
  ];

  return (
    <PermissionGuard permission={PermissionDto.CartRead}>
      <List
        actions={<ListTopBar />}
        exporter={false}
        filters={cartFilters}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid
          hover={false}
          rowClick="show"
          bulkActionButtons={false}
          isRowExpandable={(_record: Cart): boolean => true}>
          <TextField source="id" />
          <DateField locales="fr-FR" showTime={true} source="createdAt" />
          <DateField locales="fr-FR" showTime={true} source="updatedAt" />
          <FunctionField
            source="status"
            render={(record: Cart): string => {
              if (record.paidAt) {
                return 'Réglé';
              }

              return 'Ouvert';
            }}
          />
          <FunctionField
            sortable={false}
            source="totalAmount"
            label={<PaidAmountTooltip text="Montant (en €)" />}
            render={(record: Cart): string => {
              const sum = record.items?.reduce(
                (acc, cartItem) =>
                  acc + cartItem.amountInCents + cartItem.serviceFeeInCents,
                0,
              );
              return formatMaybeAmountInCents(sum);
            }}
          />
          <FunctionField
            source="payerId"
            render={(record: Cart): JSX.Element => (
              <PermissionLink
                permission={PermissionDto.PayerRead}
                to={`/admin/Client/${record.payerId}/show`}>
                {record.payerId}
              </PermissionLink>
            )}
          />
          <FunctionField
            sortable={false}
            source="paymentId"
            render={(record: Cart): ReactElement => {
              const lastPayment = getLastOperationConfirmed(record);
              if (lastPayment) {
                return (
                  <PermissionLink
                    permission={PermissionDto.PaymentWorkflowRead}
                    key={`Payment ${lastPayment.id}`}
                    to={`/admin/Operation/${lastPayment.id}/show`}>
                    {lastPayment.id}
                  </PermissionLink>
                );
              } else {
                return <>Aucune opération</>;
              }
            }}
          />
          {featureFlags.neptuneSimulator === OnOff.On &&
            can(PermissionDto.NeptuneRead) && (
              <FunctionField
                source="Action"
                sortable={false}
                render={(record: Cart): JSX.Element => {
                  return record.items.length ? (
                    <Link
                      target="_blank"
                      onClick={stopPropagation}
                      to={`/admin/neptune/scan?cartId=${record.id.toString()}&updatedAt=${
                        record.updatedAt && typeof record.updatedAt === 'string'
                          ? record.updatedAt
                          : ''
                      }`}>
                      <TvIcon /> Simul. paiement
                    </Link>
                  ) : (
                    <div style={{color: 'grey'}}>
                      <TvIcon /> Simul. paiement
                    </div>
                  );
                }}
              />
            )}
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
