import {ComponentProps} from 'react';

import {LabelWithTooltip} from './LabelWithTooltip';

export const PaidAmountTooltip = ({
  text = "Montant de l'opération (en €)",
  title = 'Frais de service inclus',
  tooltipProps,
  helpIconProps,
}: Partial<ComponentProps<typeof LabelWithTooltip>>): JSX.Element => (
  <LabelWithTooltip
    text={text}
    title={title}
    tooltipProps={tooltipProps}
    helpIconProps={helpIconProps}
  />
);
