import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Box, Stack} from '@mui/material';

const MagicLinkLoginRequestSuccess = ({
  inputEmail,
}: {
  inputEmail: string;
}): JSX.Element => (
  <Stack alignItems="center" sx={{padding: '16px 0px 32px 0px;'}}>
    <CheckCircleIcon
      sx={{
        color: '#73b98a',
        fontSize: '3rem',
      }}
    />
    <Box
      sx={{
        marginTop: '16px',
        maxWidth: '360px',
        textAlign: 'center',
      }}>
      <span>
        Si l&apos;adresse email <strong>{inputEmail}</strong> est associée à un
        compte, un email contenant le lien de connexion vous y a été envoyé
      </span>
    </Box>
  </Stack>
);

export default MagicLinkLoginRequestSuccess;
