export const MuiButtonOverride = {
  styleOverrides: {
    root: {
      width: '236px',
      height: '56px',
      borderRadius: '56px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
      color: '#2222B3',
      fontFamily: 'Chance',
      fontSize: '16px',
      fontWeight: 700,
      backgroundColor: '#FFFFFF',
      textTransform: 'none' as const,
      '& .MuiButton-startIcon': {
        marginRight: '10px',
      },
    },
    contained: {
      background: '#2222B3',
      color: 'white',
    },
  },
};
