import {Box, Button, Card, CardContent, Grid} from '@mui/material';
import {Title} from 'react-admin';
import {Link} from 'react-router-dom';

import {PermissionDto} from '../../../api/generated';
import {usePermissions} from '../../../hooks/usePermissions';

export const NeptuneHome = (): JSX.Element => {
  const {can} = usePermissions();

  const links: Array<{path: string; label: string}> = [];

  if (can(PermissionDto.NeptuneWrite)) {
    links.push({
      path: '/admin/neptune/scan',
      label: 'Scanner un QR Code de paiement (Facture, AVP)',
    });

    links.push({
      path: '/admin/neptune/toll',
      label: 'Recherche de passages de péage',
    });
  }
  links.push(
    {
      path: '/admin/neptune/operation/search',
      label: 'Rechercher une opération',
    },
    {
      path: '/admin/neptune/operation/report',
      label: "Rapport d'opérations",
    },
  );

  return (
    <Card sx={{minHeight: 'calc(100vh - 50px)'}}>
      <Title title="Tableau de bord" />
      <CardContent>
        <Grid container>
          {links.map(link => (
            <Grid key={link.label} item md={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link style={{textDecoration: 'none'}} to={link.path}>
                  <Button
                    sx={{mb: 2, fontSize: '1.2rem', width: '400px'}}
                    variant="contained">
                    {link.label}
                  </Button>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
