import {Chip, styled, Typography} from '@mui/material';
import {useState} from 'react';
import {
  Button,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  Pagination,
  ReferenceManyField,
  TextField,
  useShowController,
} from 'react-admin';

import {
  NirioBankWelcomePromotion,
  NirioBankWelcomePromotionType,
  PermissionDto,
  useGetAllNirioBankPromotionOffersQuery,
} from '../../../api/generated';
import {CustomShow} from '../../../components/CustomShow';
import {LabeledFunctionField} from '../../../components/Fields/LabeledFunctionField';
import {LabeledTextField} from '../../../components/Fields/LabeledTextField';
import {PermissionGuard} from '../../../components/PermissionGuard';
import {RenderConditionalLabel} from '../../../components/RenderLabel';
import {usePermissions} from '../../../hooks/usePermissions';
import {palette} from '../../../theme/palette';
import {formatMaybeAmountInCents} from '../../../utils/format-maybe-amount-in-cents';
import {GenerateCodesDialog} from './GenerateCodesDialog';

const StyledCardsContentWithoutMargin = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledCardsContent = styled('div')({
  marginTop: '23px',
});

const LabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '7px',

  '& .MuiTypography-root': {
    fontSize: '14px',
  },
  '& .RaLabeled-label': {
    color: palette.grey[500],
    fontSize: '12px',
  },
});

const LeftCardTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '30px',
});

const GridContainer = styled('div')({
  display: 'grid',
  width: '100%',
  gap: '24px',
  gridTemplateColumns: '1fr 1fr',
  marginBottom: '46px',
});

const ChipWithMargin = styled(Chip)({
  marginRight: '0.5em',
});

const codesGrid = (
  <ReferenceManyField
    reference="NirioBankWelcomeCode"
    target="promotionId"
    pagination={<Pagination />}
    perPage={10}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="code" />
      <DateField source="createdAt" />
      <TextField source="maxClaimed" />
    </Datagrid>
  </ReferenceManyField>
);

const LeftCardContent = (): JSX.Element => {
  const {data: {allNirioBankPromotionOffers: offers} = {}} =
    useGetAllNirioBankPromotionOffersQuery();

  return (
    <LabelContainer>
      <LabeledTextField source="name" key="name" />
      <Labeled key="dateStart">
        <DateField
          source="dateStart"
          showTime={true}
          key="dateStart"
          locales="fr-FR"
        />
      </Labeled>
      <Labeled key="dateEnd">
        <DateField
          source="dateEnd"
          showTime={true}
          key="dateEnd"
          locales="fr-FR"
        />
      </Labeled>
      <Labeled key="offers">
        <FunctionField
          source="offers"
          render={(record: NirioBankWelcomePromotion): JSX.Element[] =>
            // offers could be undefined if loaded from React-Admin cache before request completes
            record.offers?.map(offerId => (
              <ChipWithMargin
                key={offerId}
                label={offers?.find(o => o.id === offerId)?.name ?? '...'}
              />
            ))
          }
        />
      </Labeled>
    </LabelContainer>
  );
};

const RightCardContent = (): JSX.Element => {
  const {record} = useShowController<NirioBankWelcomePromotion>();

  if (!record) {
    return <></>;
  }

  const field =
    record.type === NirioBankWelcomePromotionType.FreeMonths
      ? 'freeMonthsCount'
      : 'gainAmountInCents';

  return (
    <LabelContainer>
      <LabeledFunctionField
        key="type"
        source="type"
        render={(record: NirioBankWelcomePromotion): string => {
          switch (record.type) {
            case NirioBankWelcomePromotionType.Monetary:
              return "Versement d'argent";
            case NirioBankWelcomePromotionType.FreeMonths:
              return 'Mois offert';
            default:
              return '';
          }
        }}
      />
      <LabeledFunctionField
        key={field}
        source={field}
        render={(record: NirioBankWelcomePromotion): string => {
          switch (record.type) {
            case NirioBankWelcomePromotionType.FreeMonths:
              return record.freeMonthsCount
                ? record.freeMonthsCount.toString()
                : '0';
            case NirioBankWelcomePromotionType.Monetary:
              return record.gainAmountInCents
                ? formatMaybeAmountInCents(record.gainAmountInCents)
                : '0';
            default:
              return '';
          }
        }}
      />
    </LabelContainer>
  );
};

export const WelcomePromotionShow = (): JSX.Element => {
  const {can} = usePermissions();
  const [dialogOpen, setDialogOpen] = useState(false);

  const actions = [];
  if (can(PermissionDto.ProductWrite)) {
    actions.push(
      <Button
        label="Générer des codes"
        variant="contained"
        onClick={(): void => setDialogOpen(true)}
      />,
      <EditButton
        icon={<div />}
        key="edit-button"
        label="Modifier"
        variant="contained"
      />,
    );
  }

  const topPapers = [
    {
      content: (
        <StyledCardsContentWithoutMargin>
          <LeftCardTitleContainer>
            <Typography variant="h1" color="secondary">
              Promotion de bienvenue
            </Typography>
            <FunctionField
              render={(record: NirioBankWelcomePromotion): JSX.Element => (
                <RenderConditionalLabel condition={record.isActive} />
              )}
            />
          </LeftCardTitleContainer>
          <LeftCardContent />
        </StyledCardsContentWithoutMargin>
      ),
    },
    {
      title: 'Paramètres',
      content: (
        <StyledCardsContent>
          <RightCardContent />
        </StyledCardsContent>
      ),
    },
  ];

  return (
    <PermissionGuard permission={PermissionDto.PromotionRead}>
      <GenerateCodesDialog
        open={dialogOpen}
        onClose={(): void => setDialogOpen(false)}
      />
      <CustomShow
        actions={actions}
        topPapersContainer={GridContainer}
        topPapers={topPapers}
        mainPaperTitle="Codes promotionnels"
        mainPaper={[
          {
            content: codesGrid,
          },
        ]}
      />
    </PermissionGuard>
  );
};
