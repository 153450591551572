import {Theme} from '@mui/material/styles';
export const RaLabeledOverride = {
  styleOverrides: {
    root: ({theme}: {theme: Theme}): unknown => ({
      '& .RaLabeled-label': {
        color: theme.palette.grey[500],
        lineHeight: '1',
      },
    }),
  },
};
