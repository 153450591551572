import {NirioBankWelcomePromotion, PermissionDto} from '../../../api/generated';
import {NirioEdit} from '../../../components/NirioEdit';
import {PermissionGuard} from '../../../components/PermissionGuard';
import {WelcomePromotionForm} from './WelcomePromotionForm';

export const WelcomePromotionEdit = (): JSX.Element => (
  <PermissionGuard permission={PermissionDto.PromotionWrite}>
    <NirioEdit
      title={(record: NirioBankWelcomePromotion): string =>
        `Promotion ${record.name}`
      }>
      <WelcomePromotionForm />
    </NirioEdit>
  </PermissionGuard>
);
