import {styled} from '@mui/material/styles';

export const TopBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '60px 0 0 0',
  minHeight: '56px',
  width: '100%',
  marginBottom: '44px',
});
