import {palette} from './palette';

export const RaDatagridOverride = {
  styleOverrides: {
    root: {
      boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.05)',
      borderRadius: '8px 8px 0 0',
      overflow: 'hidden',
      '& .MuiTableRow-root': {
        height: '64px',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#E5F8FF',
          '& .MuiTypography-root': {
            fontWeight: '700',
          },
        },
      },
      '& .MuiTableCell-root': {
        border: 'none',
        '& .MuiTypography-root': {
          fontFamily: 'Inter',
        },
      },
      '& .MuiTableHead-root': {
        height: '60px',
        borderBottom: `2px solid ${palette.grey.light}`,
        '& .MuiTableCell-root': {
          fontFamily: 'Chance',
          color: '#2222B3',
          fontSize: '16px',
        },
        '& .MuiButtonBase-root': {
          color: '#2222B3',
          '& .MuiSvgIcon-root': {
            color: '#2222B3',
          },
        },
      },
    },
  },
};
