import {styled} from '@mui/system';
import {BooleanInput} from 'react-admin';

export const StyledSwitch = styled(BooleanInput)({
  '& .MuiSwitch-root': {
    overflow: 'unset',
    padding: '0',
    height: 'unset',
    width: 'unset',
    marginRight: '8px',
  },

  '& .MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#00A8E8',
    },
  },

  '& .MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    color: '#fff',
    boxShadow:
      '0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 3px 1px rgba(0, 0, 0, 0.03)',
  },

  '& .MuiSwitch-track': {
    backgroundColor: '#00B9FF',
    opacity: '0.2',
    borderRadius: '36.5px',
    height: '20px',
    width: '40px',
  },
});
