import {Button, Grid, Link} from '@mui/material';

import {SecondaryButton} from '../../../../Buttons/SecondaryButton';

interface ControlBarProps {
  jsonFunc: () => void;
  rescanFunc: () => void;
  confirmationLink: string;
  rescanText: string;
}

const ControlBar: React.FC<ControlBarProps> = ({
  jsonFunc,
  rescanFunc,
  confirmationLink,
  rescanText,
}): JSX.Element => {
  return (
    <Grid>
      <SecondaryButton
        text="Activer/Désactiver le mode JSON"
        onClick={jsonFunc}
      />
      <SecondaryButton text={rescanText} onClick={rescanFunc} />
      <Link href={confirmationLink}>
        <Button variant="contained">Aller sur la page de confirmation</Button>
      </Link>
    </Grid>
  );
};

export default ControlBar;
