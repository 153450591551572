import {AxiosError} from 'axios';

import {PaymentMethod} from '../context/reducer.types';
import {PaymentDto} from '../dto/service-requests.dto';
import {neptuneClient} from './neptuneClient';

/**
 * Send a request to the API to confirm a service request
 * @param rdiRoleCode RDI role code of the retailer
 * @param operatorId Operator Identifier, aka Operator Code
 * @param serviceRequestUuid UUID of the service request to confirm
 * @param paymentConfirmationObject service request confirmation object
 * @param errorSetter Callback function to set an eventual axios error
 * @param successSetter Callback function to set the success state
 * @returns boolean, true if the service request was confirmed, false otherwise
 */

export async function confirmPayment(
  rdiRoleCode: string,
  operatorId: string,
  serviceRequestUuid: string,
  payment: PaymentDto,
  paymentMethod: PaymentMethod,
  errorSetter: (err: AxiosError | Error) => void,
  successSetter: (value: unknown) => void,
): Promise<boolean> {
  try {
    const client = await neptuneClient(rdiRoleCode, operatorId);
    const response = await client.post<unknown>(
      `/serviceRequests/${serviceRequestUuid}/confirmations`,
      {
        payment: {...payment, paymentMethod: {type: paymentMethod}},
      },
      {
        headers: {
          Accept:
            'application/vnd.fgs.ese.orchestration.servicerequest.confirmed-3+json, application/vnd.lotsys.falcon.error-1+json',
          'Content-Type':
            'application/vnd.fgs.ese.orchestration.servicerequest.confirmation-1+json; charset=utf-8',
        },
      },
    );
    if (response.status >= 200 || response.status <= 300) {
      successSetter(response.data);
      return true;
    }
    return false;
  } catch (error) {
    if (error instanceof AxiosError) {
      errorSetter(error);
    }
    return false;
  }
}
