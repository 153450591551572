import {AppProvider} from '../../context/context';
import {SimulatorConfiguration} from './SimulatorConfiguration';

const PaymentSimulation = (): JSX.Element => {
  return (
    <AppProvider>
      <SimulatorConfiguration />
    </AppProvider>
  );
};

export default PaymentSimulation;
