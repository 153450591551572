import {SvgIcon} from '@mui/material';
import {EditButton, EditButtonProps} from 'react-admin';

import {ReactComponent as EditIcon} from '../assets/icons/edit.svg';

export const NirioEditButton = (props: EditButtonProps): JSX.Element => (
  <EditButton
    icon={<SvgIcon component={EditIcon} />}
    variant="contained"
    sx={{
      '& .MuiButton-startIcon': {
        display: 'none',
      },
    }}
    {...props}
  />
);
