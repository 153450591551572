import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const settingsProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      query: gql`
        query PromotionSettings($id: String!) {
          data: PromotionSettings(id: $id) {
            id
            isActive
            userLimit
            slidingMonthLimit
            requiredPaymentsCount
            requiredPaymentsMinimumAmountInCents
            requiredPaymentsSlidingDays
            gainAmountInCents
            referrerGainAmountInCents
            retailerGainAmountInCents
          }
        }
      `,
    };
  }
  return builtQuery;
};
