import {Stack, Typography, useTheme} from '@mui/material';
import {Link, SaveButton, useTranslate} from 'react-admin';

import {basename} from '../constants';
import {Icons} from './Icon';

interface TopBarProps {
  title?: string;
  resourceName: string;
  NavigationButton?: JSX.Element;
}

export const CreateEditTopBar: React.FC<TopBarProps> = ({
  title,
  resourceName,
  NavigationButton,
}) => {
  const translate = useTranslate();
  const theme = useTheme();
  const resourceNameTranslation = translate(`resources.${resourceName}.name`);
  const [translatedLowerCaseResource] = resourceNameTranslation.split(' |||| ');
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        marginBottom: theme.spacing(5.5),
        marginTop: theme.spacing(7.5),
      }}>
      <Stack
        component={Link}
        to={`${basename}/${resourceName}`}
        alignItems="center"
        gap={theme.spacing(2)}
        direction="row">
        <Icons.ChevronLeft size={20} />
        <Typography variant="h1" color="secondary">
          {title || `Nouveau ${translatedLowerCaseResource}`}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={theme.spacing(1.5)}>
        {NavigationButton}
        <SaveButton alwaysEnable variant="contained" icon={<div />} />
      </Stack>
    </Stack>
  );
};
