import {SvgIcon} from '@mui/material';
import {
  DeleteWithConfirmButton,
  DeleteWithConfirmButtonProps,
} from 'react-admin';

import {ReactComponent as DeleteIcon} from '../assets/icons/delete.svg';

export const NirioDeleteWithConfirmButton = (
  props: DeleteWithConfirmButtonProps,
): JSX.Element => (
  <DeleteWithConfirmButton
    icon={<SvgIcon component={DeleteIcon} />}
    sx={{
      '& .MuiButton-startIcon': {
        display: 'none',
      },
    }}
    {...props}
  />
);
