import {Box} from '@mui/material';
import {
  Loading,
  RaRecord,
  ShowBase,
  ShowControllerProps,
  useShowController,
} from 'react-admin';

import {basename} from '../constants';
import {BreadCrumbHeader} from './BreadCrumbHeader';
import {NirioDeleteWithConfirmButton} from './NirioDeleteWithConfirmButton';
import {NirioEditButton} from './NirioEditButton';
import {PageLayout} from './PageLayout';

export type NirioShowProps = ShowControllerProps<RaRecord> & {
  canDelete?: boolean;
  canEdit?: boolean;
  children: React.ReactNode;
};

export const NirioShow = ({
  canDelete = true,
  canEdit = true,
  children,
  ...props
}: NirioShowProps): JSX.Element => {
  const controller = useShowController(props);

  if (controller.isLoading) {
    return <Loading />;
  }

  return (
    <ShowBase {...props}>
      <PageLayout>
        <Box my={7}>
          <BreadCrumbHeader
            {...controller}
            actions={[
              canDelete && <NirioDeleteWithConfirmButton key="delete" />,
              canEdit && <NirioEditButton key="edit" />,
            ]}
            backTo={`${basename}/${controller.resource}`}
          />
        </Box>
        {children}
      </PageLayout>
    </ShowBase>
  );
};
