import {Stack} from '@mui/material';
import {useState} from 'react';

import {
  SendMagicLinkResult,
  useLoginRequestMutation,
} from '../../api/generated';
import MagicLinkLoginRequestForm from './MagicLinkLoginRequestForm';
import MagicLinkLoginRequestInfos from './MagicLinkLoginRequestInfos';
import MagicLinkLoginRequestSuccess from './MagicLinkLoginRequestSuccess';

const MagicLinkLoginRequest = (): JSX.Element => {
  const [inputEmail, setInputEmail] = useState('');

  const [sendLoginRequest, {data, loading, error}] = useLoginRequestMutation();

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    // useEffect collides with no-floating-promises: https://github.com/typescript-eslint/typescript-eslint/issues/1184
    void sendLoginRequest({variables: {email: inputEmail}});
  };
  return (
    <Stack sx={{height: '100vh'}} alignItems="center" justifyContent="center">
      <Stack
        alignItems="center"
        sx={{border: '2px solid darkBlue', padding: '8px 16px'}}>
        <img
          src="/Nirio_LogotypeGroupe_RVB.png"
          alt="Logo de Nirio"
          width="160"
        />
        {data?.sendMagicLink === SendMagicLinkResult.Success ? (
          <MagicLinkLoginRequestSuccess inputEmail={inputEmail} />
        ) : (
          <>
            <MagicLinkLoginRequestForm
              handleSubmit={handleSubmit}
              inputEmail={inputEmail}
              setInputEmail={setInputEmail}
            />
            <MagicLinkLoginRequestInfos loading={loading} error={error} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default MagicLinkLoginRequest;
