import {Box} from '@mui/material';
import {ReactNode} from 'react';
import {
  Button,
  EditBase,
  EditControllerProps,
  Loading,
  RaRecord,
  useEditController,
} from 'react-admin';
import {Link} from 'react-router-dom';

import {basename} from '../constants';
import {BreadCrumbHeader} from './BreadCrumbHeader';
import {Form} from './Form/Form';
import {NirioSaveButton} from './NirioSaveButton';
import {PageLayout} from './PageLayout';

export type NirioEditProps<RecordType extends RaRecord> =
  EditControllerProps<RecordType> & {
    children: ReactNode;
    title?: string | ((record: RecordType) => string);
  };

export const NirioEdit = <RecordType extends RaRecord = RaRecord>({
  children,
  title,
  ...props
}: NirioEditProps<RecordType>): JSX.Element => {
  const controller = useEditController<RecordType>(props);

  if (controller.isLoading || !controller.record) {
    return <Loading />;
  }

  const backTo = controller.record?.id
    ? `${basename}/${controller.resource}/${controller.record?.id}/show`
    : `${basename}/${controller.resource}`;

  return (
    <EditBase {...props}>
      <PageLayout>
        <Form>
          <Box my={7}>
            <BreadCrumbHeader
              {...controller}
              title={
                typeof title === 'function' ? title(controller.record) : title
              }
              actions={[
                <Button
                  key="back"
                  label="Annuler"
                  component={Link}
                  to={backTo}
                />,
                <NirioSaveButton key="save" />,
              ]}
              backTo={backTo}
            />
          </Box>
          {children}
        </Form>
      </PageLayout>
    </EditBase>
  );
};
